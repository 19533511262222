import { useTranslation } from '@/components';
import { VerificationTimeoutCodes } from '@/enums';
import {
  fontWeights,
  getVerificationTimeout,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme
} from '@mui/material';
import {
  SettingsButtonBack,
  SettingsPanel,
  SettingsPanelHeader
} from '@user/settings';
import { useEffect, useState } from 'react';

enum Steps {
  Request = 'Request',
  Verify = 'Verify',
  Done = 'Done'
}

const PasswordCheck = ({
  color,
  children
}: {
  color?: string;
  children: string | string[];
}) => (
  <Typography
    color={color}
    variant='caption'
    display='inline-block'
    lineHeight={1}
  >
    {children}
  </Typography>
);

const SettingsPassword = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const theme = useTheme();
  const t = useTranslation('PersonalSpace.Settings');

  const [step, setStep] = useState(Steps.Request);
  const [remainingTime, setRemainingTime] = useState(60);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<FormError>(false);
  const [confirm, setConfirm] = useState('');
  const [confirmError, setConfirmError] = useState<FormError>(false);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  );
  const [loading, setLoading] = useState(false);

  //#region Password strength

  const passwordHas8Characters = () => {
    return password.trim().length > 7
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';
  };

  const passwordHasUpperCase = () => {
    return /[A-Z]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';
  };

  const passwordHasLowerCase = () => {
    return /[a-z]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';
  };

  const passwordHasNumber = () => {
    return /[0-9]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';
  };

  const passwordHasSpecialCharacter = () => {
    return /[^A-Za-z0-9\s]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';
  };

  //#endregion Password strength

  let timeoutId: any;

  const getExpireTime = () => {
    return (
      getVerificationTimeout(VerificationTimeoutCodes.UserSettingsPassword) || 0
    );
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  const togglePassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const resend = async () => {
    try {
      setLoading(true);
      await webClient.api.auth.basic.resend({
        email: window.user.email
      });

      setVerificationTimeout(VerificationTimeoutCodes.UserSettingsPassword, 60);
      startTimer();
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    }

    setLoading(false);
  };

  const request = async () => {
    setLoading(true);
    try {
      await webClient.api.auth.password.forget({
        email: window.user.email
      });

      setVerificationTimeout(VerificationTimeoutCodes.UserSettingsPassword, 60);
      startTimer();
      setStep(Steps.Verify);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    }

    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);
    try {
      await webClient.api.auth.password.reset({
        code,
        email: window.user.email,
        password
      });
      setStep(Steps.Done);
      setLoading(false);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    }

    setLoading(false);
  };

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('settings', 'Change password');
    // eslint-disable-next-line
  }, []);

  if (step === Steps.Done) {
    return (
      <SettingsPanel>
        <SettingsButtonBack to='/user/settings/' />
        <Box textAlign='center'>
          <CheckCircle sx={{ fontSize: '56px' }} color='success' />
          <Typography
            fontSize={20}
            fontWeight={fontWeights.semiBold}
            mt={16 / 12}
          >
            {t('$Success')}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={fontWeights.regular}
            mt={16 / 12}
          >
            {t('$Authentication.RecoverPassword.YourPasswordChanged')}
          </Typography>
        </Box>
      </SettingsPanel>
    );
  }

  if (step === Steps.Verify) {
    return (
      <SettingsPanel>
        <SettingsButtonBack onClick={() => setStep(Steps.Request)} />
        <SettingsPanelHeader>{t('ChangePassword')}</SettingsPanelHeader>
        <Typography>{t('WeSentACodeToYourEmail')}</Typography>
        <Typography>
          {t('EnterTheXDigitVerificationCode', {
            digits: '6-digit',
            email: window.user.email
          })}
        </Typography>
        <InputLabel sx={{ mt: 3 }} htmlFor='code'>
          {t('$Code')}
        </InputLabel>
        <OutlinedInput
          error={codeError !== false}
          type='text'
          fullWidth
          defaultValue={code}
          placeholder={t('EnterCode')}
          name='code'
          id='code'
          key='code'
          onChange={(e) => {
            setCode(e.target.value);
            setCodeError(false);
          }}
        />
        <FormHelperText error={codeError !== false}>
          {codeError !== false ? codeError : ''}
        </FormHelperText>
        <Box fontSize={14}>
          {t('$Authentication.DidntGetTheCode')}
          <Button
            variant='text'
            onClick={resend}
            disabled={remainingTime > 0}
            sx={{ p: 0, fontSize: 'inherit' }}
          >
            {t('$Authentication.Resend')}
          </Button>
          {remainingTime > 0 &&
            t('$Authentication.ResendItIn', {
              time:
                remainingTime > 1
                  ? t('$XSeconds', {
                      x: remainingTime
                    })
                  : t('$1Second')
            })}
        </Box>
        <InputLabel sx={{ mt: 1 }} htmlFor='signup_password'>
          {t('$Authentication.NewPassword')}
        </InputLabel>
        <OutlinedInput
          error={passwordError !== false}
          type={passwordType}
          fullWidth
          defaultValue={password}
          placeholder={t('$Authentication.NewPasswordHint')}
          name='password'
          id='signup_password'
          key='signup_password'
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
            setConfirmError(false);
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          sx={{
            lineHeight: 1
          }}
        >
          <PasswordCheck color={passwordHas8Characters()}>
            {t('$Authentication.StrongPassword.PlusXCharacters', {
              x: 8
            })}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasUpperCase()}>
            &nbsp;{t('$Authentication.StrongPassword.UpperCase')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasLowerCase()}>
            &nbsp;{t('$Authentication.StrongPassword.LowerCase')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasNumber()}>
            &nbsp;{t('$Authentication.StrongPassword.Number')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasSpecialCharacter()}>
            &nbsp;{t('$Authentication.StrongPassword.SpecialCharacter')}
          </PasswordCheck>
          .
        </FormHelperText>
        <InputLabel sx={{ mt: 1 }} htmlFor='signup_confirm'>
          {t('$Authentication.ConfirmPassword')}
        </InputLabel>
        <OutlinedInput
          error={confirmError !== false}
          type={passwordType}
          fullWidth
          defaultValue={confirm}
          placeholder={t('$Authentication.ConfirmPasswordHint')}
          name='confirm'
          id='signup_confirm'
          key='signup_confirm'
          onChange={(e) => {
            setConfirm(e.target.value);
            setConfirmError(false);
            setPasswordError(false);
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error={confirmError !== false}>
          {confirmError !== false ? confirmError : ''}
        </FormHelperText>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: 18 }}
          variant='contained'
          disabled={loading || code.trim().length < 5}
          onClick={submit}
        >
          {loading ? <CircularProgress color='inherit' /> : t('$Submit')}
        </Button>
      </SettingsPanel>
    );
  }

  return (
    <SettingsPanel>
      <SettingsButtonBack to='/user/settings/' />
      <SettingsPanelHeader>{t('ChangePassword')}</SettingsPanelHeader>
      <Typography fontSize={14} fontWeight={fontWeights.semiBold} mt={2}>
        {t('PrimaryEmail')}
      </Typography>
      <Typography fontSize={16} fontWeight={fontWeights.regular}>
        {window.user.email}
      </Typography>
      {window.user.secondaryEmail && (
        <>
          <Typography fontSize={14} fontWeight={fontWeights.semiBold}>
            {t('SecondaryEmail')}
          </Typography>
          <Typography fontSize={16} fontWeight={fontWeights.regular}>
            {window.user.secondaryEmail}
          </Typography>
        </>
      )}
      <Button
        type='button'
        variant='outlined'
        onClick={request}
        disabled={loading}
        sx={{
          mt: 4
        }}
      >
        {t('ChangePassword')}
      </Button>
    </SettingsPanel>
  );
};

export default SettingsPassword;
