import {
  AdminsAuthenticate,
  Authenticate,
  getLanguage,
  Header,
  Loading,
  useTranslation
} from '@/components';
import '@/extensions/array.extensions';
import '@/extensions/html.extensions';
import '@/extensions/number.extensions';
import '@/extensions/object.extensions';
import '@/extensions/string.extensions';
import { fontWeights, getCdnUrl } from '@/helpers';
import { webClient } from '@/helpers/webClient';
import { ErrorComponent, Layout } from '@/pages';
import RecoverPassword from '@auth/recover-password';
import SignIn from '@auth/signIn';
import SignUpThanks from '@auth/signUpThanks';
import SignUp from '@auth/signup';
import Verify from '@auth/verify';
import AppContext from '@contexts/app-context';
import {
  Box,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material';
import UserRouter from '@userRoot/userRouter';
import { AxiosError } from 'axios';
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

const LazyAdminRouter = React.lazy(() => import('@adminRoot/adminRouter'));

interface IToast {
  id: number;
  content: string;
  closing: boolean;
  type: ToastTypes;
}

interface IProps {}

const defaltSettings: ISettings = {
  APP_NAME: 'GrowPass',
  base: '',
  surveys: {
    target: 3
  }
};

const App = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    title: string;
    content?: string | ReactNode;
    actions: ReactNode;
  }>({
    isOpen: false,
    title: '',
    actions: <></>
  });
  const [errorState, setErrorState] = useState<FormError>(false);
  const [openProfileState, setOpenProfileState] = useState(false);
  const [anchorElementState, setAnchorElementState] = useState<Element | null>(
    null
  );
  const [mainClassesState, setMainClassesState] = useState<
    Record<MainAvailableClasses, boolean>
  >({
    'main-flex': true,
    dark: false,
    'no-padding': false
  });
  const [headerState, setHeaderState] = useState<{
    linkTo: string;
    back?: string;
    tools: ReactNode[] | undefined;
  }>({
    linkTo: '/',
    tools: []
  });
  const [loadingCountState, setLoadingCountState] = useState(0);
  const [toastsState, setToastsState] = useState<IToast[]>([]);
  const [settingsState, setSettingsState] = useState<ISettings>(defaltSettings);

  const redirectOnResponseError = async (error: AxiosError) => {
    if (error.config?.authHandled === true) {
      throw error;
    }

    if (document.location.pathname.startsWith('/login')) {
      return;
    }

    if (error.response?.status === 403) {
      return setError("You don't have access to this page!");
    }

    if (error.response?.status === 401) {
      const lang = getLanguage();
      return navigate(
        `/login/?returnUrl=%2F${lang}${encodeURIComponent(
          document.location.pathAndSearch
        )}`,
        {
          replace: true
        }
      );
    }

    throw error;
  };

  const showDialog = (
    title: string,
    contentActions?: string | ReactNode,
    actions?: ReactNode
  ) => {
    const content = typeof actions === 'undefined' ? undefined : contentActions;
    const buttons = typeof actions === 'undefined' ? contentActions : actions;

    setDialogState({
      isOpen: true,
      title,
      content,
      actions: buttons
    });
  };

  const hideDialog = () =>
    setDialogState((s) => ({
      ...s,
      isOpen: false
    }));

  const setError = (error: FormError) => setErrorState(error);

  const closeProfile = () => setOpenProfileState(false);
  const openProfile = (e: React.MouseEvent<HTMLElement>) => {
    setOpenProfileState(true);
    setAnchorElementState(e.currentTarget);
  };

  const setMainClasses = (...classes: MainAvailableClasses[]) => {
    let s = mainClassesState;
    let classNames = Object.keys(s) as MainAvailableClasses[];

    for (let i = 0; i < classNames.length; i++) {
      const className = classNames[i];
      s[className] = classes.indexOf(className) > -1;
    }

    setMainClassesState(s);
  };

  const setHeader = (to?: string, back?: string, ...tools: ReactNode[]) =>
    setHeaderState((s) => ({
      ...s,
      linkTo: to || s.linkTo,
      back,
      tools
    }));

  const showLoading = () => setLoadingCountState((s) => s + 1);
  const hideLoading = () => setLoadingCountState((s) => (s <= 0 ? 0 : s - 1));

  const showToast = (content: string, duration?: number, type?: ToastTypes) => {
    const id = Math.round(Math.random() * 10000);
    setToastsState((s) => [
      ...s,
      {
        id,
        content,
        type: type || 'default',
        closing: false
      }
    ]);

    setTimeout(
      async function (id: number) {
        let i = toastsState.findIndex((x) => x.id === id);
        if (i < 0) {
          return;
        }

        let toasts = [...toastsState];
        let toast = { ...toasts[i] };
        toast.closing = true;

        toasts[i] = toast;
        setToastsState(toasts);
        setTimeout(
          async function (id: number) {
            let i = toastsState.findIndex((x) => x.id === id);
            if (i < 0) {
              return;
            }

            let toasts = [...toastsState];
            toasts = toasts.filter((x) => x.id !== id);
            setToastsState(toasts);
          }.bind(id),
          1e3
        );
      }.bind(id),
      duration || 2e3
    );
  };

  useEffect(() => {
    webClient.interceptors.response.use((x) => x, redirectOnResponseError);

    webClient
      .get<SingleResponse<{ settings: ISettings }>>('/settings')
      .then((settings) => {
        document.title = settings.data.data.settings.APP_NAME || 'GrowPass';
        setSettingsState(settings.data.data.settings);
      });
  }, []);

  return (
    <AppContext.Provider
      value={{
        header: headerState,
        settings: settingsState,
        setHeader,
        setMainClasses,
        showToast,
        setError,
        showDialog,
        hideDialog,
        openProfile,
        openProfileState
      }}
    >
      <Dialog
        open={dialogState.isOpen}
        onClose={hideDialog}
        PaperProps={{
          sx: {
            minWidth: 400
          }
        }}
      >
        <DialogTitle>{dialogState.title}</DialogTitle>
        {dialogState.content && (
          <DialogContent>{dialogState.content}</DialogContent>
        )}
        {dialogState.actions && (
          <DialogActions>{dialogState.actions}</DialogActions>
        )}
      </Dialog>
      <CssBaseline />
      <Box
        height='100vh'
        display='flex'
        flexDirection='column'
        sx={{
          overflowY: 'auto'
        }}
        color={theme.palette?.text.primary}
      >
        <Menu
          anchorEl={anchorElementState}
          open={openProfileState}
          onClose={closeProfile}
          onClick={closeProfile}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => (document.location.href = '/sign-out')}>
            {t('Logout')}
          </MenuItem>
        </Menu>
        <Header />
        <Box
          component='main'
          p={mainClassesState['no-padding'] ? 0 : 2.5}
          minHeight='calc(100vh - 84px)'
          bgcolor={mainClassesState.dark ? theme.palette.grey[800] : undefined}
          color={mainClassesState.dark ? 'white' : undefined}
          display={mainClassesState['main-flex'] ? 'flex' : undefined}
          flexDirection='column'
          sx={{
            overflowX: 'hidden',
            '@media print': {
              bgcolor: 'white',
              p: 0
            }
          }}
        >
          {loadingCountState > 0 && (
            <Box
              position='fixed'
              height='calc(100vh - 4rem)'
              top='4rem'
              left={0}
              width='100vw'
              zIndex={1000}
              bgcolor='#fff7'
              display='flex'
            >
              <Loading />
            </Box>
          )}
          {toastsState.length > 0 && (
            <Box
              position='fixed'
              top='84px'
              left='32px'
              right='32px'
              zIndex={10000000}
            >
              {toastsState.map((toast) => (
                <Box
                  key={toast.id}
                  lineHeight='19px'
                  bgcolor={
                    toast.type === 'error'
                      ? '#feeded'
                      : toast.type === 'info'
                      ? '#e6ebff'
                      : toast.type === 'success'
                      ? '#f2fdf6'
                      : '#f5f5f5'
                  }
                  color={
                    toast.type === 'error'
                      ? theme.palette.error.main
                      : toast.type === 'info'
                      ? theme.palette.info.main
                      : toast.type === 'success'
                      ? theme.palette.success.main
                      : theme.palette.secondary.main
                  }
                  fontSize='15px'
                  fontWeight={fontWeights.semiBold}
                  borderRadius='10px'
                  px={1.5}
                  py={1}
                  mb={1.5}
                  display='flex'
                  sx={{
                    animation: toast.closing
                      ? 'hideToast 0.5s ease-in-out 1'
                      : 'showToast 1s ease-in-out 1',
                    top: toast.closing ? '-500px' : undefined
                  }}
                >
                  {toast.type !== 'default' && (
                    <img
                      style={{ marginRight: '8px' }}
                      src={getCdnUrl(`/images/${toast.type}.svg`)}
                      alt={toast.type}
                    />
                  )}
                  {toast.content}
                </Box>
              ))}
            </Box>
          )}
          {(errorState && (
            <ErrorComponent
              code={errorState}
              context={{
                setError
              }}
            />
          )) || (
            <Routes>
              <Route path='/login' element={<SignIn />} />
              <Route path='/register/thanks' element={<SignUpThanks />} />
              <Route path='/register' element={<SignUp />} />
              <Route path='/verify' element={<Verify />} />
              <Route path='/recover-password' element={<RecoverPassword />} />
              <Route
                path='/admin/*'
                element={
                  <AdminsAuthenticate
                    component={
                      <Suspense fallback={<CircularProgress />}>
                        <LazyAdminRouter />
                      </Suspense>
                    }
                  />
                }
              />
              <Route
                path='/user/*'
                element={<Authenticate component={<UserRouter />} />}
              />
              <Route path='*' element={<Layout />} />
            </Routes>
          )}
        </Box>
      </Box>
    </AppContext.Provider>
  );
};

export default App;
