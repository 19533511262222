import { createContext, useContext } from 'react';

const PdfContext = createContext<IPdfContext>({} as any);
const usePdfContext = () => useContext(PdfContext);

export { usePdfContext };

export type PdfContextType = React.ContextType<typeof PdfContext>;

export default PdfContext;
