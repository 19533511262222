import { useTheme } from '@mui/material';

const QuestionSvg = () => {
  const theme = useTheme();

  return (
    <svg
      width='196'
      height='179'
      viewBox='0 0 196 179'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M94.82 114.95V109.345C94.82 106.242 95.2317 103.708 96.055 101.745C96.8783 99.7183 97.9233 98.04 99.19 96.71C100.457 95.3167 101.755 94.0183 103.085 92.815C104.985 91.1683 106.6 89.49 107.93 87.78C109.323 86.07 110.02 83.8533 110.02 81.13C110.02 79.3567 109.577 77.8367 108.69 76.57C107.867 75.24 106.79 74.2267 105.46 73.53C104.13 72.77 102.768 72.39 101.375 72.39C99.4117 72.39 97.7017 72.8333 96.245 73.72C94.7883 74.5433 93.585 75.6833 92.635 77.14C91.685 78.5967 90.8933 80.1167 90.26 81.7L81.33 77.615C81.7733 76.3483 82.47 74.86 83.42 73.15C84.4333 71.3767 85.795 69.6667 87.505 68.02C89.215 66.3733 91.305 65.0117 93.775 63.935C96.245 62.8583 99.1583 62.32 102.515 62.32C106.062 62.32 109.26 62.985 112.11 64.315C115.023 65.645 117.303 67.64 118.95 70.3C120.66 72.8967 121.515 76.19 121.515 80.18C121.515 83.41 121.072 86.07 120.185 88.16C119.298 90.25 118.158 92.055 116.765 93.575C115.372 95.095 113.915 96.5517 112.395 97.945C111.192 99.085 110.052 100.257 108.975 101.46C107.962 102.663 107.138 104.057 106.505 105.64C105.935 107.223 105.65 109.282 105.65 111.815V114.95H94.82ZM100.235 133.38C98.335 133.38 96.7517 132.842 95.485 131.765C94.2817 130.688 93.68 129.295 93.68 127.585C93.68 125.938 94.3133 124.545 95.58 123.405C96.8467 122.265 98.3983 121.695 100.235 121.695C102.135 121.695 103.687 122.265 104.89 123.405C106.093 124.545 106.695 125.938 106.695 127.585C106.695 129.295 106.093 130.688 104.89 131.765C103.75 132.842 102.198 133.38 100.235 133.38Z'
        fill='url(#paint3_linear_5534_1747)'
      />
      <path
        d='M169.58 50.55V47.305C169.58 45.5083 169.818 44.0417 170.295 42.905C170.772 41.7317 171.377 40.76 172.11 39.99C172.843 39.1833 173.595 38.4317 174.365 37.735C175.465 36.7817 176.4 35.81 177.17 34.82C177.977 33.83 178.38 32.5467 178.38 30.97C178.38 29.9433 178.123 29.0633 177.61 28.33C177.133 27.56 176.51 26.9733 175.74 26.57C174.97 26.13 174.182 25.91 173.375 25.91C172.238 25.91 171.248 26.1667 170.405 26.68C169.562 27.1567 168.865 27.8167 168.315 28.66C167.765 29.5033 167.307 30.3833 166.94 31.3L161.77 28.935C162.027 28.2017 162.43 27.34 162.98 26.35C163.567 25.3233 164.355 24.3333 165.345 23.38C166.335 22.4267 167.545 21.6383 168.975 21.015C170.405 20.3917 172.092 20.08 174.035 20.08C176.088 20.08 177.94 20.465 179.59 21.235C181.277 22.005 182.597 23.16 183.55 24.7C184.54 26.2033 185.035 28.11 185.035 30.42C185.035 32.29 184.778 33.83 184.265 35.04C183.752 36.25 183.092 37.295 182.285 38.175C181.478 39.055 180.635 39.8983 179.755 40.705C179.058 41.365 178.398 42.0433 177.775 42.74C177.188 43.4367 176.712 44.2433 176.345 45.16C176.015 46.0767 175.85 47.2683 175.85 48.735V50.55H169.58ZM172.715 61.22C171.615 61.22 170.698 60.9083 169.965 60.285C169.268 59.6617 168.92 58.855 168.92 57.865C168.92 56.9117 169.287 56.105 170.02 55.445C170.753 54.785 171.652 54.455 172.715 54.455C173.815 54.455 174.713 54.785 175.41 55.445C176.107 56.105 176.455 56.9117 176.455 57.865C176.455 58.855 176.107 59.6617 175.41 60.285C174.75 60.9083 173.852 61.22 172.715 61.22Z'
        fill='url(#paint4_linear_5534_1747)'
        fillOpacity='0.5'
      />
      <path
        d='M23.5645 75.7V71.57C23.5645 69.2833 23.8679 67.4167 24.4745 65.97C25.0812 64.4767 25.8512 63.24 26.7845 62.26C27.7179 61.2333 28.6745 60.2767 29.6545 59.39C31.0545 58.1767 32.2445 56.94 33.2245 55.68C34.2512 54.42 34.7645 52.7867 34.7645 50.78C34.7645 49.4733 34.4379 48.3533 33.7845 47.42C33.1779 46.44 32.3845 45.6933 31.4045 45.18C30.4245 44.62 29.4212 44.34 28.3945 44.34C26.9479 44.34 25.6879 44.6667 24.6145 45.32C23.5412 45.9267 22.6545 46.7667 21.9545 47.84C21.2545 48.9133 20.6712 50.0333 20.2045 51.2L13.6245 48.19C13.9512 47.2567 14.4645 46.16 15.1645 44.9C15.9112 43.5933 16.9145 42.3333 18.1745 41.12C19.4345 39.9067 20.9745 38.9033 22.7945 38.11C24.6145 37.3167 26.7612 36.92 29.2345 36.92C31.8479 36.92 34.2045 37.41 36.3045 38.39C38.4512 39.37 40.1312 40.84 41.3445 42.8C42.6045 44.7133 43.2345 47.14 43.2345 50.08C43.2345 52.46 42.9079 54.42 42.2545 55.96C41.6012 57.5 40.7612 58.83 39.7345 59.95C38.7079 61.07 37.6345 62.1433 36.5145 63.17C35.6279 64.01 34.7879 64.8733 33.9945 65.76C33.2479 66.6467 32.6412 67.6733 32.1745 68.84C31.7545 70.0067 31.5445 71.5233 31.5445 73.39V75.7H23.5645ZM27.5545 89.28C26.1545 89.28 24.9879 88.8833 24.0545 88.09C23.1679 87.2967 22.7245 86.27 22.7245 85.01C22.7245 83.7967 23.1912 82.77 24.1245 81.93C25.0579 81.09 26.2012 80.67 27.5545 80.67C28.9545 80.67 30.0979 81.09 30.9845 81.93C31.8712 82.77 32.3145 83.7967 32.3145 85.01C32.3145 86.27 31.8712 87.2967 30.9845 88.09C30.1445 88.8833 29.0012 89.28 27.5545 89.28Z'
        fill='url(#paint5_linear_5534_1747)'
        fillOpacity='0.75'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5534_1747'
          x1='173.871'
          y1='60.8203'
          x2='173.871'
          y2='83.7947'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5534_1747'
          x1='101.952'
          y1='128.309'
          x2='101.952'
          y2='174.257'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5534_1747'
          x1='30.0322'
          y1='83.7949'
          x2='30.0322'
          y2='115.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_5534_1747'
          x1='105.181'
          y1='-32.5549'
          x2='105.181'
          y2='256.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.346' stopColor={theme.palette.secondary.light} />
          <stop offset='1' stopColor={theme.palette.secondary.main} />
        </linearGradient>
        <linearGradient
          id='paint4_linear_5534_1747'
          x1='173.972'
          y1='-30.5658'
          x2='173.972'
          y2='115.401'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.346' stopColor={theme.palette.secondary.light} />
          <stop offset='1' stopColor={theme.palette.secondary.main} />
        </linearGradient>
        <linearGradient
          id='paint5_linear_5534_1747'
          x1='30.9582'
          y1='-31.2535'
          x2='30.9582'
          y2='175.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.346' stopColor={theme.palette.secondary.light} />
          <stop offset='1' stopColor={theme.palette.secondary.main} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionSvg;
