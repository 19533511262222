import { addAlphaToHex, fontWeights } from '@/helpers';
import { Data } from '@/icons';
import { Box, Typography, useTheme } from '@mui/material';

const Empty = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  const theme = useTheme();

  return (
    <Box
      borderRadius='24px'
      boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
      bgcolor='white'
      mx='calc((100% - 450px) / 2)'
      p={4}
      textAlign='center'
    >
      <Data color={theme.palette.primary.main} width={56} height={56} />
      <Typography fontSize={20} fontWeight={fontWeights.semiBold}>
        {title}
      </Typography>
      {subtitle && (
        <Typography fontSize={14} fontWeight={fontWeights.light}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Empty;
