import { appendVersion } from '@/helpers';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';

const useForceUpdate = () => {
  const setState = useState(true)[1];
  return () => setState((n) => !n);
};

const defLanguage: Languages = 'en';
let id: number = 1;

const subscribes: {
  [key: string]: Subscription;
} = {};

let translations: ITranslations = {};

export function languageChangeSubscribe(cb: Subscription): number {
  const newId = id;
  subscribes[newId] = cb;
  id += 1;
  return newId;
}

export function languageChangeUnsubscribe(componentId: number): void {
  delete subscribes[componentId];
}

function triggerLanguageChangeSubscriptions(
  oldLang: Languages,
  newLang: Languages
) {
  Object.keys(subscribes).forEach((componentId: string) => {
    new Promise((resolve) => {
      if (
        subscribes[componentId] &&
        typeof subscribes[componentId] === 'function'
      ) {
        subscribes[componentId](oldLang, newLang);
      }
      resolve(componentId);
    }).then();
  });
}

export function addTranslation(lang: Languages, translation: ITranslation) {
  translations[lang] = translation;
}

export async function loadTranslation(lang: Languages) {
  if (typeof translations[lang] !== 'undefined') {
    return true;
  }

  try {
    const translation = await axios.get<ITranslation>(
      appendVersion(`/langs/${lang}.json`)
    );
    translations[lang] = translation.data;
    return true;
  } catch (exp) {
    return false;
  }
}

export function setDefaultLanguage(lang: Languages): void {
  setLanguage(lang);
}

export function setDefaultTranslations(userTranslations: ITranslations): void {
  if (Object.keys(translations).length !== 0) {
    setTranslations(userTranslations);
    return;
  }
  translations = userTranslations;
}

export function setTranslations(userTranslations: ITranslations): void {
  translations = userTranslations;
}

export async function setLanguage(lang: Languages) {
  const loaded = await loadTranslation(lang);
  if (!loaded) {
    return;
  }

  const oldLang = getLanguage();
  await window.setLanguage(lang);
  triggerLanguageChangeSubscriptions(oldLang, lang);

  const sc = document.getElementById('grecaptcha') as HTMLScriptElement;
  if (sc) {
    const dsrc = sc.getAttribute('data-src');
    if (dsrc) {
      sc.src = dsrc.replace('{lang}', lang);
    }
  }
}

export function getLanguage(): Languages {
  return window._language as Languages;
}

export function getDefaultLanguage(): Languages {
  return defLanguage as Languages;
}

function getTranslation(
  path: string,
  lang: Languages,
  args?: ITranslationParams
): string | null {
  const translationKeys: string[] = path.split('.');
  let translation: string = '';
  if (translations[lang]) {
    let translationObj: ITranslation = translations[lang];

    translationKeys.forEach((key: string) => {
      const temp: string | ITranslation = translationObj[key];
      if (typeof translationObj[key] === 'object') {
        translationObj = translationObj[key] as ITranslation;
      }
      if (typeof temp === 'string') {
        translation = temp;
      }
    });

    if (translation) {
      if (args) {
        Object.keys(args).forEach((key) => {
          translation = translation.replace(
            new RegExp(`{${key}}`, 'g'),
            args ? args[key] + '' : ''
          );
        });
      }
      return translation;
    }
  }

  return null;
}

export function t(path: string, args?: ITranslationParams): string {
  const lang = args?.lang || window._language;
  return (
    getTranslation(path, lang, args) ||
    getTranslation(path, defLanguage, args) ||
    path
  );
}

export function useTranslation(basePath?: string) {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    const subId = languageChangeSubscribe(() => forceUpdate());
    return () => languageChangeUnsubscribe(subId);
  }, [forceUpdate]);
  return (path: string, args?: ITranslationParams) =>
    t(
      path.startsWith('$')
        ? path.substring(1)
        : basePath
        ? basePath + '.' + path
        : path,
      args
    );
}

export class LanguageChangedEvent extends Event {
  private _oldLang!: string;
  private _newLang!: string;

  public get oldLang() {
    return this._oldLang;
  }

  public get newLang() {
    return this._newLang;
  }

  constructor(oldLang: string, newLang: string) {
    super('languageChanged', {
      bubbles: true
    });

    this._oldLang = oldLang;
    this._newLang = newLang;
  }
}

export class MultiLangComponent<
  P = {},
  S = {},
  SS = {}
> extends React.Component<MultiLangProps<P>, S, SS> {
  private cookiesTemp?: {
    [key: string]: string;
  };

  constructor(props: MultiLangProps<P>) {
    super(props);

    this.t = this.t.bind(this);
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
  }

  protected t(path: string, args?: ITranslationParams) {
    return this.props.t(path, args);
  }

  componentDidMount() {
    window.addEventListener('languageChanged', this._onLanguageChanged, true);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'languageChanged',
      this._onLanguageChanged,
      true
    );
  }

  setStateAsync<K extends keyof S>(state: StateObject<K, S>, debug?: boolean) {
    if (debug) {
      console.clear();
      console.log(state);
    }

    return new Promise((resolve) => {
      this.setState(state, () => resolve(state));
    });
  }

  private _onLanguageChanged(event: LanguageChangedEvent) {
    this.languageChanged(event.oldLang, event.newLang);
  }

  languageChanged(oldLang: string, newLang: string) {}
}

export const MultiLangRoute = ({ lang }: { lang: Languages }) => {
  setLanguage(lang);

  return (
    <Navigate
      replace
      to={document.location.pathAndSearch.replace(`/${lang}`, '')}
    />
  );
};

const MultiLang = {
  setDefaultLanguage,
  setLanguage,
  setDefaultTranslations,
  setTranslations,
  useTranslation,
  subscribe: languageChangeSubscribe,
  unsubscribe: languageChangeUnsubscribe,
  t,
  MultiLangComponent
};

export default MultiLang;
