import { useTranslation } from '@/components';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ItemProgress, StyledTable } from '.';

interface IProps {
  id: string;
  rows: TableOverallItemResponse[];
  items: TopicItemResponse[];
}

const TableOverall = ({ id, rows, items }: IProps) => {
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          <TableCell component='th' width='1%'>
            {t('SelfRating')}
          </TableCell>
          <TableCell component='th' width='1%'>
            {t('RatersRating')}
          </TableCell>
          {hasBenchmark && (
            <TableCell
              component='th'
              width='1%'
              sx={{
                whiteSpace: {
                  sm: 'nowrap'
                }
              }}
            >
              {t('CohortBenchmark')}
            </TableCell>
          )}
          <TableCell
            component='th'
            width='1%'
            sx={{
              whiteSpace: {
                sm: 'nowrap'
              }
            }}
          >
            {t('YoursVSOthers')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((x) => {
          const data = rows.find((y) => y.itemId === x.id);
          return (
            <TableRow key={`${id}_row_${x.id}`}>
              <TableCell component='th'>{x.name}</TableCell>
              <TableCell component='th'>{data?.selfRating || '-'}</TableCell>
              <TableCell component='th'>
                {data?.othersRating.toFixedString(1, '-')}
              </TableCell>
              {hasBenchmark && (
                <TableCell>
                  {rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)
                    .toFixedString(1, '-')}
                </TableCell>
              )}
              <TableCell sx={{ p: '4px 8px' }}>
                <ItemProgress
                  user={data?.selfRating || 0}
                  other={data?.othersRating || 0}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default TableOverall;
