import { useTheme } from '@mui/material';

const QuestionBgSvg = () => {
  const theme = useTheme();

  return (
    <svg
      width='196'
      height='179'
      viewBox='0 0 196 179'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <path
        d='M173.871 60.8203L196 72.3075L173.871 83.7947L151.742 72.3075L173.871 60.8203Z'
        fill='url(#paint0_linear_5534_1747)'
        fillOpacity='0.1'
      />
      <path
        d='M101.952 128.309L145.42 151.283L101.952 174.257L58.4844 151.283L101.952 128.309Z'
        fill='url(#paint1_linear_5534_1747)'
        fillOpacity='0.1'
      />
      <path
        d='M30.0322 83.7949L60.0645 99.5898L30.0322 115.385L-6.48499e-05 99.5898L30.0322 83.7949Z'
        fill='url(#paint2_linear_5534_1747)'
        fillOpacity='0.1'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5534_1747'
          x1='173.871'
          y1='60.8203'
          x2='173.871'
          y2='83.7947'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5534_1747'
          x1='101.952'
          y1='128.309'
          x2='101.952'
          y2='174.257'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5534_1747'
          x1='30.0322'
          y1='83.7949'
          x2='30.0322'
          y2='115.385'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.secondary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.secondary.main}
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionBgSvg;
