import { hexToColorMatrix } from '@/helpers';
import { useTheme } from '@mui/material';

const ShareSvg = () => {
  const theme = useTheme();

  return (
    <svg
      width='222'
      height='134'
      viewBox='0 0 222 134'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M184.912 44.2031L222 65.3962L184.912 86.5894L147.824 65.3962L184.912 44.2031Z'
        fill='url(#paint0_linear_5625_1491)'
        fillOpacity='0.1'
      />
      <path
        d='M40.5331 1.81641L77.6211 23.0095L40.5331 44.2026L3.4452 23.0095L40.5331 1.81641Z'
        fill='url(#paint1_linear_5625_1491)'
        fillOpacity='0.1'
      />
      <path
        d='M41.1963 77.3164L81.5957 101.159L41.1963 125.001L0.796959 101.159L41.1963 77.3164Z'
        fill='url(#paint2_linear_5625_1491)'
        fillOpacity='0.1'
      />
      <path
        d='M31.5938 105.132C33.9142 102.671 37.0646 101.159 40.5346 101.159C44.0046 101.159 47.155 102.671 49.4754 105.132M45.005 92.7146C45.005 95.1835 43.0035 97.185 40.5346 97.185C38.0656 97.185 36.0642 95.1835 36.0642 92.7146C36.0642 90.2456 38.0656 88.2441 40.5346 88.2441C43.0035 88.2441 45.005 90.2456 45.005 92.7146Z'
        stroke='url(#paint3_linear_5625_1491)'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.5938 29.6324C33.9142 27.1713 37.0646 25.6587 40.5346 25.6587C44.0046 25.6587 47.155 27.1713 49.4754 29.6324M45.005 17.2146C45.005 19.6835 43.0035 21.685 40.5346 21.685C38.0656 21.685 36.0642 19.6835 36.0642 17.2146C36.0642 14.7456 38.0656 12.7441 40.5346 12.7441C43.0035 12.7441 45.005 14.7456 45.005 17.2146Z'
        stroke='url(#paint4_linear_5625_1491)'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M175.971 70.6929C178.291 68.2318 181.442 66.7192 184.912 66.7192C188.382 66.7192 191.532 68.2318 193.852 70.6929M189.382 58.2751C189.382 60.7441 187.38 62.7455 184.912 62.7455C182.443 62.7455 180.441 60.7441 180.441 58.2751C180.441 55.8062 182.443 53.8047 184.912 53.8047C187.38 53.8047 189.382 55.8062 189.382 58.2751Z'
        stroke='url(#paint5_linear_5625_1491)'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g filter='url(#filter0_d_5625_1491)'>
        <circle
          cx='110.736'
          cy='62.7461'
          r='51.6582'
          fill='url(#paint6_linear_5625_1491)'
        />
      </g>
      <path
        d='M99.3325 65.6669L115.541 75.1122M115.518 49.0546L99.3325 58.4999M128.784 45.4711C128.784 49.4031 125.596 52.5907 121.664 52.5907C117.732 52.5907 114.545 49.4031 114.545 45.4711C114.545 41.5391 117.732 38.3516 121.664 38.3516C125.596 38.3516 128.784 41.5391 128.784 45.4711ZM100.306 62.0834C100.306 66.0155 97.118 69.203 93.186 69.203C89.2539 69.203 86.0664 66.0155 86.0664 62.0834C86.0664 58.1514 89.2539 54.9639 93.186 54.9639C97.118 54.9639 100.306 58.1514 100.306 62.0834ZM128.784 78.6957C128.784 82.6278 125.596 85.8153 121.664 85.8153C117.732 85.8153 114.545 82.6278 114.545 78.6957C114.545 74.7637 117.732 71.5762 121.664 71.5762C125.596 71.5762 128.784 74.7637 128.784 78.6957Z'
        stroke='url(#paint7_linear_5625_1491)'
        strokeOpacity='0.8'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_d_5625_1491'
          x='44.0781'
          y='0.0878906'
          width='133.316'
          height='133.316'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='7.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values={hexToColorMatrix(theme.palette.primary.main, 50)}
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_5625_1491'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_5625_1491'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_5625_1491'
          x1='184.912'
          y1='44.2031'
          x2='184.912'
          y2='86.5894'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.primary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5625_1491'
          x1='40.5331'
          y1='1.81641'
          x2='40.5331'
          y2='44.2026'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.primary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5625_1491'
          x1='41.1963'
          y1='77.3164'
          x2='41.1963'
          y2='125.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop
            offset='1'
            stopColor={theme.palette.primary.main}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_5625_1491'
          x1='40.5346'
          y1='78.641'
          x2='40.5346'
          y2='111.755'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_5625_1491'
          x1='40.5346'
          y1='3.14101'
          x2='40.5346'
          y2='36.2553'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_5625_1491'
          x1='184.912'
          y1='44.2016'
          x2='184.912'
          y2='77.3158'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_5625_1491'
          x1='108.087'
          y1='247.524'
          x2='110.736'
          y2='6.4519'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} stopOpacity='0.74' />
          <stop offset='1' stopColor={theme.palette.primary.main} />
        </linearGradient>
        <linearGradient
          id='paint7_linear_5625_1491'
          x1='106.763'
          y1='66.7199'
          x2='110.074'
          y2='-180.975'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop
            offset='1'
            stopColor={theme.palette.primary.main}
            stopOpacity='0.26'
          />
          <stop offset='1' stopColor='#D991F9' stopOpacity='0.58116' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShareSvg;
