import '@/icons/logo.css';
import React from 'react';

export default class Logo extends React.Component<{
  color?: string;
  size?: string;
  animated?: boolean;
}> {
  render(): React.ReactNode {
    return (
      <svg
        width="35"
        height="40"
        viewBox="0 0 35 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9862 20.2895V39.9991L0 30.0706V10.4102L16.9862 20.2895Z"
          fill="#333333"
        />
        <path
          d="M17.537 0L0.550781 9.86297L17.537 19.7259L34.5232 9.86297L17.537 0Z"
          fill="#333333"
        />
      </svg>
    );
  }
}
