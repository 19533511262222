import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const createStyleSheet = <T extends Record<string, Style>>(
  styleSheet: T
): T => {
  const keys = Object.keys(styleSheet);
  const defaults: Style = {
    fontFamily: 'Inter'
  };

  for (const key of keys) {
    styleSheet[key as keyof T] = {
      ...defaults,
      ...styleSheet[key as keyof T]
    };
  }

  return StyleSheet.create(styleSheet);
};

export { createStyleSheet };

