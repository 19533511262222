import { AppErrorMessage } from '@/components';
import { InputLabel, InputLabelProps } from '@mui/material';
import { FieldArray, FieldArrayRenderProps } from 'formik';

type IProps = {
  name: string;
  multiple?: boolean;
  placeholder?: string;
  label?: string;
  children(props: FieldArrayRenderProps): React.JSX.Element;
  labelProps?: InputLabelProps;
};

const AppFieldArray = ({ name, label, children, ...props }: IProps) => (
  <>
    {label && (
      <InputLabel
        sx={{
          '&:not(:first-of-type)': {
            mt: 1
          }
        }}
        {...props.labelProps}
      >
        {label}
      </InputLabel>
    )}
    <FieldArray name={name}>{children}</FieldArray>
    <AppErrorMessage name={name} />
  </>
);

export default AppFieldArray;

