import { useTranslation } from '@/components';
import { colorCalculator, makeArray } from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import { Box, Button, Grid, Typography } from '@mui/material';
import { StepPage, TopicZone } from '@survey/feedback/components';
import React, { ReactNode, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

type IProps = {
  user: {
    name: string;
    email: string;
  };
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  name?: string;
  relationship?: string;
  simple?: boolean;
  isFeedback: boolean;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
};

const WizardMultiOption = ({
  name,
  isFeedback,
  topic,
  simple,
  ...props
}: IProps) => {
  const { showToast } = useAppContext();
  const t = useTranslation('Feedback.Wizard');

  let from = 0;
  let sections =
    topic.sections && topic.sections.length > 0
      ? topic.sections.map((x) => {
          const items = x.itemIds.map(
            (y) => topic.items.find((z) => z.id === y)!
          );
          return {
            ...x,
            items,
            from: from,
            to: (from += items.length)
          };
        })
      : [
          {
            from: 0,
            id: '',
            itemIds: topic.items.map((x) => x.id),
            items: topic.items,
            title: '',
            to: topic.items.length
          }
        ];
  if (topic.sections && topic.sections.length > 0) {
    const itemsWithNoSection = topic.items.filter(
      (x) => !topic.sections!.any((y) => y.itemIds.any((z) => z === x.id))
    );
    if (itemsWithNoSection.length > 0) {
      sections = [
        {
          from: 0,
          id: '',
          itemIds: itemsWithNoSection.map((x) => x.id),
          items: itemsWithNoSection,
          title: '',
          to: itemsWithNoSection.length
        },
        ...sections.map((x) => ({
          ...x,
          from: itemsWithNoSection.length + x.from,
          to: itemsWithNoSection.length + x.to
        }))
      ];
    }
  }
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    makeArray(topic.items.length, '')
  );
  const [sectionConfirmed, setSectionConfirmed] = useState(
    sections && sections.length > 1 ? false : true
  );
  const [page, setPage] = useState(0);
  const [step, setStep] = useState(1);
  const [gotoRight, setGotoRight] = useState(false);
  const [gotoLeft, setGotoLeft] = useState(false);

  useEffect(() => {
    if (selectedOptions[page] === '') {
      return;
    }

    setTimeout(() => {
      if (page === topic.items.length - 1) {
        submit();
      }

      next();
    }, 500);
  }, [selectedOptions]);

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedOptions(
      selectedOptions.map((x, i) => {
        if (i === index) {
          return e.target.value;
        }

        return x;
      })
    );
  };

  const submit = async () => {
    if (step < 2 && simple !== true) {
      return setStep(2);
    }

    let hasError = false;
    for (let i = 0; i < topic.options!.length; i++) {
      const option = topic.options![i];
      const min = option.minSelect || 0;
      const max = option.maxSelect || 100;
      const responsesCount = selectedOptions.count((x) => x === option.id);

      if (responsesCount > max) {
        await showToast(
          t('YouCantHaveMoreThan', {
            max,
            name: option.title
          }),
          3e3,
          'error'
        );
        hasError = true;
      } else if (responsesCount < min) {
        await showToast(
          t('YouNeedToHaveAtleast', {
            min,
            name: option.title
          }),
          3e3,
          'error'
        );
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    await props.submit(
      topic.items.map((x, i) => {
        const option = topic.options?.find((y) => y.id === selectedOptions[i]);

        if (!option) {
          alert('Option is not valid!');
        }

        return {
          itemId: x.id,
          value: option?.value || option?.title || '',
          optionId: selectedOptions[i]
        };
      })
    );
  };

  const getAdjective = (index: number) => {
    const section = getSection(index);

    return section.items[index - section.from];
  };

  const previous = () => {
    setGotoLeft(true);

    setTimeout(() => {
      if (sections.length > 1) {
        if (sectionConfirmed && getSection().from === page) {
          setSectionConfirmed(false);
          setGotoLeft(false);
          return;
        }

        if (!sectionConfirmed) {
          setSectionConfirmed(true);
        }
      }

      setPage(page - 1);
      setGotoLeft(false);
    }, 500);
  };

  const next = () => {
    setGotoRight(true);

    setTimeout(() => {
      if (sections.length > 1) {
        if (!sectionConfirmed) {
          setSectionConfirmed(true);
          setGotoRight(false);
          return;
        }

        if (getSection().to === page + 1) {
          setSectionConfirmed(false);
        }
      }

      setPage(page + 1);
      setGotoRight(false);
    }, 500);
  };

  const getSection = (index?: number) =>
    sections.length === 1
      ? sections[0]
      : sections.find(
          (x) => x.from <= (index || page) && x.to > (index || page)
        )!;

  const onDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.source.droppableId === result.destination.droppableId
    ) {
      return;
    }

    let items = selectedOptions;
    const index = topic.items.findIndex((x) => x.id === result.draggableId);

    const option = topic.options!.find(
      (x) => x.id === result.destination?.droppableId
    );
    if (!option) {
      return;
    }

    items[index] = result.destination.droppableId;

    setSelectedOptions(items);
  };

  const checkMinMaxOptions = () => {
    for (let i = 0; i < topic.options!.length; i++) {
      const option = topic.options![i];
      const min = option.minSelect || 0;
      const responsesCount = selectedOptions.count((x) => x === option.id);

      if (responsesCount < min) {
        return true;
      }
    }

    return false;
  };

  if (step === 2) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        py={{
          xs: 0,
          md: 4
        }}
        mx={{
          xs: 'unset',
          sm: 'calc((100vw - (768px - 160px)) / 2)',
          md: 'calc((100vw - (850px - 160px)) / 2)',
          lg: 'calc((100vw - (1100px - 160px)) / 2)'
        }}
      >
        <Typography variant='h2' sx={{ mt: 3 }}>
          {t('LetsDoFinalCheck').toTsx()}
        </Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={36 / 12} mt={1}>
            {topic.options?.map((x) => (
              <Grid item xs={12} sm={6} md={4} key={x.id}>
                <TopicZone
                  id={x.id}
                  title={x.title}
                  items={selectedOptions
                    .filter((y) => y === x.id)
                    .map((y, i) => ({
                      id: topic.items[i].id,
                      name:
                        topic.items.find((z) => z.id === topic.items[i].id)
                          ?.name || ''
                    }))}
                  min={x.minSelect}
                  color={colorCalculator((x.value / 5) * 100)}
                  t={t}
                />
              </Grid>
            ))}
          </Grid>
        </DragDropContext>
        <Box mt={2} textAlign='right'>
          <Button
            disabled={checkMinMaxOptions()}
            type='button'
            variant='contained'
            color='primary'
            onClick={submit}
          >
            {t('$Submit')}
          </Button>
        </Box>
      </Box>
    );
  }

  const getHeadline = () => {
    if (isFeedback) {
      if (topic.othersHeadline) {
        return topic.othersHeadline.replace('{name}', name!);
      }

      return t('HowDoYouSeeIn', {
        name
      }).toTsx();
    }

    if (topic.selfHeadline) {
      return topic.selfHeadline;
    }

    return t('HowDoYouSeeIn', {
      name: t('$Feedback.Yourself')
    }).toTsx();
  };

  const isSection = () => {
    const section = getSection();

    return page >= topic.items.length
      ? false
      : sections.length > 1 &&
          page === section.from &&
          !sectionConfirmed &&
          section.id !== '';
  };

  const renderPages = () => {
    if (page >= topic.items.length) {
      return <></>;
    }

    const section = getSection();

    const previousIsSection =
      sections.length > 1 &&
      page === section.from &&
      sectionConfirmed &&
      section.id !== '';
    const currentIsSection = isSection();
    const nextIsSection =
      sections.length > 1 &&
      page + 1 === section.to &&
      topic.items.length > page + 1;

    const res: ReactNode[] = [];

    if (previousIsSection) {
      res.push(
        <StepPage
          section={section}
          next={next}
          previous={previous}
          topic={topic}
          position={'left'}
          canGoToNext
        />
      );
    } else if (page > 0) {
      res.push(
        <StepPage
          item={getAdjective(page - 1)}
          handleChange={(e) => handleChange(page - 1, e)}
          index={page}
          next={next}
          previous={previous}
          submit={submit}
          topic={topic}
          optionId={selectedOptions[page - 1]}
          position={'left'}
          canGoToNext
        />
      );
    }

    if (currentIsSection) {
      res.push(
        <StepPage
          section={section}
          next={next}
          previous={previous}
          canGoToPrevious={res.length === 1}
          canGoToNext
          topic={topic}
        />
      );
    } else {
      res.push(
        <StepPage
          item={getAdjective(page)}
          handleChange={(e) => handleChange(page, e)}
          index={page}
          next={next}
          previous={previous}
          submit={submit}
          topic={topic}
          optionId={selectedOptions[page]}
          canGoToPrevious={res.length === 1}
          canGoToNext={topic.items.length > page + 1 && !!selectedOptions[page]}
        />
      );
    }

    if (nextIsSection) {
      res.push(
        <StepPage
          section={section}
          next={next}
          previous={previous}
          position={'right'}
          canGoToPrevious
          topic={topic}
        />
      );
    } else if (topic.items.length - 1 > page) {
      res.push(
        <StepPage
          item={getAdjective(currentIsSection ? page : page + 1)}
          handleChange={(e) =>
            handleChange(currentIsSection ? page : page + 1, e)
          }
          index={page}
          next={next}
          previous={previous}
          submit={submit}
          topic={topic}
          optionId={selectedOptions[currentIsSection ? page : page + 1]}
          position={'right'}
          canGoToPrevious
        />
      );
    }

    return res;
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      py={{
        xs: 0,
        md: 4
      }}
      mx={{
        xs: 'unset',
        sm: 'calc((100vw - 650px) / 2)',
        md: 'calc((100vw - 750px) / 2)',
        lg: 'calc((100vw - 850px) / 2)'
      }}
    >
      <Typography variant='h2' sx={{ mt: 3, pl: 1 }}>
        {isSection() ? getSection().title : getHeadline()}
      </Typography>
      <Box overflow='hidden' mt={2}>
        <Box
          position='relative'
          m='10px'
          sx={{
            transition: gotoRight || gotoLeft ? 'all 0.5s ease' : undefined,
            transform: gotoRight
              ? 'translateX(calc(-100% - 32px))'
              : gotoLeft
              ? 'translateX(calc(100% + 32px))'
              : undefined
          }}
        >
          {renderPages()}
        </Box>
      </Box>
    </Box>
  );
};

export default WizardMultiOption;
