import { Alert, useTranslation } from '@/components';
import { CodeTypes } from '@/enums';
import { Logger, webClient } from '@/helpers';
import SurveyReportJohari from '@/pages/survey/report/johari';
import { useAppContext } from '@contexts/app-context';
import SurveyFeedback from '@survey/feedback';
import SurveyIndex from '@survey/index';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

interface ICodeResponse {
  type: CodeTypes;
  metadata: Record<string, any>;
}

interface IState {
  type: CodeTypes | -1;
  metadata: Record<string, any>;
}

const CodeRouter = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IState>({
    type: -1,
    metadata: {}
  });
  const code = useParams<'code'>().code!;
  const navigate = useNavigate();
  const t = useTranslation();
  const { showToast } = useAppContext();

  localStorage.clear();

  useEffect(() => {
    async function checkCode() {
      setLoading(true);
      try {
        let res = await webClient.get<SingleResponse<ICodeResponse>>(
          `/short-codes/${code}/`
        );
        setData(res.data.data);
        Logger.log(res.data.data);
        setLoading(false);
      } catch (exp) {
        setData({
          type: CodeTypes.Invalid,
          metadata: {}
        });
        setLoading(false);
      }
    }

    if (data.type === -1 && !loading) {
      checkCode();
    }
  }, [data, loading, code]);

  switch (data.type) {
    case CodeTypes.Feedback:
      return <SurveyFeedback code={code} name={data.metadata.name} />;

    case CodeTypes.Report:
      return <SurveyReportJohari />;

    case CodeTypes.Project:
      return <SurveyIndex project={code} />;

    case CodeTypes.VerifyEmail:
      navigate('/verify');
      return null;

    case CodeTypes.Invalid:
      showToast(t('CodeIsNotValid'), 3e3, 'error');
      navigate('/', { replace: true });
      return null;

    case CodeTypes.GainAccess:
      return (
        <Alert
          text={t('OrganizerGainAccess')}
          closable
          onClose={() => navigate('/')}
        />
      );

    case CodeTypes.CancelAccess:
      return (
        <Alert
          text={t('OrganizerCancelAccess')}
          closable
          onClose={() => navigate('/')}
        />
      );

    default:
      return null;
  }
};

export default CodeRouter;
