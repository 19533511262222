import { Box, BoxProps } from '@mui/material';

export default function Frame(props: BoxProps) {
  return (
    <Box
      borderRadius="24px"
      boxShadow="0 0 10px 2px rgba(0,0,0,0.1)"
      {...props}
    >
      {props.children}
    </Box>
  );
}

