import { useReportContext } from '@/contexts/report-context';
import { fontWeights } from '@/helpers';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import ChapterIcon from './chapter-icon';

const DetailedContents = () => {
  const theme = useTheme();
  const { items } = useReportContext();

  const scrollToContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const section = document.getElementById(
      e.currentTarget.href.replace(/^[^#]+#/, '').replace(/[-_]/g, '')
    );
    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <Box px={32 / 12}>
      <Grid
        container
        borderLeft='solid 1.5px #6D6E71'
        borderRight='solid 1.5px #6D6E71'
      >
        {items.map((x, i) => (
          <Grid
            item
            display='flex'
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={12 / 5}
            key={`detailedDataContents${i}`}
            borderRight={{
              xs: 'none',
              sm: `solid 1.5px #6D6E71`
            }}
            pr={16 / 12}
            pl={4}
            sx={{
              [theme.breakpoints.only('sm')]: {
                [`&:nth-child(2n),&:nth-child(${items.length})`]: {
                  borderRight: 'none'
                },
                [`&:nth-child(${
                  Math.floor((items.length - 1) / 2) * 2
                }) ~ * > div`]: {
                  borderBottom: 'none'
                }
              },
              [theme.breakpoints.only('md')]: {
                [`&:nth-child(3n),&:nth-child(${items.length})`]: {
                  borderRight: 'none'
                },
                [`&:nth-child(${
                  Math.floor((items.length - 1) / 3) * 3
                }) ~ * > div`]: {
                  borderBottom: 'none'
                }
              },
              [theme.breakpoints.only('lg')]: {
                [`&:nth-child(4n),&:nth-child(${items.length})`]: {
                  borderRight: 'none'
                },
                [`&:nth-child(${
                  Math.floor((items.length - 1) / 4) * 4
                }) ~ * > div`]: {
                  borderBottom: 'none'
                }
              },
              [theme.breakpoints.only('xl')]: {
                [`&:nth-child(5n),&:nth-child(${items.length})`]: {
                  borderRight: 'none'
                },
                [`&:nth-child(${
                  Math.floor((items.length - 1) / 5) * 5
                }) ~ * > div`]: {
                  borderBottom: 'none'
                }
              }
            }}
          >
            <Box
              pt={{
                xs: 0.5,
                sm: 0
              }}
              pb={{
                xs: 0.5,
                sm: 1
              }}
              my={{
                xs: 0,
                sm: 1
              }}
              borderBottom={{
                xs: 'none',
                sm: `solid 1.5px #6D6E71`
              }}
            >
              <Box
                component='a'
                href={`#${x.id}`}
                onClick={scrollToContent}
                display='flex'
                flexDirection={{
                  xs: 'row',
                  sm: 'column'
                }}
                alignItems={{
                  xs: 'center',
                  sm: 'unset'
                }}
                sx={{
                  textDecoration: 'none',
                  color: '#6D6E71',
                  '&:hover': {
                    color: theme.palette.primary.main
                  }
                }}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  mr={{
                    xs: 1,
                    sm: 0
                  }}
                  mb={{
                    xs: 0,
                    sm: 16 / 12
                  }}
                >
                  <ChapterIcon width='24px' />
                  <Typography
                    fontSize={24}
                    fontWeight={fontWeights.black}
                    color='inherit'
                    ml={1}
                  >
                    {(i + 1).toFixedLength(2)}
                  </Typography>
                </Box>
                <Typography
                  fontSize={16}
                  fontWeight={fontWeights.extraLight}
                  color='black'
                >
                  {x.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DetailedContents;
