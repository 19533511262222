import { GRecaptchaAnimation } from '@/animations';
import { LazyTyping, Progress } from '@/components';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe,
  useTranslation
} from '@/components/multi-lang';
import { ReportTypes } from '@/enums';
import {
  classNamer,
  colorCalculator,
  fontWeights,
  getCdnUrl,
  GRecaptcha,
  Theme,
  webClient
} from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import styles from '@survey/report/report.module.scss';
import { AxiosError } from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';

const LazyLottie = React.lazy(() => import('lottie-react'));
const skipCaptcha = false;

interface ITag {
  title: string;
  value?: number;
}

interface IAnalysis {
  type: number;
  tags: ITag[];
  reports: {
    title?: string;
    content: string;
  }[];
  thumb: boolean | null;
}

type ISurvey =
  | {
      name: string;
      topic: string;
      analysis: IAnalysis[];
      individual: undefined | false;
      nextLevel?: string;
    }
  | {
      name: string;
      tags: ITag[];
      reports: {
        title?: string;
        content: string;
      }[];
      individual: true;
      progress: number;
    };

interface IReport {
  title: string;
  tags?: ITag[];
  reports: {
    title?: string;
    content: string;
  }[];
  type: ReportTypes;
  image: string;
  color: 'primary' | 'yellow' | 'success' | 'dark';
  thumb: boolean | null;
}

interface IState {
  activeTab: number;
  name: string;
  topic: string;
  readMore: boolean;
  window: boolean;
  tabs: IReport[];
  verified: boolean;
  maxHeight: string;
  space: string;
  nextLevel?: string;
  individual?: {
    tags: ITag[];
    name: string;
    reports: {
      title?: string;
      content: string;
    }[];
    progress: number;
  };
}

const TabItem = styled(Box)(({ theme }) => ({
  fontSize: '12px',
  padding: '16px',
  flex: 'calc(50% - 4px) 1 1',
  lineHeight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.5s ease-in-out',
  fontWeight: fontWeights.semiBold,
  flexDirection: 'column',
  borderRadius: '0 10px 10px 0',
  '&.active': {
    opacity: 1,
    boxShadow: '0 4px 4px #0008',
    zIndex: 1
  },
  [theme.breakpoints.up('md')]: {
    flex: '1 1 0px',
    '&:not(:last-of-type)': {
      marginBottom: '26px'
    }
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    '&:nth-of-type(2n)': {
      borderRadius: '0 10px 10px 0',
      marginLeft: '8px'
    },
    '&:nth-of-type(2n-1)': {
      borderRadius: '10px 0 0 10px'
    },
    '&.read-more': {
      fontSize: '7px',
      padding: '6px',
      borderRadius: '10px !important',
      lineHeight: '8px',
      opacity: 0.8,
      '&:not(:first-of-type)': {
        marginLeft: '7px !important'
      },
      '&.active': {
        transform: 'scale(1.1)'
      }
    }
  }
}));

const SlickButtonFix = (props: {
  children: JSX.Element;
  slideCount?: number;
  currentSlide?: number;
}) => {
  const { children, currentSlide, slideCount, ...others } = props;
  return (
    <Box
      component='span'
      bgcolor='#EAEAEA'
      textAlign='center'
      lineHeight='33px'
      {...others}
    >
      {children}
    </Box>
  );
};

const SurveyReportJohari = () => {
  const { setMainClasses, setError } = useAppContext();
  const t = useTranslation('Reports.Johari');
  const theme = useTheme();
  const { code } = useParams<'code'>();

  let slider: Slider;
  let contentArea = React.createRef<HTMLDivElement>();
  let grecaptchaId: string | null = null;

  const getMaxHeight = () => {
    const content = contentArea?.current;
    let padding: string | number = getIsMobile() ? 2.5 : 7.5;
    if (!!state && !!state.nextLevel) {
      padding += 5;
    }

    padding = `${padding}rem`;
    if (!content) {
      return `calc(100vh - 332px - ${padding})`;
    }

    return `calc(100vh - ${content.getTotalOffsetTop()}px - ${padding})`;
  };

  const [state, setState] = useState<IState>({
    verified: false,
    activeTab: 0,
    name: '',
    topic: '',
    readMore: false,
    window: true,
    space: '32px',
    tabs: [
      {
        title: 'YourAllKnown',
        color: 'primary',
        type: ReportTypes.AllKnown,
        image: getCdnUrl('/images/all-known.svg'),
        thumb: null,
        reports: []
      },
      {
        title: 'YourFriendsKnown',
        color: 'dark',
        type: ReportTypes.FriendsKnown,
        image: getCdnUrl('/images/friends-known.svg'),
        thumb: null,
        reports: []
      },
      {
        title: 'YourPersonKnown',
        color: 'yellow',
        type: ReportTypes.PersonKnown,
        image: getCdnUrl('/images/person-known.svg'),
        thumb: null,
        reports: []
      },
      {
        title: 'YourUnknown',
        color: 'success',
        type: ReportTypes.Unknown,
        image: getCdnUrl('/images/unknown.svg'),
        thumb: null,
        reports: []
      }
    ],
    maxHeight: getMaxHeight(),
    individual: undefined
  });

  const getGrecaptchaPlaceholder = () =>
    document.getElementById('gPlaceholder');

  const recaptchaCallback = async (token: any) => {
    setMainClasses();
    try {
      const res = await webClient.post<ISurvey>(`/short-codes/${code}/`, {
        token
      });
      if (res.data.individual === true) {
        setState({
          ...state,
          individual: {
            name: res.data.name,
            tags: res.data.tags,
            reports: res.data.reports,
            progress: res.data.progress
          },
          verified: true
        });
        return;
      }

      let tabs = [...state.tabs];
      let allKnownTab = { ...tabs[0] };
      const allKnown = res.data.analysis.find(
        (x) => x.type === ReportTypes.AllKnown
      ) as IAnalysis;
      allKnownTab.reports = allKnown.reports;
      allKnownTab.tags = allKnown.tags;
      allKnownTab.thumb = allKnown.thumb;

      let friendsKnownTab = { ...tabs[1] };
      const friendsKnown = res.data.analysis.find(
        (x) => x.type === ReportTypes.FriendsKnown
      ) as IAnalysis;
      friendsKnownTab.reports = friendsKnown.reports;
      friendsKnownTab.tags = friendsKnown.tags;
      friendsKnownTab.thumb = friendsKnown.thumb;

      let personKnownTab = { ...tabs[2] };
      const personKnown = res.data.analysis.find(
        (x) => x.type === ReportTypes.PersonKnown
      ) as IAnalysis;
      personKnownTab.reports = personKnown.reports;
      personKnownTab.tags = personKnown.tags;
      personKnownTab.thumb = personKnown.thumb;

      let unknownTab = { ...tabs[3] };
      const unknown = res.data.analysis.find(
        (x) => x.type === ReportTypes.Unknown
      ) as IAnalysis;
      unknownTab.reports = unknown.reports;
      unknownTab.tags = unknown.tags;
      unknownTab.thumb = unknown.thumb;

      tabs = [allKnownTab, friendsKnownTab, personKnownTab, unknownTab];

      setState({
        ...state,
        name: res.data.name,
        topic: res.data.topic,
        tabs,
        individual: undefined,
        verified: true,
        nextLevel: res.data.nextLevel
      });

      contentArea.current?.addEventListener('swipeUp', async () => {
        if (state.readMore) {
          return;
        }

        if (state.readMore) {
          return;
        }

        setState({
          ...state,
          readMore: true
        });
      });
      contentArea.current?.addEventListener('swipeDown', async () => {
        if (!state.readMore) {
          return;
        }

        const mainElement = document.getElementsByTagName('main')[0];
        if (mainElement.scrollTop > 0) {
          return;
        }

        setState({
          ...state,
          readMore: false
        });
      });
    } catch (exp) {
      if ((exp as AxiosError).response?.status === 504) {
        await recaptchaCallback(token);
        return;
      }
      await setError('404');
    }
  };

  const renderCaptcha = (retry?: number) => {
    if ((!window.grecaptcha || !window.grecaptcha.render) && (retry || 0) < 4) {
      setTimeout(() => renderCaptcha((retry || 0) + 1), 2000);
      return;
    }

    if (!window.grecaptcha || !window.grecaptcha.render) {
      return document.location.reload();
    }

    if (grecaptchaId !== null) {
      window.grecaptcha.reset(grecaptchaId);
      grecaptchaId = null;
    }

    if (getGrecaptchaPlaceholder()?.firstElementChild === null) {
      grecaptchaId = window.grecaptcha.render('gPlaceholder', {
        sitekey: GRecaptcha,
        callback: recaptchaCallback
      });
    }
  };

  const getIsMobile = () => window.innerWidth < theme.breakpoints.values.md;

  const setActiveTab = (activeTab: number) => {
    slider?.slickGoTo(activeTab);
    setState({
      ...state,
      activeTab
    });
  };

  const updateMaxHeight = () => {
    const maxHeight = getMaxHeight();
    if (maxHeight !== state.maxHeight) {
      setState({
        ...state,
        maxHeight: getMaxHeight()
      });
    }

    if (getIsMobile() && state.space !== '32px') {
      setState({
        ...state,
        space: '32px'
      });
    } else if (!getIsMobile() && state.space !== '116px') {
      setState({
        ...state,
        space: '116px'
      });
    }

    setTimeout(updateMaxHeight, 1000);
  };

  useEffect(() => {
    let languageSubscriptionId = languageChangeSubscribe(() => {
      setState({
        ...state,
        verified: false
      });
      renderCaptcha();
    });

    if (skipCaptcha) {
      recaptchaCallback('skip').then(() => {
        renderCaptcha();
        updateMaxHeight();
      });
      return () => {
        languageChangeUnsubscribe(languageSubscriptionId);
      };
    }

    renderCaptcha();
    updateMaxHeight();

    return () => {
      languageChangeUnsubscribe(languageSubscriptionId);
    };
  }, []);

  if (!state.verified) {
    return (
      <Box
        m='auto'
        width='304px'
        sx={{
          [theme.breakpoints.up('md')]: {
            m: 'auto calc((100vw - 5rem - 304px) / 2)',
            width: 'auto'
          }
        }}
      >
        <Typography mb={2} variant='h4' fontWeight={fontWeights.bold}>
          {t('YourReportIsReady')}
        </Typography>
        <div id='gPlaceholder'></div>
        <Box
          mt={2}
          border='solid 1px'
          borderColor={Theme.palette.primary.main}
          borderRadius='10px'
          p={1}
        >
          <Typography variant='subtitle2' mb={1}>
            {t('JustAQuickCheck')}
          </Typography>
          <Suspense fallback={<CircularProgress />}>
            <LazyLottie animationData={GRecaptchaAnimation} />
          </Suspense>
        </Box>
      </Box>
    );
  }

  if (!!state.individual) {
    return (
      <Box
        border={`solid 1px ${theme.palette.primary.main}`}
        borderRadius='10px'
        mx={{
          xs: 'unset',
          sm: 'calc((100vw - 768px) / 2)',
          md: 'calc((100vw - 900px) / 2)',
          lg: 'calc((100vw - 1100px) / 2)'
        }}
        overflow='hidden'
      >
        <Box
          display='flex'
          flexDirection='column'
          borderRadius='10px'
          bgcolor='#F5F5F5'
          m={8 / 12}
        >
          <Typography
            variant='h1'
            fontSize='14px'
            fontWeight={fontWeights.extraBold}
            bgcolor={theme.palette.primary.main}
            color='white'
            borderRadius='5px'
            py={1}
            textAlign='center'
          >
            {t('FirstLookIntoYourProfile')}
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            bgcolor='white'
            borderRadius='0 0 10px 10px'
            mx={8 / 12}
            pb={2}
            mb={1}
          >
            <List
              sx={{
                px: '20px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {state.individual.tags.map((x, i) => (
                <ListItem
                  sx={{
                    border: `solid 2px ${
                      typeof x.value === 'undefined'
                        ? theme.palette.primary.main
                        : colorCalculator(x.value)
                    }`,
                    borderRadius: '10px',
                    p: 1,
                    width: 'auto',
                    alignSelf: i % 2 === 0 ? 'flex-start' : 'flex-end',
                    mt: i > 0 ? '5px' : 0,
                    position: 'relative',
                    bgcolor: 'white',
                    maxWidth: '80%'
                  }}
                  key={x.title}
                >
                  <Box
                    position='absolute'
                    borderLeft='solid 7px transparent'
                    borderRight='solid 7px transparent'
                    borderTop={`solid 13px ${
                      typeof x.value === 'undefined'
                        ? theme.palette.primary.main
                        : colorCalculator(x.value)
                    }`}
                    top='100%'
                    left={i % 2 === 0 ? 'calc(100% - 32px)' : '20px'}
                  />
                  <Box
                    position='absolute'
                    borderLeft='solid 6px transparent'
                    borderRight='solid 6px transparent'
                    borderTop='solid 12px white'
                    top='100%'
                    left={i % 2 === 0 ? 'calc(100% - 31px)' : '21px'}
                  />
                  {x.title}
                </ListItem>
              ))}
            </List>
            <Typography
              bgcolor={theme.palette.primary.main}
              color='white'
              variant='h3'
              borderRadius='10px'
              fontSize='16px'
              fontWeight={fontWeights.semiBold}
              alignSelf='center'
              p='8px'
            >
              {state.individual.name}
            </Typography>
          </Box>
          {state.individual.reports.map((x, i) => (
            <>
              {x.title && (
                <Typography
                  key={`individualReport${i}Title`}
                  variant='h2'
                  mx='20px'
                  my='8px'
                >
                  {x.title}
                </Typography>
              )}
              <p
                key={`individualReport${i}Content`}
                style={{
                  margin: '8px 20px 20px'
                }}
              >
                {x.content}
              </p>
            </>
          ))}
        </Box>
        <Progress progress={state.individual.progress} />
      </Box>
    );
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      m={-2.5}
      sx={{
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          backgroundColor: '#F5F5F5'
        }
      }}
    >
      <Hidden mdDown>
        <Box
          pt={12}
          textAlign='center'
          flex='calc(200px + 4rem) 0 0'
          px={4}
          pb='calc(100vh - 16rem - 420px)'
          bgcolor='white'
          justifyContent='center'
          display='flex'
          flexDirection='column'
        >
          {state.tabs.map((tab, i) => (
            <TabItem
              sx={{
                background:
                  tab.type === ReportTypes.AllKnown
                    ? theme.palette.primary.main
                    : tab.type === ReportTypes.PersonKnown
                    ? `linear-gradient(to left, #011936, #011936 48%, ${theme.palette.primary.main} 52%, ${theme.palette.primary.main})`
                    : tab.type === ReportTypes.FriendsKnown
                    ? `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.primary.main} 48%, #011936 52%, #011936)`
                    : '#011936'
              }}
              color='white'
              onClick={() => setActiveTab(i)}
              className={classNamer({
                active: state.activeTab === i,
                'read-more': state.readMore
              })}
              key={tab.type}
            >
              <img
                style={{
                  marginBottom: '8px',
                  maxHeight: '32px',
                  display: getIsMobile() && state.readMore ? 'none' : undefined
                }}
                src={tab.image}
                alt={t(tab.title)}
              />
              {t(tab.title)}
            </TabItem>
          ))}
        </Box>
      </Hidden>
      <Box
        pt={2}
        display='flex'
        flexDirection='column'
        height='calc(100vh - 5rem)'
        maxWidth='100vw'
        overflow={'visible clip'}
        sx={{
          [theme.breakpoints.up('md')]: {
            p: 4,
            maxWidth: 'calc(100vw - 200px - 2.5rem)',
            height: 'calc(100vh - 4rem)'
          }
        }}
      >
        <Typography
          variant='h1'
          fontWeight={fontWeights.black}
          fontSize='22px'
          textAlign='center'
          display={getIsMobile() && state.readMore ? 'none' : 'block'}
          px='32px'
          sx={{
            [theme.breakpoints.up('md')]: {
              textAlign: 'left'
            }
          }}
        >
          {t('WelcomeBack', { name: state.name })}
        </Typography>
        <Typography
          variant='body2'
          textAlign='center'
          display={getIsMobile() && state.readMore ? 'none' : 'block'}
          px='32px'
          sx={{
            [theme.breakpoints.up('md')]: {
              textAlign: 'left'
            }
          }}
        >
          {t('HereIsJoHari')}
        </Typography>
        <Hidden mdUp>
          <Box
            mt={1}
            mx={state.readMore ? 0 : 2}
            display='flex'
            flexDirection='row'
            flexWrap={state.readMore ? 'nowrap' : 'wrap'}
            p={state.readMore ? undefined : 1}
            textAlign='center'
          >
            {state.tabs.map((tab, i) => (
              <TabItem
                sx={{
                  background:
                    tab.type === ReportTypes.AllKnown
                      ? theme.palette.primary.main
                      : tab.type === ReportTypes.PersonKnown
                      ? `linear-gradient(to left, #011936, #011936 48%, ${theme.palette.primary.main} 52%, ${theme.palette.primary.main})`
                      : tab.type === ReportTypes.FriendsKnown
                      ? `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.primary.main} 48%, #011936 52%, #011936)`
                      : '#011936'
                }}
                key={`mobileTabs${tab.type}`}
                color='white'
                onClick={() => setActiveTab(i)}
                className={classNamer({
                  active: state.activeTab === i,
                  'read-more': state.readMore
                })}
              >
                <img
                  style={{
                    marginBottom: '8px',
                    maxHeight: '32px',
                    display: state.readMore ? 'none' : undefined
                  }}
                  src={tab.image}
                  alt={t(tab.title)}
                />
                {t(tab.title)}
              </TabItem>
            ))}
          </Box>
        </Hidden>
        <Box
          ref={contentArea}
          m='8px 0 0'
          sx={{
            [theme.breakpoints.up('md')]: {
              m: '32px 32px 0',
              bgcolor: 'white',
              px: 1,
              py: 2,
              borderRadius: '10px'
            }
          }}
        >
          <Slider
            className={classNamer(styles.reports, {
              [styles['read-more']]: state.readMore
            })}
            ref={(slider) => (slider = slider)}
            centerMode={!state.readMore}
            centerPadding={state.space}
            swipe={true}
            arrows={!getIsMobile()}
            nextArrow={
              <SlickButtonFix>
                <ArrowForwardIos />
              </SlickButtonFix>
            }
            prevArrow={
              <SlickButtonFix>
                <ArrowBackIosNew />
              </SlickButtonFix>
            }
            beforeChange={async (_, tab) =>
              setState((x) => ({
                ...x,
                activeTab: tab
              }))
            }
          >
            {state.tabs.map((tab, i) => (
              <Box
                key={`sliderTabls${tab.type}`}
                p='0 4px'
                sx={{
                  opacity: i === state.activeTab ? 1 : 0.2
                }}
              >
                <Box
                  borderRadius='5px'
                  position='relative'
                  p='8px'
                  bgcolor={Theme.palette.grey[100]}
                  overflow='hidden'
                  maxHeight={state.maxHeight}
                  height={state.maxHeight}
                  display='flex'
                  flexDirection='column'
                >
                  <Box
                    position='absolute'
                    left={0}
                    top={0}
                    right={0}
                    height='50px'
                    zIndex={1}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                      background:
                        tab.type === ReportTypes.AllKnown
                          ? theme.palette.primary.main
                          : tab.type === ReportTypes.PersonKnown
                          ? `linear-gradient(290deg, #011936, #011936 54%, ${theme.palette.primary.main} 58%, ${theme.palette.primary.main})`
                          : tab.type === ReportTypes.FriendsKnown
                          ? `linear-gradient(290deg, ${theme.palette.primary.main}, ${theme.palette.primary.main} 54%, #011936 58%, #011936)`
                          : '#011936'
                    }}
                  >
                    <Hidden mdDown>
                      <Typography mr={1} variant='h4' color='white'>
                        {t(tab.title)}
                      </Typography>
                    </Hidden>
                    <img
                      src={getCdnUrl(`/images/report-${tab.type}.svg`)}
                      alt={t(tab.title)}
                    />
                  </Box>
                  <LazyTyping
                    title={tab.title}
                    code={code!}
                    tags={tab.tags}
                    reports={tab.reports}
                    type={tab.type}
                    speed={4}
                    color={tab.color}
                    disabled
                    thumb={tab.thumb}
                    open={state.readMore}
                    onOpened={() =>
                      setState((x) => ({
                        ...x,
                        readMore: true
                      }))
                    }
                    onClosed={() =>
                      setState((x) => ({
                        ...x,
                        readMore: false
                      }))
                    }
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        {!!state.nextLevel && (
          <Box mt={3} px={3}>
            <Button
              variant='contained'
              color='primary'
              fullWidth={getIsMobile()}
              component={Link}
              to={state.nextLevel}
            >
              Go to next level
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SurveyReportJohari;
