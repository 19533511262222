import { useTranslation } from '@/components';
import { translate } from '@/helpers';
import { Language } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';

interface IProps {
  text?: string;
  lang: Languages;
  name: string;
}

const TranslateWithAIButton = ({ text, lang, name }: IProps) => {
  const t = useTranslation();
  const { setFieldValue } = useFormikContext();

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    try {
      await translate(text!, lang, name, setFieldValue);
    } catch (exp) {}
    setLoading(false);
  };

  if (!text) {
    return <></>;
  }

  return (
    <InputAdornment position='start'>
      <Tooltip title={t('Translate with AI')}>
        <IconButton disabled={loading} onClick={submit}>
          {loading ? <CircularProgress size={14} /> : <Language />}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

export default TranslateWithAIButton;
