import { A4Page, ChapterCover } from '@survey/report/pdf';

type IProps = TopicItemResponse & {
  index?: string;
  cover?: string;
  tables?: TableResponseType[];
  aiReports?: SimpleAIReportResponse[];
  options: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  section?: string;
  charts?: ChartResponse[];
  single?: boolean;
};

const ReportItem = ({
  id,
  index,
  cover,
  name,
  tables,
  aiReports,
  options,
  section,
  relations,
  familiarity,
  charts,
  single
}: IProps) => {
  const aiReport = aiReports?.find((x) => x.itemId === id);

  return (
    <>
      <ChapterCover
        image={cover}
        index={index}
        name={name}
        section={section}
        single={single}
      />
      <A4Page></A4Page>
    </>
  );
};

export default ReportItem;
