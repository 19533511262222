import { createContext, useContext } from 'react';

const UserContext = createContext<IUserContext>({} as any);
const useUserContext = () => useContext(UserContext);

export { useUserContext };

export type UserContextType = React.ContextType<typeof UserContext>;

export default UserContext;
