/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Array.prototype.any = function (predicate) {
  predicate = predicate || (() => true);
  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i], i)) {
      return true;
    }
  }

  return false;
};

Array.prototype.sum = function (selection) {
  let total = 0;
  for (let i = 0; i < this.length; i++) {
    total += selection(this[i], i);
  }

  return total;
};

Array.prototype.average = function (selection, divider) {
  return this.sum(selection) / (divider ? this.sum(divider) : this.length);
};

Array.prototype.where = function (predicate) {
  let res = [];
  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i], i)) {
      res.push(this[i]);
    }
  }

  return res;
};

Array.prototype.count = function (predicate) {
  let res = 0;
  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i], i)) {
      res++;
    }
  }

  return res;
};

Array.prototype.groupBy = function (predicate) {
  let res: GroupedArray<any, any> = [];

  for (let i = 0; i < this.length; i++) {
    const key = predicate(this[i], i);
    let group = res.find((x) => x.group === key);
    if (group) {
      group.items.push(this[i]);
    } else {
      res.push({
        group: key,
        items: [this[i]]
      });
    }
  }

  return res;
};

Array.prototype.last = function (predicate) {
  predicate = predicate || (() => true);
  const filtered = this.where(predicate);
  return filtered.length === 0 ? undefined : filtered[filtered.length - 1];
};

Array.prototype.emptyAsUndefined = function () {
  if (this.length === 0) {
    return;
  }

  return this;
};

Array.prototype.contains = function (item) {
  return this.any((x) => x === item);
};

export { };

