import { Checkbox } from '@/animations';
import { Box } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';

type IProps = {
  value: string;
  text: string;
  description?: string;
  active: boolean;
  onClick: (value: string) => void;
};

const BehaviourTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px',
    maxWidth: '400px'
  }
});

const Behaviour = ({ value, active, text, description, onClick }: IProps) => {
  const theme = useTheme();

  const renderBody = () => {
    return (
      <Box
        onClick={() => onClick(value)}
        position='relative'
        height='45px'
        borderRadius='10px'
        sx={{
          cursor: 'pointer'
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <Box
          width='100%'
          height='100%'
          borderRadius='10px'
          lineHeight='18px'
          px='12px'
          bgcolor='white'
          fontSize='15px'
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          component='span'
          border='solid 2px transparent'
          borderColor={active ? theme.palette.success.main : 'transparent'}
          sx={{
            userSelect: 'none'
          }}
        >
          <Checkbox checked={active} />
          {text}
        </Box>
      </Box>
    );
  };

  if (description) {
    return (
      <BehaviourTooltip
        enterDelay={1000}
        enterTouchDelay={0}
        title={description}
        arrow
        placement='top'
      >
        {renderBody()}
      </BehaviourTooltip>
    );
  }

  return renderBody();
};

export default Behaviour;
