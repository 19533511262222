import { CheckboxAnimation } from '@/animations';
import { Box, CircularProgress } from '@mui/material';
import React, { Component, RefObject, Suspense, createRef } from 'react';

const LazyLottie = React.lazy(() => import('lottie-react'));

interface LottieRefProps {
  setDirection(_direction: AnimationDirection): void;
  play(): void;
}

interface IProps {
  checked?: boolean;
  width?: string;
}

type AnimationDirection = 1 | -1;

export default class Checkbox extends Component<IProps> {
  private _checkboxAnimation!: RefObject<LottieRefProps>;
  private _direction: AnimationDirection = -1;
  private _isChecked!: boolean;

  constructor(props: any) {
    super(props);

    this._checkboxAnimation = createRef();
    this._isChecked = props.active;
  }

  private checkActive() {
    if (this._isChecked) {
      this.toggleAnimation();
    }
  }

  private toggleAnimation() {
    const anim = this._checkboxAnimation.current;
    if (!anim) {
      return;
    }

    this._direction *= -1;
    anim.setDirection(this._direction as AnimationDirection);
    anim.play();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (prevProps.checked !== this.props.checked) {
      this.toggleAnimation();
    }
  }

  render() {
    return (
      <Box
        sx={{
          '& .lottie': {
            display: 'inline-block',

            '& > svg > g': {
              transform: 'scale(2)',
              transformOrigin: 'center'
            }
          }
        }}
      >
        <Suspense fallback={<CircularProgress />}>
          <LazyLottie
            style={{
              maxWidth: this.props.width || '24px',
              width: this.props.width || '24px',
              transform: 'translateY(1px)',
              marginRight: '8px',
              flex: `${this.props.width || '24px'} 0 0`
            }}
            className='lottie'
            animationData={CheckboxAnimation}
            autoplay={false}
            loop={false}
            lottieRef={this._checkboxAnimation as any}
            initialSegment={[10, 60]}
            onDOMLoaded={this.checkActive}
          />
        </Suspense>
      </Box>
    );
  }
}
