import { useTranslation } from '@/components';
import { fontWeights } from '@/helpers';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';

type IProps = {
  closable?: boolean;
  title?: string;
  text: string;
  onClose?: Action;
};

const Alert = ({ closable, title, text, onClose }: IProps) => {
  const t = useTranslation();
  const theme = useTheme();
  const backdropClick = () => {
    if (closable !== true) {
      return;
    }

    if (!onClose) {
      return;
    }

    onClose();
  };

  const okClick = () => {
    if (!onClose) {
      return;
    }

    onClose();
  };

  return (
    <Box
      position='fixed'
      top={0}
      left={0}
      right={0}
      bottom={0}
      display='flex'
      zIndex={1000}
      bgcolor={theme.palette.grey[800]}
      onClick={backdropClick.bind(this)}
    >
      <Box
        width='400px'
        maxWidth='calc(100vw - 5rem)'
        bgcolor='white'
        m='auto'
        display='flex'
        flexDirection='column'
        p='12px'
        borderRadius='12px'
      >
        {title && (
          <Typography
            variant='h5'
            fontWeight={fontWeights.semiBold}
            fontSize='1.5rem'
            mb={0.5}
          >
            {title}
          </Typography>
        )}
        <Typography variant='body1' mb={1}>
          {text}
        </Typography>
        <Button type='button' variant='contained' onClick={okClick}>
          {t('Ok')}
        </Button>
      </Box>
    </Box>
  );
};

export default Alert;
