import { DataTableStyles, Loading, useTranslation } from '@/components';
import { Theme as AdminTheme, classNamer, webClient } from '@/helpers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  SxProps,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme
} from '@mui/material';
import { AxiosError } from 'axios';
import { ReactNode, useEffect, useState } from 'react';

interface IColumn<T> {
  title: string;
  data: keyof T;
  orderable?: boolean;
  width?: string;
  noWrap?: boolean;
  render?: (value: any, row: number, data: T) => ReactNode;
}

interface IOrder<T> {
  column: keyof T;
  desc?: boolean;
}

export interface DataTableRef {
  refreshData(): Promise<void>;
  showDialog(title: string, actions: ReactNode): void;
  hideDialog(): void;
}

interface IProps<T> {
  api: string;
  children: IColumn<T>[];
  order?: IOrder<T>[];
  noData?: string;
  rowSx?: (row: T, index: number) => SxProps<Theme>;
  stripped?: boolean;
  onError?: (code: number) => void;
  onReady?: (ref: DataTableRef) => void;
}

const DataTable = function <T = {}>({
  order,
  api,
  onError,
  rowSx,
  stripped,
  children,
  noData,
  onReady
}: IProps<T>) {
  const t = useTranslation();
  const [rows, setRows] = useState<T[]>([]);
  const [total, setTotals] = useState(0);
  const [orderS, setOrderS] = useState<IOrder<T>[] | undefined>(order);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [error, setError] = useState<FormError>(false);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState<
    { title: string; actions: ReactNode } | undefined
  >();

  const getUrl = () => {
    const haveQuestionMark = api.indexOf('?') > -1;
    let query: string[] = [];
    if (order) {
      for (let i = 0; i < order.length; i++) {
        const o = order[i];
        query.push(
          `sort=${o.column as string}:${o.desc === true ? '-1' : '1'}`
        );
      }
    }

    query.push(`skip=${(page - 1) * pageSize}`);
    query.push(`limit=${pageSize}`);

    return api + (haveQuestionMark ? '&' : '?') + query.join('&');
  };

  const loader = async () => {
    setLoading(true);
    try {
      const res = await webClient.get<ArrayResponse<T>>(getUrl(), {
        loadingHandled: true
      });
      setTotals(res.data.totalCount);
      setRows(
        res.data.data.map((x, i) => ({
          ...x,
          index: (page - 1) * pageSize + i + 1
        }))
      );
      setLoading(false);
    } catch (exp) {
      if (onError) {
        onError((exp as AxiosError).response?.status || 500);
      }

      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getOrder = (data: keyof T) => {
    if (!orderS || orderS.length === 0) {
      return false;
    }

    const order = orderS.find((x) => x.column === data);
    if (!order) {
      return false;
    }

    return order.desc ? 'desc' : 'asc';
  };

  const handleColumnClick = async (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    column: IColumn<T>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!column.orderable) {
      return;
    }

    const replace = !e.ctrlKey;
    const data = column.data;

    let orders = orderS;
    if (!orders || orders.length === 0) {
      orders = [
        {
          column: data
        }
      ];
    } else {
      let order = orders.findIndex((x) => x.column === data);
      if (order < 0) {
        if (replace) {
          orders = [
            {
              column: data
            }
          ];
        } else {
          orders.push({
            column: data
          });
        }
      } else {
        if (orders[order].desc === true) {
          orders = orders.filter((x) => x.column !== data);
        } else {
          orders[order].desc = !(orders[order].desc || false);
        }
      }
    }

    setOrderS(orders);
    await loader();
  };

  const getValue = (data: T, key: keyof T) => {
    return (data as any)[key];
  };

  const renderRow = (data: T, index: number) => {
    let sx: SxProps<Theme> = {};

    if (stripped) {
      sx = {
        ...sx,
        ...{
          '&:nth-of-type(odd)': {
            backgroundColor: AdminTheme.palette.grey[50]
          },
          '&:last-child td, &:last-child th': {
            border: 0
          }
        }
      };
    }
    if (rowSx) {
      sx = {
        ...sx,
        ...rowSx(data, index)
      };
    }
    return (
      <TableRow sx={sx} key={`row${index}`}>
        {children.map((x, i) => {
          const value = getValue(data, x.data);
          const renderer = x.render || ((x, j, d) => x);
          return (
            <TableCell
              key={`row${index}cell${i}`}
              sx={{
                whiteSpace: x.noWrap ? 'nowrap' : 'initial'
              }}
            >
              {renderer(value, index, data)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={children.length}>
            <Loading />
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={children.length}>
            {error}
            <Button onClick={refreshData}>{t('Reload')}</Button>
          </TableCell>
        </TableRow>
      );
    }

    if (!rows || rows.length < 1) {
      return (
        <TableRow>
          <TableCell colSpan={children.length}>
            {noData || t('NoItemsFound')}
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((x, i) => renderRow(x, i));
  };

  const showDialog = (title: string, actions: ReactNode) => {
    setDialog({
      title,
      actions
    });
  };

  const hideDialog = () => {
    setDialog(undefined);
  };

  const refreshData = async () => {
    await loader();
  };

  const onPageChange = (_: unknown, page: number) => {
    setPage(page + 1);
  };

  const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    loader();
    if (onReady) {
      onReady({
        refreshData,
        showDialog,
        hideDialog
      });
    }
  }, [pageSize, page]);

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {children.map((x, i) => (
              <TableCell
                sx={{
                  [`&.${tableCellClasses.head}`]: {
                    backgroundColor: AdminTheme.palette.primary.dark,
                    color: AdminTheme.palette.common.white
                  },
                  [`&.${tableCellClasses.body}`]: {
                    fontSize: 14
                  }
                }}
                key={i}
                className={classNamer(DataTableStyles['column'], {
                  [DataTableStyles.orderable]: x.orderable === true,
                  [DataTableStyles.asc]: getOrder(x.data) === 'asc',
                  [DataTableStyles.desc]: getOrder(x.data) === 'desc'
                })}
                onClick={(ev) => handleColumnClick(ev, x)}
                style={{
                  width: x.width || 'auto',
                  whiteSpace: 'nowrap'
                }}
              >
                {x.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderBody()}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={pageSize}
              count={total}
              page={(page || 1) - 1}
              onPageChange={onPageChange}
              onRowsPerPageChange={onPageSizeChange}
              labelDisplayedRows={({ from, to, count }) =>
                t('FromToOf', {
                  from,
                  to,
                  count
                })
              }
              labelRowsPerPage={t('RowsPerPage')}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Dialog open={!!dialog} onClose={async () => setDialog(undefined)}>
        <DialogTitle>{dialog?.title}</DialogTitle>
        <DialogActions>{dialog?.actions}</DialogActions>
      </Dialog>
    </>
  );
};

export default DataTable;
