import IdleAnimation from '@/animations/idle.json';
import { Theme } from '@/helpers';
import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';

const LazyLottie = React.lazy(() => import('lottie-react'));

const CharacterIdle = () => (
  <Suspense fallback={<CircularProgress />}>
    <LazyLottie
      animationData={IdleAnimation}
      style={{
        position: 'absolute',
        bottom: '100%',
        right: 0,
        width: '94px',
        marginBottom: Theme.spacing(-1)
      }}
    />
  </Suspense>
);

export default CharacterIdle;
