import { Page, PageProps } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

const A4Page = (props: PropsWithChildren<PageProps>) => {
  const defaultProps: PageProps = {
    size: 'A4',
    orientation: 'portrait'
  };

  const { style, ...otherProps } = props;

  return (
    <Page
      {...defaultProps}
      style={{
        padding: 24,
        ...style
      }}
      {...otherProps}
    />
  );
};

export default A4Page;
