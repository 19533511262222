import { Theme } from '@/helpers';
import { Box } from '@mui/material';

export default function Redirect({
  to,
  beforeRedirect
}: {
  to: string;
  beforeRedirect?: Function;
}) {
  if (beforeRedirect) {
    beforeRedirect();
  }
  document.location.href = to;
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      top={0}
      left={0}
      textAlign="center"
      bgcolor="transparent"
      zIndex="auto"
      sx={{
        animationFillMode: 'both',
        perspective: 1000
      }}
    >
      <Box
        width="16px"
        height="16px"
        m={0}
        bgcolor={Theme.palette.primary.main}
        borderRadius={0}
        display="inline-block"
        sx={{
          animation: 'cubemove 1.8s infinite ease-in-out'
        }}
      />
      <Box
        width="16px"
        height="16px"
        m={0}
        bgcolor={Theme.palette.primary.main}
        borderRadius={0}
        display="inline-block"
        sx={{
          animation: 'cubemove 1.8s infinite ease-in-out',
          animationDelay: '-0.9s'
        }}
      />
    </Box>
  );
}
