function classNamer(classes: Record<string, boolean>): string;
function classNamer(base: string, classes: Record<string, boolean>): string;
function classNamer(
  base: string | Record<string, boolean>,
  classes?: Record<string, boolean>
) {
  let res: string[] = [];
  if (typeof base !== 'string') {
    classes = base;
  } else {
    res = [base];
  }

  for (const className in classes) {
    if (classes[className]) {
      res.push(className);
    }
  }

  return res.join(' ').trim();
}

export function delayClass(
  className: string,
  delay: number,
  event: React.SyntheticEvent<HTMLElement>
) {
  setTimeout(() => event.currentTarget.classList.add(className), delay);
}

export default classNamer;
