import { Box } from '@mui/material';
import { Component } from 'react';

type IProps = {
  radius: number;
  progress?: number;
  innerRadius: number;
  stroke?: string;
  strokeOpacity?: number;
  filter?: string;
};

export default class HollowCircle extends Component<IProps> {
  private get strokeWidth() {
    return this.props.radius - this.props.innerRadius;
  }

  private get extraRadius() {
    return this.strokeWidth / 2;
  }

  private get innerRadius() {
    return this.props.innerRadius + this.extraRadius;
  }

  private get circleTotalLength() {
    return 2 * Math.PI * this.innerRadius;
  }

  render() {
    if (this.props.progress === 0) {
      return <></>;
    }

    return (
      <Box
        component='circle'
        stroke={this.props.stroke}
        strokeOpacity={this.props.strokeOpacity}
        filter={this.props.filter}
        fill='none'
        r={this.innerRadius}
        strokeWidth={this.strokeWidth}
        strokeDasharray={
          this.props.progress &&
          `${this.props.progress * this.circleTotalLength} ${
            this.circleTotalLength
          }`
        }
        sx={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
          transition: 'all 0.1s'
        }}
      />
    );
  }
}
