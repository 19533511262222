import { FormHelperText } from '@mui/material';
import { ErrorMessage } from 'formik';

interface IProps {
  name: string;
}

const AppErrorMessage = ({ name }: IProps) => (
  <ErrorMessage name={name}>
    {(message) => {
      if (typeof message === 'string') {
        return <FormHelperText error>{message}</FormHelperText>;
      }

      return <FormHelperText error>Fix above errors</FormHelperText>;
    }}
  </ErrorMessage>
);

export default AppErrorMessage;

