import {
  addAlphaToHex,
  fontWeights,
  getTextColor,
  lightenHexColor
} from '@/helpers';
import { Table, TableContainer, TableProps, styled } from '@mui/material';

const StyledTable = styled(Table)(({ theme }) => ({
  '--border-radius': '24px',
  breakInside: 'avoid',
  backgroundColor: 'white',
  padding: '0',
  border: `solid 1px ${addAlphaToHex(theme.palette.secondary.main, 50)}`,
  borderRadius: 'var(--border-radius)',
  borderCollapse: 'initial',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderSpacing: '8px',
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    '--border-radius': '10px',
    '& th, & td': {
      fontSize: '10px',
      lineHeight: '1.5',
      padding: '7px 4px !important'
    }
  },
  '& th, & td': {
    border: 'none',
    padding: '14px 8px'
  },
  '& > thead > tr > th:not(:first-of-type)': {
    backgroundColor: lightenHexColor(theme.palette.secondary.main, 80),
    color: getTextColor(lightenHexColor(theme.palette.secondary.main, 80))
  },
  '& > thead > tr > th:last-of-type': {
    borderRadius: '0 var(--border-radius) 0 0'
  },
  '& > tbody > tr:nth-of-type(2n - 1) > th': {
    backgroundColor: lightenHexColor(theme.palette.secondary.main, 85),
    color: getTextColor(lightenHexColor(theme.palette.secondary.main, 85))
  },
  '& > tbody > tr:nth-of-type(2n) > th': {
    backgroundColor: lightenHexColor(theme.palette.secondary.main, 95),
    color: getTextColor(lightenHexColor(theme.palette.secondary.main, 95))
  },
  '& > tbody > tr:last-of-type > th:first-of-type': {
    borderRadius: '0 0 0 var(--border-radius)'
  },
  '& > tbody > tr:last-of-type > td:last-of-type': {
    borderRadius: '0 0 var(--border-radius) 0'
  },
  '& > tbody td': {
    boxShadow: '0 0 4px #D9D9D9'
  },
  '& > thead > th': {
    fontSize: '16px',
    fontWeight: fontWeights.semiBold
  },
  '& > tbody > th': {
    fontWeight: fontWeights.medium,
    fontSize: '16px'
  },
  '& > tfoot td:not(:empty)': {
    boxShadow: '0 0 4px #D9D9D9'
  },
  '@media print': {
    padding: '8px'
  }
}));

const StyledTableContainer = (props: TableProps) => (
  <TableContainer
    sx={{
      mt: {
        xs: 4 / 12,
        sm: 8 / 12,
        md: 16 / 12
      },
      mb: {
        xs: 1,
        sm: 2,
        md: 3
      },
      pb: 1
    }}
  >
    <StyledTable {...props}>{props.children}</StyledTable>
  </TableContainer>
);

export default StyledTableContainer;
