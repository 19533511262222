import { Markdown, useTranslation } from '@/components';
import {
  addAlphaToHex,
  appendVersion,
  fontWeights,
  getCdnUrl
} from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Privacy = () => {
  const { setMainClasses } = useAppContext();
  const t = useTranslation();

  const [content, setContent] = useState('');

  useEffect(() => {
    setMainClasses('main-flex');

    axios.get<string>(appendVersion('/privacy.md')).then((x) => {
      setContent(x.data);
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bgcolor={{
        sm: 'white'
      }}
      borderRadius='24px'
      p={{
        xs: 1,
        sm: 4,
        md: 4
      }}
      textAlign='center'
      my='auto'
      mx={{
        sm: 'calc((100% - 700px) / 2)',
        md: 'calc((100% - 900px) / 2)'
      }}
      boxShadow={{
        sm: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`
      }}
    >
      <Box component='img' src={getCdnUrl('/images/terms.svg')} alt='Privacy' />
      <Typography
        variant='body1'
        mt={32 / 12}
        textAlign='start'
        fontSize={12}
        fontWeight={fontWeights.light}
      >
        <Markdown options={{}}>{content}</Markdown>
      </Typography>
    </Box>
  );
};

export default Privacy;
