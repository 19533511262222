import { Alert, Loading, useTranslation } from '@/components';
import { useAppContext } from '@/contexts/app-context';
import { webClient } from '@/helpers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const SurveyReportGrantAccess = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { setError } = useAppContext();
  const { id } = useParams<'id'>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    webClient.api.surveys
      .grantAccess(id!, {
        loadingHandled: true
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError('Survey not found!');
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Alert
      text={t('OrganizerGainAccess')}
      closable
      onClose={() => navigate('/')}
    />
  );
};

export default SurveyReportGrantAccess;
