import { makeArray } from '@/helpers';
import { ErrorOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';

type IProps = HasTranslationAccess & {
  id: string;
  title: string;
  color: string;
  min?: number;
  icon?: React.ReactNode;
  items: TopicItemResponse[];
};

const TopicZone = ({ id, title, color, min, icon, items, t }: IProps) => {
  const minimum = min || 0;

  return (
    <Box
      borderRadius='24px'
      boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
      display='flex'
      flexDirection='column'
      overflow='hidden'
    >
      <Box
        bgcolor={color}
        px={16 / 12}
        py={{
          xs: 4 / 12,
          md: 6 / 12
        }}
        textAlign='center'
        fontSize='16px'
      >
        {icon}
        {title}
      </Box>
      <Droppable droppableId={id}>
        {(provided) => (
          <Box
            p={16 / 12}
            bgcolor='rgba(255,255,255,0.5)'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((x, i) => (
              <Draggable draggableId={x.id.toString()} index={i} key={x.id}>
                {(dragProvided) => (
                  <Box
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    ref={dragProvided.innerRef}
                    textAlign='center'
                    borderRadius='24px'
                    bgcolor='white'
                    p={16 / 12}
                    minHeight='53px'
                    sx={{
                      cursor: 'grab',
                      '&:not(:last-of-type)': {
                        mb: 16 / 12
                      },
                      '&:hover': {
                        animation: 'shake 0.25s infinite'
                      },
                      '&:active': {
                        cursor: 'grabbing'
                      }
                    }}
                    boxShadow='0 4px 4px rgba(0, 0, 0, 0.25)'
                    zIndex={1}
                    key={x.id}
                  >
                    {x.name}
                  </Box>
                )}
              </Draggable>
            ))}
            {makeArray(Math.max(minimum - items.length, 1), 0).map(() => (
              <Box
                borderRadius='24px'
                sx={{
                  userSelect: 'none',
                  '&:not(:last-of-type)': {
                    mb: 16 / 12
                  }
                }}
                textAlign='center'
                zIndex={0}
                bgcolor='#FFF'
                p={16 / 12}
                color='#EC4849'
                minHeight='53px'
                boxShadow='inset 1px 3px 2px rgba(0, 0, 0, 0.1)'
              >
                {items.length < minimum && (
                  <ErrorOutline sx={{ mr: 4 / 12 }} fontSize='small' />
                )}
                {items.length < minimum &&
                  t('YouNeedToHaveAtleast', {
                    min: minimum,
                    name: title
                  })}
              </Box>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default TopicZone;
