import { useTranslation } from '@/components';
import { VerificationTimeoutCodes } from '@/enums';
import {
  fontWeights,
  getQueryString,
  getVerificationTimeout,
  redirectToUserspace,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import { AuthButtonBack, AuthPanel, AuthPanelHeader } from '@auth/styleds';
import { useAppContext } from '@contexts/app-context';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const Verify = () => {
  const { setMainClasses } = useAppContext();
  const navigate = useNavigate();
  const t = useTranslation('Authentication');

  const [step, setStep] = useState(1);
  const [remainingTime, setRemainingTime] = useState(60);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);

  let timeoutId: any;

  const getExpireTime = () => {
    return getVerificationTimeout(VerificationTimeoutCodes.AuthVerify) || 0;
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  const submit = async () => {
    if (code.length !== 5) {
      setCodeError('VerificationCodeIsWrong');
      return;
    }

    setLoading(true);

    try {
      await webClient.api.auth.basic.verify({
        email: getQueryString('email')!,
        code
      });

      setVerificationTimeout(VerificationTimeoutCodes.AuthVerify, 5);
      setLoading(false);
      setStep(2);
      setTimeout(() => {
        redirectToUserspace(navigate);
      }, 5e3);
    } catch (exp) {
      setLoading(false);
      setCodeError('VerificationCodeIsWrong');
    }
  };

  const resend = async () => {
    setLoading(true);
    try {
      await webClient.api.auth.basic.resend({
        email: getQueryString('email')!
      });

      setVerificationTimeout(VerificationTimeoutCodes.AuthVerify, 60);
      setLoading(false);
      startTimer();
    } catch (exp) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMainClasses();

    const email = getQueryString('email');
    if (email && email.trim().length > 0) {
      startTimer();
    }

    return () => {
      clearTimer();
    };
    // eslint-disable-next-line
  }, []);

  if (step === 2) {
    return (
      <AuthPanel textAlign='center'>
        <Typography
          variant='h3'
          sx={{
            mt: 2,
            fontWeight: fontWeights.bold
          }}
        >
          {t('VerificationCompleted')}
        </Typography>
        <Typography variant='h4' sx={{ mt: 1, mb: 'auto' }}>
          {t('WeWillRedirectToYourPersonalSpace', {
            time:
              remainingTime > 1
                ? t('$XSeconds', { x: remainingTime })
                : t('$1Second')
          })}
        </Typography>
      </AuthPanel>
    );
  }

  return (
    <AuthPanel>
      <AuthButtonBack
        to={
          window.hasReturnUrl
            ? `/login/?returnUrl=${window.returnUrlEncoded}`
            : '/login/'
        }
      />
      <AuthPanelHeader>{t('MakingSureItIsYou')}</AuthPanelHeader>
      <Typography>{t('PleaseEnterYourVerificationCode')}</Typography>
      <InputLabel sx={{ mt: 3 }} htmlFor='recover_code'>
        {t('VerificationCode')}
      </InputLabel>
      <OutlinedInput
        error={codeError !== false}
        type='text'
        fullWidth
        defaultValue={code}
        placeholder={t('VerificationCodeHint')}
        name='code'
        id='recover_code'
        onChange={(e) => {
          setCode(e.target.value);
          setCodeError(false);
        }}
      />
      <FormHelperText error={codeError !== false}>
        {codeError !== false ? t(codeError) : ''}
      </FormHelperText>
      <Button
        fullWidth
        sx={{ mt: 2, fontSize: 18 }}
        variant='contained'
        disabled={loading || code.trim().length < 5}
        onClick={submit}
      >
        {loading ? <CircularProgress color='inherit' /> : t('$Submit')}
      </Button>
      <Box fontSize={14} mt={1}>
        {t('DidntGetTheCode')}
        <Button
          variant='text'
          onClick={resend.bind(this)}
          disabled={remainingTime > 0}
          sx={{ p: 0, fontSize: 'inherit' }}
        >
          {t('Resend')}
        </Button>{' '}
        {remainingTime > 0 &&
          t('ResendItIn', {
            time:
              remainingTime > 1
                ? t('$XSeconds', { x: remainingTime })
                : t('$1Second')
          })}
      </Box>
    </AuthPanel>
  );
};

export default Verify;
