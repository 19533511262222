import { LazyPlotly, Loading } from '@/components';
import { webClient } from '@/helpers';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const SurveyChart = () => {
  const { id } = useParams<'id'>();

  const [chartData, setChartData] = useState<ChartResponse[] | null>();

  useEffect(() => {
    webClient.api.surveys
      .charts(id!)
      .then((x) => setChartData(x.data.data))
      .catch(() => setChartData(null));
  }, [id]);

  if (!chartData && chartData !== null) {
    return <Loading />;
  }

  if (!chartData) {
    return <Box>Error</Box>;
  }

  return (
    <>
      {chartData.map((x, i) => (
        <LazyPlotly key={`chart${i}`} layout={x.layout} data={x.data} />
      ))}
    </>
  );
};

export default SurveyChart;
