import { useTranslation } from '@/components';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NotAvailable = () => {
  const t = useTranslation();

  return (
    <>
      <Typography>
        This assessment is already closed and new feedback cannot be submitted.
      </Typography>
      <Typography>
        Please contact the person who shared the link with you.
      </Typography>
      <Button type='button' component={Link} to='/'>
        Home
      </Button>
    </>
  );
};

export default NotAvailable;
