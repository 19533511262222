import { useTranslation } from '@/components';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import { usePdfContext } from '@/contexts/pdf-context';
import {
  ChartDataType,
  TableDataType,
  TopicItemTableType,
  TopicTableType
} from '@/enums';
import { addAlphaToHex, fontWeights, getCdnUrl, randPerm } from '@/helpers';
import { useTheme } from '@mui/material';
import {
  Document,
  Font,
  Image,
  Rect,
  Svg,
  Text,
  View
} from '@react-pdf/renderer';
import {
  A4Page,
  ChapterCover,
  Contents,
  createStyleSheet,
  ReportItem
} from '@survey/report/pdf';
import { useEffect } from 'react';

type IProps = SimpleReportResponse;

const SurveyReportPdf = ({
  tables,
  aiReports,
  sectionReports,
  sections,
  items,
  relations,
  familiarity,
  options,
  commentSections,
  responds,
  comments,
  nickname,
  charts,
  summary,
  date,
  title,
  customizations
}: IProps) => {
  const {} = usePdfContext();
  const theme = useTheme();

  const styleSheet = createStyleSheet({
    hero: {
      position: 'relative',
      height: '100%'
    },
    hero_background: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    hero_titebar: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 24,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: '6px 24px'
    },
    hero_nickname: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.semiBold,
      fontSize: 16
    },
    hero_nickname_separator: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.black,
      margin: '0 20px'
    },
    hero_nickname_date: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.light,
      fontSize: 12
    },
    chapter_cover: {
      display: 'flex',
      flexDirection: 'row'
    },
    chapter_cover_image: {}
  });

  Font.register({
    family: 'Inter',
    fonts: [
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
        fontWeight: 100
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
        fontWeight: 200
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
        fontWeight: 300
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
        fontWeight: 400
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
        fontWeight: 500
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
        fontWeight: 600
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
        fontWeight: 700
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
        fontWeight: 800
      },
      {
        src: 'http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
        fontWeight: 900
      }
    ]
  });

  const summaryTables = tables
    .where((x) => x.dataType === TableDataType.SUMMARY)
    .flatMap((x) => x.tables);

  const sectionsTables = tables
    .where((x) => x.dataType === TableDataType.SECTION)
    .map((x) => ({
      id: x.dataId!,
      tables: tables
        .where(
          (y) => y.dataId === x.dataId && y.dataType === TableDataType.SECTION
        )
        .flatMap((y) => y.tables)
    }));

  const fixedComments = comments
    .flatMap((x) =>
      x.comments.map((y) => ({
        ...y,
        relationId: x.relationId
      }))
    )
    .groupBy((x) => x.commentId);

  tables = tables.map((a) => ({
    ...a,
    tables: a.tables.map((x) => {
      switch (x.type) {
        case TopicItemTableType.DETAILED:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            relation: relations.find((a) => a.id === z.relationId)
          }));
          break;

        case TopicItemTableType.AVERAGE:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            relation:
              (z.relationId &&
                relations &&
                relations.find((a) => a.id === z.relationId)) ||
              undefined
          }));
          break;

        case TopicItemTableType.ANONYMOUS:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!
          }));
          break;

        case TopicItemTableType.FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            familiarity:
              (z.familiarityId &&
                familiarity &&
                familiarity.options.find((a) => a.id === z.familiarityId)) ||
              undefined
          }));
          break;

        case TopicTableType.OVERALL:
        case TopicTableType.OVERALL_FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          break;

        default:
          throw new Error('Type is not supported');
      }

      return x;
    })
  }));

  sectionReports = sectionReports?.map((x) => ({
    ...x,
    section: sections?.find((y) => y.id === x.sectionId)!
  }));

  comments = comments.map((x) => ({
    ...x,
    relation: relations.find((y) => y.id === x.relationId)
  }));

  const t = useTranslation('Reports.Table');

  const coverImage = (name: string) =>
    getCdnUrl(`/images/reports/chapters/${name}.jpg`);
  const commentImage = (index: number) =>
    getCdnUrl(`/images/reports/comments/${index % 4 || 4}.jpg`);

  const coverImages = randPerm(
    1,
    61,
    (sections?.length || 0) + items.length
  ).map((x) => coverImage(x.toString()));

  useEffect(() => {
    const languageSubscriptionId = languageChangeSubscribe((_, newLanguage) => {
      if (newLanguage === 'en') {
        document.location.reload();
      } else {
        document.location.href = `/${newLanguage}${document.location.pathAndSearch}`;
      }
    });

    return () => languageChangeUnsubscribe(languageSubscriptionId);
  }, []);

  return (
    <Document title={title} creator='GPT' producer='GPT'>
      <A4Page>
        <View style={styleSheet.hero}>
          <Svg style={styleSheet.hero_background} viewBox='0 0 210 297'>
            <Rect width={210} height={297} fill='#000' fillOpacity={0.02} />
            <Rect
              width={129}
              height={297}
              x={210 - 129}
              fill='#EEFF0000'
              opacity={1}
              fillOpacity={0.6}
            />
            <Rect
              width={128}
              height={48}
              y={182}
              opacity={1}
              fillOpacity={0.5}
              style={{
                color: addAlphaToHex(theme.palette.secondary.main, 50)
              }}
            />
          </Svg>
          <View style={styleSheet.hero_titebar}>
            <Text style={styleSheet.hero_nickname}>
              {nickname}
              <Text style={styleSheet.hero_nickname_separator}>|</Text>
              {date && (
                <Text style={styleSheet.hero_nickname_date}>
                  {date.asDateTime('MMMM yyyy')}
                </Text>
              )}
            </Text>
          </View>
          <Text>{t('360FeedbackReport')}</Text>
          <Text>{title}</Text>
          <Image src={getCdnUrl('/images/reports/hero.png')} />
        </View>
      </A4Page>
      {sectionReports ? (
        <Contents
          hasSummary={!!summary}
          items={sectionReports.map((x) => ({
            id: x.sectionId,
            name: x.section.title
          }))}
          isSections
        />
      ) : items.length > 1 ? (
        <Contents
          hasSummary={!!summary}
          hasComments={comments.length > 0}
          items={items}
        />
      ) : (
        <></>
      )}
      {sectionReports?.any()
        ? sectionReports?.map((x, i) => {
            const tables =
              sectionsTables &&
              sectionsTables.find((y) => y.id === x.sectionId)?.tables;
            return (
              <>
                <ChapterCover
                  name={x.section.title}
                  image={coverImages[i]}
                  index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
                />
                <A4Page
                  key={`section_${x.sectionId}`}
                  id={x.sectionId.replace(/[-_]/g, '')}
                >
                  {/*{tables &&
                    tables.map((y) => (
                      <TableRenderer
                        key={`section_${x.sectionId}`}
                        id={`section_${x.sectionId}`}
                        table={y}
                        familiarity={familiarity}
                        items={items.where((z) =>
                          x.section.itemIds.contains(z.id)
                        )}
                      />
                    ))}
                  <AIReport
                    content={x.content}
                    title={x.section.title}
                    charts={charts
                      ?.where(
                        (y) =>
                          y.dataType === ChartDataType.SECTION &&
                          y.dataId === x.sectionId
                      )
                      .map((y) => y.chart)}
                  />*/}
                </A4Page>
              </>
            );
          })
        : items.map((x, i) => (
            <ReportItem
              key={i}
              {...x}
              index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
              options={options}
              relations={relations}
              tables={tables
                .filter(
                  (y) => y.dataType === TableDataType.ITEM && y.dataId === x.id
                )
                .flatMap((y) => y.tables)}
              aiReports={aiReports}
              cover={coverImages[i]}
              familiarity={familiarity}
              charts={charts
                ?.where(
                  (y) => y.dataType === ChartDataType.ITEM && y.dataId === x.id
                )
                .map((y) => y.chart)}
              single={items.length === 1}
            />
          ))}
    </Document>
  );
};

export default SurveyReportPdf;
