/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
Number.prototype.limit = function (
  this: number,
  min: number,
  max?: number
): number {
  if (typeof max !== 'number') {
    max = min;
    min = 0;
  }

  let diff = max - min + 1;

  max -= min;
  let n = this - min;

  n %= diff;
  n += min;

  return n + (n < min ? diff : 0);
};

Number.prototype.toFixedLength = function (this: Number, length: number) {
  let res = this.toString();

  while (res.length < length) {
    res = '0' + res;
  }

  return res;
};

Number.prototype.toWeekDayName = function (this: Number) {
  return this === 0 ? 'Satarday' : 'Other days';
};

Number.prototype.toFixedDecimal = function (this: Number, length: number) {
  return Number(this.toFixed(length));
};

Number.prototype.isNaN = function (this: Number) {
  return isNaN(Number(this));
};

Number.prototype.toFixedString = function (
  this: Number,
  length: number,
  def?: string
) {
  if (this.isNaN()) {
    return def;
  }

  return this.toFixedDecimal(length).toString();
};

export { };

