import { useTranslation } from '@/components';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import { LocalStorageKeys } from '@/enums';
import { fontWeights } from '@/helpers';
import ThanksSvg from '@/images/thanks';
import { useAppContext } from '@contexts/app-context';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const SurveyThanks = () => {
  const { setMainClasses } = useAppContext();
  const t = useTranslation('SurveyThanks');
  const thanksData = localStorage.getItem(LocalStorageKeys.SURVEY_THANKS_DATA);
  const surveyThanksData =
    (thanksData &&
      (JSON.parse(thanksData) as {
        topic: string;
        customizations?: CompanyCustomizations;
      })) ||
    undefined;

  useEffect(() => {
    setMainClasses('main-flex');
    // eslint-disable-next-line
  }, []);

  const startUrl = () => {
    const currentTopic = localStorage.getItem(
      LocalStorageKeys.SURVEY_THANKS_DATA
    );
    const defaultUrl = process.env.REACT_APP_FRIEND_START;
    if (!defaultUrl || defaultUrl.trim() === '') {
      if (!currentTopic) {
        return process.env.REACT_APP_LANDING;
      }

      return `${process.env.REACT_APP_LANDING}/topics/${currentTopic}/`;
    }

    return defaultUrl;
  };

  const render = () => (
    <Box
      bgcolor={{
        xs: '#f5f5f5',
        sm: 'white'
      }}
      borderRadius='24px'
      boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
      p={1}
      pt={3}
      textAlign='center'
      my='auto'
      mx={{
        xs: 1,
        sm: 'calc((100vw - 370px) / 2)'
      }}
    >
      <ThanksSvg />
      <Box mt={1} textAlign='center' pb={3} px={4}>
        <Typography
          variant='h3'
          fontSize='20px'
          fontWeight={fontWeights.extraBold}
          color='#414042'
        >
          {t('ThankYou')}
        </Typography>
        <Typography
          variant='body1'
          mt='12px'
          fontSize='16px'
          textAlign='center'
          color='#6D6E71'
        >
          {surveyThanksData &&
          surveyThanksData.customizations &&
          surveyThanksData.customizations.feedbackFinishedReceived
            ? surveyThanksData.customizations.feedbackFinishedReceived
            : t('ReceivedInputs')}
        </Typography>
        <Typography
          variant='body1'
          mt={4}
          fontWeight={fontWeights.semiBold}
          textAlign='center'
          fontSize='20px'
          color='#414042'
        >
          {surveyThanksData &&
          surveyThanksData.customizations &&
          surveyThanksData.customizations.feedbackFinishedInvite
            ? surveyThanksData.customizations.feedbackFinishedInvite
            : t('GetForYourself')}
        </Typography>
        <Button
          sx={{
            mt: 2.6
          }}
          fullWidth
          component={Link}
          to={startUrl()}
          variant='contained'
          color='primary'
        >
          {t('$Start')}
        </Button>
        <Box
          component='small'
          fontStyle='italic'
          textAlign='center'
          mt={1.4}
          display='block'
          color='#6D6E71'
        >
          {t('ClickToStart')}
        </Box>
      </Box>
    </Box>
  );

  if (
    surveyThanksData &&
    surveyThanksData.customizations &&
    (surveyThanksData.customizations.primaryColor ||
      surveyThanksData.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={surveyThanksData.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default SurveyThanks;
