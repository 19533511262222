import { useTranslation } from '@/components';
import { fontWeights } from '@/helpers';
import { Link, Text, View } from '@react-pdf/renderer';
import { A4Page, ChapterIcon, createStyleSheet } from '@survey/report/pdf';

interface IItem {
  id: string;
  name: string;
}

interface IItemProps extends IItem {
  index: number;
}

interface IProps {
  items: IItem[];
  hasSummary: boolean;
  hasComments?: boolean;
  isSections?: boolean;
}

const ContentsItem = ({ id, name, index }: IItemProps) => {
  const styleSheet = createStyleSheet({
    link: {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none',
      marginBottom: 4
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row'
    },
    number: {
      color: '#6D6E71',
      fontSize: 32,
      fontWeight: fontWeights.black,
      textDecoration: 'none',
      marginLeft: 8
    },
    text: {
      fontSize: 14,
      fontWeight: fontWeights.light,
      color: '#6D6E71'
    }
  });

  return (
    <Link href={`#${id}`} style={styleSheet.link} wrap={false}>
      <View style={styleSheet.wrapper}>
        <ChapterIcon />
        <Text style={styleSheet.number}>{index.toFixedLength(2)}</Text>
      </View>
      <Text style={styleSheet.text}>{name}</Text>
    </Link>
  );
};

const Contents = ({ items, hasSummary, hasComments, isSections }: IProps) => {
  const t = useTranslation('');

  const startIndex = hasSummary ? 2 : 1;

  return (
    <A4Page>
      {hasSummary && (
        <ContentsItem
          key='executiveSummary'
          id='executiveSummary'
          name={t('ExecutiveSummary')}
          index={1}
        />
      )}
      {items.map((x, i) => (
        <ContentsItem
          key={`link_${x.id}`}
          id={x.id}
          name={x.name}
          index={startIndex + i}
        />
      ))}
      {hasComments && (
        <ContentsItem
          key='comments'
          id='comments'
          name={t('Comments')}
          index={startIndex + items.length}
        />
      )}
      {isSections && (
        <ContentsItem
          key='detailedData'
          id='detailedData'
          name={t('DetailedData')}
          index={startIndex + items.length + (hasComments ? 1 : 0)}
        />
      )}
    </A4Page>
  );
};

export default Contents;
