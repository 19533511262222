export enum CodeTypes {
  Invalid = 'INVALID',
  Project = 'PROJECT',
  Feedback = 'FEEDBACK',
  Report = 'REPORT',
  ResetPassword = 'RESET_PASSWORD',
  VerifyEmail = 'VERIFY_EMAIL',
  GainAccess = 'GAIN_ACCESS',
  CancelAccess = 'CANCEL_ACCESS'
}

export enum UserRoles {
  Guest = 'GUEST',
  User = 'BASIC',
  LocalAdmin = 'LOCAL_ADMIN',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN'
}

export enum ReportTypes {
  AllKnown = 1,
  Unknown = 2,
  FriendsKnown = 3,
  PersonKnown = 4,
  Individual = 5
}

export enum ProjectStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ENDED = 'ENDED',
  CANCELED = 'CANCELED'
}

export enum TopicTypes {
  Checkbox = 4,
  MultiOption = 8,
  Range = 16
}

export enum SettingKey {
  APP_NAME = 'APP_NAME',
  EMAIL_URL = 'EMAIL_URL',
  EMAIL_FROM = 'EMAIL_FROM',
  EMAIL_API_KEY = 'EMAIL_API_KEY',
  OPENAI_API_KEY = 'OPENAI_API_KEY',
  OPENAI_REPORT_GENERATOR = 'OPENAI_REPORT_GENERATOR',
  OPENAI_CONTENT_TRANSLATOR = 'OPENAI_CONTENT_TRANSLATOR',
  OPENAI_VALIDATOR = 'OPENAI_VALIDATOR',
  DAILY_REPORT_CHECK_CRON = 'DAILY_REPORT_CHECK_CRON',
  INDIVIDUAL_REPORT_DELAY = 'INDIVIDUAL_REPORT_DELAY',
  CONTACTS = 'CONTACTS',
  DIAGNOSE_INVITE_DELAY = 'DIAGNOSE_INVITE_DELAY',
  SURVEY_DEFAULT_LIFESPAN = 'SURVEY_DEFAULT_LIFESPAN'
}

export enum ContactKey {
  TEL = 'Tel',
  EMAIL = 'Email',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  GOOGLE = 'Google',
  LINKEDIN = 'LinkedIn'
}

export enum QualitativeTag {
  Weakness = -1,
  Unknown = 0,
  Strength = 1
}

export enum TopicPageType {
  SINGLE = 'SINGLE',
  WIZARD = 'WIZARD',
  WIZARD_SIMPLE = 'WIZARD_SIMPLE',
  OPEN_ENDED = 'OPEN_ENDED'
}

export enum TopicReportType {
  SIMPLE_INDIVIDUAL = 'SIMPLE_INDIVIDUAL',
  SIMPLE_TABLE = 'SIMPLE_TABLE',
  SIMPLE_AI = 'SIMPLE_AI'
}

export enum TopicItemTableType {
  DETAILED = 'DETAILED',
  ANONYMOUS = 'ANONYMOUS',
  AVERAGE = 'AVERAGE',
  FAMILIARITY = 'FAMILIARITY'
}

export enum TopicTableType {
  OVERALL = 'OVERALL',
  OVERALL_FAMILIARITY = 'OVERALL_FAMILIARITY'
}

export enum TopicReportPolicy {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED'
}

export enum LocalStorageKeys {
  // Wizard
  WIZARD_ADJECTIVES = 'wizard.adjectives',
  WIZARD_PAGE = 'wizard.page',
  WIZARD_STEP = 'wizard.step',

  // Survey
  SURVEY_TOPIC = 'survey.topic',
  SURVEY_PROJECT = 'survey.project',
  SURVEY_DATA = 'survey.data',
  SURVEY_THANKS_DATA = 'survey.thanks.data',
  SURVEY_SHARE = 'survey.share',
  SURVEY_RESPONDS = 'survey.responds',
  SURVEY_SENIORITY = 'survey.seniority',

  // Feedback
  FEEDBACK_COMMENT = 'feedback.comment',
  FEEDBACK_COMMENT_INDEX = 'feedback.comment.index',
  FEEDBACK_RELATION = 'feedback.relation',
  FEEDBACK_FAMILIARITY = 'feedback.familiarity',

  // User
  USER_NAME = 'user.name',
  USER_EMAIL = 'user.email',

  // General
  LANGUAGE = 'lang',
  VERIFICATION_TIMEOUT = 'verification.timeout',

  // Chat
  ADMIN_CHAT_MESSAGES = 'admin.chat.messages'
}

export enum TopicCommentRule {
  NO_COMMENT = 'NO_COMMENT',
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL'
}
export enum RectifyType {
  DIAGNOSE_ISSUE = 'DIAGNOSE_ISSUE',
  SURVEY_RESPONSE = 'SURVEY_RESPONSE'
}

export enum ReportState {
  NOT_READY = 'NOT_READY',
  READY = 'READY'
}

export enum VerificationTimeoutCodes {
  RecoverPassword = 'RecoverPassword',
  AuthVerify = 'MagicLink',
  UserSettingsExport = 'UserSettingsExport',
  UserSettingsDelete = 'UserSettingsDelete',
  UserSettingsPassword = 'UserSettingsPassword',
  DiagnoseReport = 'DiagnoseReport'
}

export enum ReferenceType {
  PROJECT = 'PROJECT',
  SURVEY = 'SURVEY',
  USER = 'USER',
  REPORT = 'REPORT',
  DIAGNOSE = 'DIAGNOSE'
}

export enum ProjectRestrictionPolicy {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum ChallengeCategory {
  PSYCHOLOGICAL_SAFETY = 'PSYCHOLOGICAL_SAFETY',
  COMMUNICATION = 'COMMUNICATION',
  NEGOTIATION = 'NEGOTIATION',
  COACHING_MINDSET = 'COACHING_MINDSET',
  RESILIENCE = 'RESILIENCE',
  EMPATHY = 'EMPATHY',
  EMOTIONAL_INTELLIGENCE = 'EMOTIONAL_INTELLIGENCE',
  GROWTH_MINDSET = 'GROWTH_MINDSET',
  FEEDBACK_CULTURE = 'FEEDBACK_CULTURE',
  INCLUSIVE_LEADERSHIP = 'INCLUSIVE_LEADERSHIP',
  PROBLEM_SOLVING = 'PROBLEM_SOLVING',
  HIGH_PERFORMANCE_TEAMS = 'HIGH_PERFORMANCE_TEAMS',
  MOTIVATION_ENERGY = 'MOTIVATION_ENERGY',
  STRATEGIC_CLARITY = 'STRATEGIC_CLARITY',
  AGILE_WORKING = 'AGILE_WORKING',
  INNOVATION_CREATIVITY = 'INNOVATION_CREATIVITY',
  DIVERSITY_INCLUSION = 'DIVERSITY_INCLUSION',
  EMPLOYEE_MENTAL_HEALTH = 'EMPLOYEE_MENTAL_HEALTH',
  CHANGE_MANAGEMENT = 'CHANGE_MANAGEMENT',
  REMOTE_WORK = 'REMOTE_WORK'
}

export enum CoreBehaviorCategory {
  PSYCHOLOGICAL_SAFETY = 'PSYCHOLOGICAL_SAFETY',
  COMMUNICATION = 'COMMUNICATION',
  NEGOTIATION = 'NEGOTIATION',
  COACHING_MINDSET = 'COACHING_MINDSET',
  RESILIENCE = 'RESILIENCE',
  EMPATHY = 'EMPATHY',
  EMOTIONAL_INTELLIGENCE = 'EMOTIONAL_INTELLIGENCE',
  GROWTH_MINDSET = 'GROWTH_MINDSET',
  FEEDBACK_CULTURE = 'FEEDBACK_CULTURE',
  INCLUSIVE_LEADERSHIP = 'INCLUSIVE_LEADERSHIP',
  PROBLEM_SOLVING = 'PROBLEM_SOLVING',
  HIGH_PERFORMANCE_TEAMS = 'HIGH_PERFORMANCE_TEAMS',
  MOTIVATION_ENERGY = 'MOTIVATION_ENERGY',
  STRATEGIC_CLARITY = 'STRATEGIC_CLARITY',
  AGILE_WORKING = 'AGILE_WORKING',
  INNOVATION_CREATIVITY = 'INNOVATION_CREATIVITY',
  DIVERSITY_INCLUSION = 'DIVERSITY_INCLUSION',
  EMPLOYEE_MENTAL_HEALTH = 'EMPLOYEE_MENTAL_HEALTH',
  CHANGE_MANAGEMENT = 'CHANGE_MANAGEMENT',
  REMOTE_WORK = 'REMOTE_WORK',
}

export enum CompanyIndustry {
  TECHNOLOGY = 'TECHNOLOGY',
  FINANCE = 'FINANCE',
  HEALTHCARE = 'HEALTHCARE',
  EDUCATION = 'EDUCATION',
  RETAIL = 'RETAIL',
  MANUFACTURING = 'MANUFACTURING',
  CONSTRUCTION = 'CONSTRUCTION',
  REAL_ESTATE = 'REAL_ESTATE',
  TRANSPORTATION = 'TRANSPORTATION',
  ENTERTAINMENT = 'ENTERTAINMENT'
}

export enum CompanySize {
  SMALL = 'SMALL', // 1-50 employees
  MEDIUM = 'MEDIUM', // 51-200 employees
  LARGE = 'LARGE', // 201-1000 employees
  ENTERPRISE = 'ENTERPRISE' // 1001+ employees
}

export enum CompanyRevenue {
  LOW = 'LOW', // Up to $1 million
  MID = 'MID', // $1 million - $10 million
  HIGH = 'HIGH', // $10 million - $50 million
  VERY_HIGH = 'VERY_HIGH', // $50 million - $100 million
  ENTERPRISE = 'ENTERPRISE' // Over $100 million
}

export enum ChartType {
  BAR = 'BAR', // Bar Chart: Average ratings across items
  RELATION_BAR = 'RELATION_BAR', // Relation-based Group Bar Chart: Compare ratings across relations
  FAMILIARITY_BAR = 'FAMILIARITY_BAR', // Familiarity-based Group Bar Chart: Compare ratings across familiarities
  PIE = 'PIE', // Pie Chart: Distribution of options chosen across all assessment items
  RADAR = 'RADAR', // Radar Chart: Compare self vs peer assessment for all items
  HEATMAP = 'HEATMAP', // Heatmap: Display ratings intensity for each respondent
  LINE = 'LINE' // Line Chart: Shows trends across ratings
}

export enum ChartDataType {
  SUMMARY = 'SUMMARY',
  SECTION = 'SECTION',
  ITEM = 'ITEM'
}

export enum SurveyReportAccess {
  NO_ACCESS = 'NO_ACCESS',
  HAVE_ACCESS = 'HAVE_ACCESS',
  ACCESS_REQUESTED = 'ACCESS_REQUESTED'
}

export enum TableDataType {
  SUMMARY = 'SUMMARY',
  SECTION = 'SECTION',
  ITEM = 'ITEM',
}