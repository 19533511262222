import { Box } from '@mui/material';
import { ProgressBar } from '@survey/report/components';

const ItemProgress = (props: { user?: number; other?: number }) => (
  <>
    <Box textAlign="center" display="block" component="small">
      {props.user?.toFixedString(1, '-') || '-'} /{' '}
      {props.other?.toFixedString(1, '-') || '-'}
    </Box>
    <ProgressBar
      variant="determinate"
      color="success"
      value={((props.user || 0) / 5) * 100}
    />
    <ProgressBar variant="determinate" value={((props.other || 0) / 5) * 100} />
  </>
);

export default ItemProgress;

