import { addAlphaToHex, fontWeights } from '@/helpers';
import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const AuthPanel = styled(Box)(({ theme }) => ({
  backgroundColor: addAlphaToHex('#FFF', 50),
  borderRadius: 24,
  boxShadow: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`,
  padding: theme.spacing(4),
  margin: '0 calc((100% - 402px) / 2)',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    backgroundColor: 'transparent',
    borderRadius: 0,
    boxShadow: 'none',
    padding: `${theme.spacing(4)} ${theme.spacing(32 / 12)}`
  }
}));

const AuthPanelHeader = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: fontWeights.semiBold,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18
  }
}));

const AuthPanelHeader2 = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: fontWeights.regular,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
}));

const AuthSocialButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: fontWeights.regular,
  color: '#000',
  '& img, & svg': {
    borderRadius: 24,
    border: 'solid 1px #CFCDCD',
    padding: 14.5,
    width: 57,
    height: 57
  },
  '& .MuiButton-startIcon': {
    margin: '0 !important'
  }
}));

const AuthButtonBack = (props: { to?: string; onClick?: () => void }) =>
  props.to ? (
    <Button
      sx={{
        color: '#AFAFAF',
        fontSize: {
          xs: 12,
          sm: 14
        },
        fontWeight: fontWeights.regular,
        mb: 2,
        p: 0
      }}
      type='button'
      component={Link}
      to={props.to}
    >
      <ArrowBack
        sx={{
          mr: {
            xs: 4 / 12,
            sm: 8 / 12
          }
        }}
      />
      Back
    </Button>
  ) : (
    <Button
      sx={{
        color: '#AFAFAF',
        fontSize: {
          xs: 12,
          sm: 14
        },
        fontWeight: fontWeights.regular,
        mb: 2,
        p: 0
      }}
      type='button'
      onClick={props.onClick}
    >
      <ArrowBack
        sx={{
          mr: {
            xs: 4 / 12,
            sm: 8 / 12
          }
        }}
      />
      Back
    </Button>
  );

export {
  AuthButtonBack,
  AuthPanel,
  AuthPanelHeader,
  AuthPanelHeader2,
  AuthSocialButton
};
