import { useTranslation } from '@/components/multi-lang';
import { LocalStorageKeys } from '@/enums';
import { fontWeights, parseJSON } from '@/helpers';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

type IProps = {
  comment: TopicCommentSectionResponse;
  submit: Action;
  minLength: number;
  last?: boolean;
};

const SurveyComment = ({ comment, submit, minLength, last }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Feedback');
  const [text, setText] = useState('');

  const internalSubmit = async (withComment: boolean) => {
    const t = text.trim();
    if (!comment.optional && t.length < minLength) {
      return;
    }

    if (withComment && t.length > 0) {
      let comments =
        parseJSON<CreateSurveyRespondCommentBody[]>(
          localStorage.getItem(LocalStorageKeys.FEEDBACK_COMMENT)
        ) || [];
      comments.push({
        commentId: comment.id,
        comment: t
      });

      localStorage.setItem(
        LocalStorageKeys.FEEDBACK_COMMENT,
        JSON.stringify(comments)
      );
    }
    setText('');
    submit();
  };
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        mx={{
          xs: 1,
          sm: 'calc((100vw - 700px) / 2)'
        }}
      >
        <Typography
          variant='h2'
          fontSize={{
            xs: '18px',
            sm: '20px'
          }}
          fontWeight={fontWeights.bold}
        >
          {comment.title}
        </Typography>
        <Box
          mt={{
            xs: 1.5,
            sm: 3
          }}
          bgcolor='white'
          border='solid 1px rgba(188,19,19,0.2)'
          borderRadius='24px'
          p={{
            xs: 16 / 12,
            sm: 32 / 12
          }}
          display='flex'
          flexDirection='column'
          position='relative'
        >
          <Box
            position='absolute'
            top='16px'
            right='16px'
            zIndex={1}
            color={theme.palette.primary.main}
          >
            <Box
              component='span'
              color={text.trim().length < minLength ? '#EC4849' : '#32EFBE'}
            >
              {text.trim().length}
            </Box>
            <Box component='span'>/{minLength}</Box>
          </Box>
          <TextField
            type='text'
            name='text'
            multiline
            rows={3}
            value={text}
            placeholder={t('WriteAtLeast', { minLength })}
            onChange={(e) => setText(e.target.value)}
            sx={{
              '& fieldset': {
                border: 'none',
                outline: 'none'
              }
            }}
          />
        </Box>
        <Box textAlign='right'>
          {comment.optional && (
            <Button
              variant='text'
              color='secondary'
              sx={{
                mr: 1
              }}
              onClick={() => internalSubmit(false)}
            >
              {t('$Skip')}
            </Button>
          )}
          <Button
            sx={{
              mt: 1
            }}
            variant='contained'
            type='button'
            onClick={() => internalSubmit(true)}
            disabled={text.trim().length < minLength}
          >
            {last ? t('$Submit') : t('$Next')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SurveyComment;
