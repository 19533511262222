export default class Logger {
  private static get writeLogs() {
    return false;
  }

  static log(data: any) {
    if (process.env.NODE_ENV === 'development' && this.writeLogs) {
      console.log(data);
    }
  }
}
