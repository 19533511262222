import { createContext, useContext } from 'react';

const ReportContext = createContext<IReportContext>({} as any);
const useReportContext = () => useContext(ReportContext);

export { useReportContext };

export type ReportContextType = React.ContextType<typeof ReportContext>;

export default ReportContext;
