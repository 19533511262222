//#region Imports

import { useTranslation } from '@/components';
import { VerificationTimeoutCodes } from '@/enums';
import {
  checkEmailValidity,
  fontWeights,
  getQueryString,
  getVerificationTimeout,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import { AuthButtonBack, AuthPanel, AuthPanelHeader } from '@auth/styleds';
import { useAppContext } from '@contexts/app-context';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//#endregion Imports

enum Steps {
  Recover = 1,
  Reset = 2,
  Done = 3
}

const PasswordCheck = ({
  color,
  children
}: {
  color?: string;
  children: string | string[];
}) => (
  <Typography
    color={color}
    variant='caption'
    display='inline-block'
    lineHeight={1}
  >
    {children}
  </Typography>
);

const RecoverPassword = () => {
  const { setMainClasses } = useAppContext();
  const theme = useTheme();
  const t = useTranslation('Authentication.RecoverPassword');

  const [step, setStep] = useState<Steps>(Steps.Recover);
  const [remainingTime, setRemainingTime] = useState(60);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<FormError>(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<FormError>(false);
  const [confirm, setConfirm] = useState('');
  const [confirmError, setConfirmError] = useState<FormError>(false);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  );

  //#region Timer

  let timeoutId: any;

  const getExpireTime = () => {
    return (
      getVerificationTimeout(VerificationTimeoutCodes.RecoverPassword) || 0
    );
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  //#endregion Timer

  //#region Password strength

  const passwordHas8Characters = () =>
    password.trim().length > 7
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';

  const passwordHasUpperCase = () =>
    /[A-Z]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';

  const passwordHasLowerCase = () =>
    /[a-z]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';

  const passwordHasNumber = () =>
    /[0-9]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';

  const passwordHasSpecialCharacter = () =>
    /[^A-Za-z0-9\s]/g.test(password)
      ? theme.palette.success.main
      : passwordError !== false
      ? theme.palette.error.main
      : 'black';

  //#endregion Password strength

  const submit = async () => {
    if (step === Steps.Recover) {
      if (!checkEmailValidity(email)) {
        setEmailError('$Authentication.InvalidEmail');
        return;
      }

      setLoading(true);

      try {
        await webClient.api.auth.password.forget({
          email
        });

        if (window.hasReturnUrl) {
          window.history.replaceState(
            null,
            '',
            `recover-password/?returnUrl=${window.returnUrlEncoded}&email=${email}`
          );
        } else {
          window.history.replaceState(
            null,
            '',
            `recover-password/?email=${email}`
          );
        }

        setVerificationTimeout(VerificationTimeoutCodes.RecoverPassword, 60);
        setLoading(false);
        setStep(Steps.Reset);
        startTimer();
      } catch (exp) {
        setLoading(false);
        setEmailError('UserNotFound');
      }

      return;
    }

    const pass = password.trim();

    if (
      pass.length < 8 ||
      !/[A-Z]/g.test(pass) ||
      !/[a-z]/g.test(pass) ||
      !/[0-9]/g.test(pass) ||
      !/[^a-zA-Z0-9\s]/g.test(pass)
    ) {
      setPasswordError('Has error');
    }

    if (confirm !== password) {
      setConfirmError('$Authenticated.ConfirmNotMatched');
    }

    if (passwordError !== false || confirmError !== false) {
      return;
    }

    try {
      await webClient.api.auth.password.reset({
        email,
        code,
        password
      });

      if (window.hasReturnUrl) {
        window.history.replaceState(
          null,
          '',
          `recover-password/?returnUrl=${window.returnUrlEncoded}`
        );
      } else {
        window.history.replaceState(null, '', `recover-password/`);
      }
      setStep(Steps.Done);
    } catch (exp: any) {
      setCodeError('$Authentication.Verify.VerificationCodeIsWrong');
    }
  };

  const togglePassword = () =>
    setPasswordType(passwordType === 'password' ? 'text' : 'password');

  const resend = async () => {
    try {
      setLoading(true);
      await webClient.api.auth.basic.resend({
        email
      });

      setLoading(false);
      setVerificationTimeout(VerificationTimeoutCodes.RecoverPassword, 60);
      startTimer();
    } catch (exp) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMainClasses();

    const email = getQueryString('email');
    if (email && email.trim().length > 0) {
      setEmail(email);
      setStep(Steps.Reset);
      startTimer();
    }

    return () => {
      clearTimer();
    };
    // eslint-disable-next-line
  }, []);

  if (step === Steps.Done) {
    return (
      <AuthPanel>
        <Typography
          fontSize={{
            xs: 18,
            sm: 20
          }}
          fontWeight={fontWeights.semiBold}
        >
          {t('AllDone')}
        </Typography>
        <Typography
          fontSize={{
            xs: 14,
            sm: 16
          }}
          fontWeight={fontWeights.regular}
        >
          {t('YourPasswordChanged')}
        </Typography>
        <Button
          fullWidth
          sx={{ mt: 4 }}
          variant='contained'
          component={Link}
          to={
            window.hasReturnUrl
              ? `/login/?returnUrl=${window.returnUrlEncoded}`
              : '/login/'
          }
        >
          {t('$Authentication.Login')}
        </Button>
      </AuthPanel>
    );
  }

  if (step === Steps.Reset) {
    return (
      <AuthPanel>
        <AuthPanelHeader>{t('$Authentication.ResetPassword')}</AuthPanelHeader>
        <Typography variant='h4' sx={{ mt: 1 }}>
          {t('EnterANewPasswordAndConfirmIt')}
        </Typography>
        <InputLabel sx={{ mt: 3 }} htmlFor='recover_code'>
          {t('$Authentication.Verify.VerificationCode')}
        </InputLabel>
        <OutlinedInput
          error={codeError !== false}
          type='text'
          fullWidth
          defaultValue={code}
          placeholder={t('$Authentication.Verify.VerificationCodeHint')}
          name='code'
          id='recover_code'
          onChange={(e) => {
            setCode(e.target.value);
            setCodeError(false);
          }}
        />
        <FormHelperText error={codeError !== false}>
          {codeError !== false ? t(codeError) : ''}
        </FormHelperText>
        <Box fontSize={14}>
          {t('$Authentication.Verify.DidntGetTheCode')}
          <Button
            variant='text'
            onClick={resend}
            disabled={remainingTime > 0}
            sx={{ p: 0, fontSize: 'inherit' }}
          >
            {t('$Authentication.Verify.Resend')}
          </Button>
          {remainingTime > 0 &&
            t('$Authentication.Verify.ResendItIn', {
              time:
                remainingTime > 1
                  ? t('$XSeconds', { x: remainingTime })
                  : t('$1Second')
            })}
        </Box>
        <InputLabel sx={{ mt: 1 }} htmlFor='signup_password'>
          {t('$Authentication.NewPassword')}
        </InputLabel>
        <OutlinedInput
          error={passwordError !== false}
          type={passwordType}
          fullWidth
          defaultValue={password}
          placeholder={t('$Authentication.NewPasswordHint')}
          name='password'
          id='signup_password'
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
            setConfirmError(false);
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          sx={{
            lineHeight: 1
          }}
        >
          <PasswordCheck color={passwordHas8Characters()}>
            {t('$Authentication.StrongPassword.PlusXCharacters', {
              x: 8
            })}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasUpperCase()}>
            &nbsp;{t('$Authentication.StrongPassword.UpperCase')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasLowerCase()}>
            &nbsp;{t('$Authentication.StrongPassword.LowerCase')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasNumber()}>
            &nbsp;{t('$Authentication.StrongPassword.Number')}
          </PasswordCheck>
          ,
          <PasswordCheck color={passwordHasSpecialCharacter()}>
            &nbsp;{t('$Authentication.StrongPassword.SpecialCharacter')}
          </PasswordCheck>
          .
        </FormHelperText>
        <InputLabel sx={{ mt: 1 }} htmlFor='signup_confirm'>
          {t('$Authentication.ConfirmPassword')}
        </InputLabel>
        <OutlinedInput
          error={confirmError !== false}
          type={passwordType}
          fullWidth
          defaultValue={confirm}
          placeholder={t('$Authentication.ConfirmPasswordHint')}
          name='confirm'
          id='signup_confirm'
          onChange={(e) => {
            setConfirm(e.target.value);
            setConfirmError(false);
            setPasswordError(false);
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error={confirmError !== false}>
          {confirmError !== false ? t(confirmError) : ''}
        </FormHelperText>
        <Button
          fullWidth
          sx={{ mt: 2 }}
          variant='contained'
          onClick={submit}
          disabled={
            codeError !== false ||
            code.trim().length !== 5 ||
            password.trim().length === 0 ||
            confirm.trim().length === 0 ||
            password !== confirm ||
            !passwordHas8Characters() ||
            !passwordHasLowerCase() ||
            !passwordHasNumber() ||
            !passwordHasSpecialCharacter() ||
            !passwordHasUpperCase()
          }
        >
          {t('$Authentication.ResetPassword')}
        </Button>
      </AuthPanel>
    );
  }

  return (
    <AuthPanel>
      <AuthButtonBack
        to={
          window.hasReturnUrl
            ? `/login/?returnUrl=${window.returnUrlEncoded}`
            : '/login/'
        }
      />
      <AuthPanelHeader>{t('Title')}</AuthPanelHeader>
      <Typography
        variant='h4'
        sx={{ mt: 1 }}
        fontWeight={fontWeights.regular}
        fontSize={14}
      >
        {t('EnterEmailToRecoverYourPassword')}
      </Typography>
      <InputLabel sx={{ mt: 2 }} htmlFor='recover_email'>
        {t('$Authentication.Email')}
      </InputLabel>
      <OutlinedInput
        error={emailError !== false}
        type='email'
        fullWidth
        defaultValue={email}
        placeholder={t('$Authentication.EmailHint')}
        name='email'
        id='recover_email'
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailError(false);
        }}
      />
      <FormHelperText error={emailError !== false}>
        {emailError !== false ? t(emailError) : ''}
      </FormHelperText>
      <Button
        fullWidth
        sx={{ mt: 2 }}
        variant='contained'
        disabled={
          loading ||
          emailError !== false ||
          email.trim() === '' ||
          !checkEmailValidity(email)
        }
        onClick={submit}
      >
        {loading ? <CircularProgress color='inherit' /> : t('$Next')}
      </Button>
    </AuthPanel>
  );
};

export default RecoverPassword;
