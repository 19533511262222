import { addAlphaToHex } from '@/helpers';
import { Box, styled } from '@mui/material';

const WorkItem = styled(Box)(({ theme }) => ({
  borderRadius: '24px',
  width: '100%',
  textAlign: 'center',
  padding: '8px 0',
  color: theme.palette.primary.main,
  boxShadow: `0 2px 4px ${addAlphaToHex('#000', 20)}`,
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(16 / 12)
  }
}));

export default WorkItem;
