import { Box, BoxProps, useTheme } from '@mui/material';

const ChapterIcon = ({ ...props }: Omit<BoxProps, 'children'>) => {
  const theme = useTheme();

  return (
    <Box
      component='svg'
      width='25px'
      viewBox='0 0 25 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.0879 19.3965L0.0851187 14.8514L0.08512 4.67273e-06L12.0879 5.95338L12.0879 19.3965Z'
        fill={theme.palette.primary.main}
        fillOpacity={0.8}
      />
      <path
        d='M12.0879 19.3965L24.0907 14.8514L24.0907 6.77136e-06L12.0879 5.95338L12.0879 19.3965Z'
        fill={theme.palette.primary.main}
        fillOpacity={0.4}
      />
    </Box>
  );
};

export default ChapterIcon;
