import { LocalStorageKeys, UserRoles, VerificationTimeoutCodes } from '@/enums';
import { getAuthCookie, webClient, Yup } from '@/helpers';
import { Theme } from '@mui/material';
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel';
import QRCodeImpl from 'qr.js/lib/QRCode';
import { NavigateFunction, Params } from 'react-router';

export class LoadingEvent extends Event {
  private _show!: boolean;

  get show() {
    return this._show;
  }

  constructor(show: boolean) {
    super('loading', {
      bubbles: true
    });

    this._show = show;
  }
}

export function emptyHandler<T>(e: T) {
  if ((e as any).preventDefault) {
    (e as any).preventDefault();
  }
  if ((e as any).stopPropagation) {
    (e as any).stopPropagation();
  }
}

export function forRange(to: number): number[];
export function forRange(from: number, to: number): number[];
export function forRange(from: number, to: number, step: number): number[];
export function forRange(from: number, to?: number, step?: number): number[] {
  let res: number[] = [];
  if (!to && !step) {
    for (let i = 0; i < from; i++) {
      res.push(i);
    }
  } else if (to && !step) {
    for (let i = from; i < to; i++) {
      res.push(i);
    }
  } else if (to && step) {
    for (let i = from; i < to; i += step) {
      res.push(i);
    }
  }

  return res;
}

export async function copyToClipboard(value: string) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(value);
  } else if (document.execCommand) {
    let tempInput = document.createElement('input');
    tempInput.value = value;
    tempInput.select();
    tempInput.setSelectionRange(0, 200);

    document.execCommand('copy');
  } else {
    return false;
  }

  return true;
}

export function qrCode(value: string, padding?: number) {
  const margin = (padding = typeof padding !== 'number' ? 8 : padding);
  const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel.L);
  qrcode.addData(value);
  qrcode.make();

  const cells = qrcode.modules;
  const scale = Math.ceil(256 / cells.length);
  const size = scale * cells.length;
  const sizeWithPadding = size + margin * 2;

  const canvas = document.createElement('canvas');
  canvas.width = sizeWithPadding;
  canvas.height = sizeWithPadding;

  const context = canvas.getContext('2d');
  if (!context) {
    return '';
  }

  context.fillStyle = '#FFFFFF';
  context.fillRect(0, 0, sizeWithPadding, sizeWithPadding);

  context.fillStyle = '#000000';
  for (let rowIndex = 0; rowIndex < cells.length; rowIndex++) {
    const row = cells[rowIndex];
    for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
      const cell = row[cellIndex];
      if (cell) {
        context.fillRect(
          cellIndex * scale + margin,
          rowIndex * scale + margin,
          scale,
          scale
        );
      }
    }
  }

  return canvas.toDataURL();
}

export function checkEmailValidity(email: string) {
  // eslint-disable-next-line
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

export function makeArray<T>(length: number, value: T) {
  let res: T[] = [];

  for (let i = 0; i < length; i++) {
    res.push(JSON.parse(JSON.stringify(value)));
  }

  return res;
}

export function colorCalculator(value: number) {
  if (value === 0) {
    return '#CCC';
  }

  if (value < 0) {
    //value = Math.abs(value);
    value = 100;
    return toHslString(338, value * 0.81, value * 0.03 + 80);
  }

  value = 100;
  return toHslString(164, value * 0.86, 80 - value * 0.23);
}

export function toHslString(h: number, s: number, l: number) {
  return `hsl(${Math.round(h)}, ${Math.round(s)}%, ${Math.round(l)}%)`;
}

export function measureText(
  text: string,
  font: string,
  lineHeight: number,
  maxWidth?: number
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('ctx is null');
  }

  ctx.font = font;
  if (!maxWidth) {
    return {
      width: ctx.measureText(text).width,
      height: lineHeight,
      lines: [text]
    };
  }

  let lines: string[] = [];
  let textArray = text.split(' ');
  let temp = '';
  while (textArray.length > 0) {
    const word = '' + textArray.shift();
    const temp2 = temp + (temp === '' ? '' : ' ') + word;

    if (ctx.measureText(temp2).width > maxWidth) {
      lines.push(temp2);
      temp = word;
    } else {
      temp = temp2;
    }
  }

  if (temp.length > 0) {
    lines.push(temp);
  }

  return {
    width: maxWidth,
    height: lines.length * lineHeight,
    lines
  };
}

//type InputType = (EventTarget & { value: string; name: string }) | (EventTarget & (HTMLInputElement | HTMLTextAreaElement));
type InputType =
  | EventTarget
  | (EventTarget & (HTMLInputElement | HTMLTextAreaElement))
  | null;
export function getInputValue(input: InputType, type?: string): any {
  return '';
}

export function appendVersion(url: string) {
  if (!window.versions) {
    return url;
  }

  const version = window.versions[url];
  if (!version) {
    return url;
  }

  return `${url}?v=${version}`;
}

export function getCdnUrl(url: string) {
  return process.env.REACT_APP_CDN + appendVersion(url);
}

export function getOrDefault<T>(
  array: T[] | undefined,
  index: number,
  def: T
): T;
export function getOrDefault<T>(array?: T[], index?: number, def?: T) {
  if (!array) {
    return def;
  }

  index = index || 0;

  if (array.length > index) {
    return array[index];
  }

  return def;
}

export function promptValidator(...parameters: (string | RegExp)[]) {
  return {
    message({ value }: { value: string }) {
      let matches = value.matchAllArray(/\{([^}]+)}/g);
      let invalidVariables = [];
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];

          if (
            !parameters.any((x) =>
              typeof x === 'string' ? x === match[1] : x.test(match[1])
            )
          ) {
            invalidVariables.push(match[1]);
          }
        }
      }
      return `Invalid variables: ${invalidVariables.join(', ')}`;
    },
    test: (value: Yup.Maybe<string>) => {
      let matches = value?.matchAllArray(/\{([^}]+)}/g);
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];

          if (
            !parameters.any((x) =>
              typeof x === 'string' ? x === match[1] : x.test(match[1])
            )
          ) {
            return false;
          }
        }
      }

      return true;
    }
  };
}

export function getShortName(name: string) {
  if (name.length < 5) {
    return name;
  }

  return name + '.';
}

export function centerOf(a: number, b: number) {
  if (a > b) {
    [a, b] = [b, a];
  }

  return (b - a) / 2 + a;
}

export function addAlphaToHex(hex: string, alpha: number) {
  let alphaHex = Math.round((alpha / 100) * 255).toString(16);
  if (alphaHex.length < 2) {
    alphaHex = `0${alphaHex}`;
  }

  if (hex.startsWith('#')) {
    hex = hex.subString(1);
  }

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  return `#${hex}${alphaHex}`;
}

export function randPerm(max: number, length: number): number[];
export function randPerm(min: number, max: number, length: number): number[];
export function randPerm(min: number, max?: number, length?: number): number[] {
  if (!length) {
    length = max;
    max = min;
    min = 0;
  }

  if (max && min > max) {
    [min, max] = [max, min];
  }

  let res = forRange(min, max!);

  for (let i = 0; i < res.length * 10; i++) {
    let i = Math.round(Math.random() * (res.length - 1));
    let j = Math.round(Math.random() * (res.length - 1));

    let temp = res[i];
    res[i] = res[j];
    res[j] = temp;
  }

  while (res.length < length!) {
    res = [...res, ...res];
  }

  return res;
}

export function getQueryString(cname: string) {
  if (document.location.search === '') {
    return;
  }

  const name = cname + '=';
  const ca = decodeURIComponent(document.location.search.substring(1)).split(
    '&'
  );

  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trimStart();

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
}

export function redirectToUserspace(navigate: NavigateFunction) {
  if (window.hasReturnUrl && window.returnUrl !== '/') {
    return navigate(window.returnUrl, {
      replace: true
    });
  }

  const auth = getAuthCookie();
  if (!auth) {
    return navigate(`/login/?returnUrl=${window.returnUrlEncoded}`);
  }

  switch (auth.role) {
    case UserRoles.User:
      return navigate('/user/');

    case UserRoles.SuperAdmin:
    case UserRoles.Admin:
      return navigate('/admin/');

    default:
      return navigate('/');
  }
}

export function setVerificationTimeout(
  code: VerificationTimeoutCodes,
  seconds: number
) {
  let timeouts = JSON.parse(
    localStorage.getItem(LocalStorageKeys.VERIFICATION_TIMEOUT) || '{}'
  ) as Record<VerificationTimeoutCodes, number>;
  const time = Math.round((new Date().getTime() + seconds * 1e3) / 1000);

  timeouts = {
    ...timeouts,
    [code]: time
  };
  localStorage.setItem(
    LocalStorageKeys.VERIFICATION_TIMEOUT,
    JSON.stringify(timeouts)
  );
}

export function getVerificationTimeout(code: VerificationTimeoutCodes) {
  const timeouts = JSON.parse(
    localStorage.getItem(LocalStorageKeys.VERIFICATION_TIMEOUT) || '{}'
  ) as Record<VerificationTimeoutCodes, number>;

  return code in timeouts ? timeouts[code] : undefined;
}

export function getLocalizedText(
  field: LocalizedField,
  lang?: Languages,
  def?: string
): string;
export function getLocalizedText(
  field?: LocalizedField,
  lang?: Languages,
  def?: string
): string | undefined;
export function getLocalizedText(
  field?: LocalizedField,
  lang?: Languages,
  def?: string
): string | undefined {
  return hasLocalizedText(field, lang)
    ? field![lang || 'en']
    : typeof def === 'undefined'
    ? field?.default
    : def;
}

export function hasLocalizedText(field?: LocalizedField, lang?: Languages) {
  return !field
    ? false
    : Object.hasOwn(field, lang || 'en') && !!field[lang || 'en']
    ? true
    : false;
}

export function getTopicLocalizationProgress(
  topic: TopicDetailedLocalizeResponse | undefined,
  lang: Languages
) {
  if (!topic) {
    return {
      fieldsCount: 0,
      localizedFields: 0,
      progress: 0
    };
  }

  let fieldsCount = topic.description ? 2 : 1;
  let localizedFields = 0;

  if (hasLocalizedText(topic.name, lang)) {
    localizedFields++;
  }

  if (topic.description && hasLocalizedText(topic.description, lang)) {
    localizedFields++;
  }

  for (let i = 0; i < topic.items.length; i++) {
    const item = topic.items[i];

    fieldsCount += item.description ? 2 : 1;

    if (hasLocalizedText(item.name, lang)) {
      localizedFields++;
    }

    if (item.description && hasLocalizedText(item.description, lang)) {
      localizedFields++;
    }
  }

  if (topic.options) {
    for (let i = 0; i < topic.options.length; i++) {
      const opt = topic.options[i];

      fieldsCount++;

      if (hasLocalizedText(opt.title, lang)) {
        localizedFields++;
      }
    }
  }

  for (let i = 0; i < topic.relations.length; i++) {
    const relation = topic.relations[i];

    fieldsCount++;

    if (hasLocalizedText(relation.relation, lang)) {
      localizedFields++;
    }
  }

  if (topic.commentSections) {
    for (let i = 0; i < topic.commentSections.length; i++) {
      const commentSection = topic.commentSections[i];

      fieldsCount += commentSection.description ? 2 : 1;

      if (hasLocalizedText(commentSection.title, lang)) {
        localizedFields++;
      }

      if (
        commentSection.description &&
        hasLocalizedText(commentSection.description, lang)
      ) {
        localizedFields++;
      }
    }
  }

  if (topic.seniorities) {
    for (let i = 0; i < topic.seniorities.length; i++) {
      const seniority = topic.seniorities[i];

      fieldsCount += seniority.description ? 2 : 1;

      if (hasLocalizedText(seniority.title, lang)) {
        localizedFields++;
      }

      if (
        seniority.description &&
        hasLocalizedText(seniority.description, lang)
      ) {
        localizedFields++;
      }
    }
  }

  return {
    fieldsCount,
    localizedFields,
    progress: (localizedFields / fieldsCount) * 100
  };
}

export async function translate(
  text: string,
  lang: Languages,
  inputName: string,
  setFieldValue: (field: string, value: any) => any
) {
  const res = await webClient.api.tools.translator(text, lang, {
    loadingHandled: true
  });
  setFieldValue(inputName, res.data.data);
}

export function getDefaultLanguageOfField(field: LocalizedField) {
  if (field.en === field.default) {
    return 'en';
  }

  const keys = Object.keys(field).filter((x) => x !== 'default') as Languages[];
  for (let i = 0; i < keys.length; i++) {
    const lang = keys[i];

    if (field[lang] === field.default) {
      return lang;
    }
  }

  return 'en';
}

export function parseJSON<T>(str: string | null): T | undefined {
  if (!str) {
    return;
  }

  return JSON.parse(str) as T;
}

export function queryString(params: Record<string, any>) {
  let res: string[] = [];

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];

      if (value) {
        res = [...res, `${key}=${value}`];
      }
    }
  }

  return res.length > 0 ? `?${res.join('&')}` : '';
}

export const deepSet = (
  obj: any,
  path: string,
  val: any | ((val: any) => any)
) => {
  path = path.replaceAll('[', '.[');
  const keys = path.split('.');

  for (let i = 0; i < keys.length; i++) {
    let currentKey = keys[i] as any;
    let nextKey = keys[i + 1] as any;
    if (currentKey.includes('[')) {
      currentKey = parseInt(currentKey.substring(1, currentKey.length - 1));
    }
    if (nextKey && nextKey.includes('[')) {
      nextKey = parseInt(nextKey.substring(1, nextKey.length - 1));
    }

    if (typeof nextKey !== 'undefined') {
      obj[currentKey] = obj[currentKey]
        ? obj[currentKey]
        : isNaN(nextKey)
        ? {}
        : [];
    } else {
      obj[currentKey] = typeof val === 'function' ? val(obj[currentKey]) : val;
    }

    obj = obj[currentKey];
  }
};

export const jsonToFormData = (json: Record<string, any>) => {
  const formData = new FormData();

  // Helper function to handle nested objects
  function appendFormData(data: Record<string, any>, root: string = ''): void {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const formKey = root ? `${root}[${key}]` : key;
        if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
          appendFormData(data[key], formKey);
        } else if (typeof data[key] !== 'undefined') {
          formData.append(formKey, data[key]);
        }
      }
    }
  }

  appendFormData(json);
  return formData;
};

export const upperCasedEnumToEnglish = (category: string) => {
  if (!category) {
    return category;
  }

  return category
    .replace(/^-/, '')
    .replace(/[A-Z]+/g, (x) => `${x[0]}${x.toLowerCase().substring(1)}`)
    .replace(/_/g, ' ');
};

export const needPatch = <TState, T>(
  skip: boolean,
  tempState: TState,
  state: TState,
  predicate: (state: TState) => T
): T | undefined | null => {
  if (skip) {
    return predicate(state);
  }

  if (
    JSON.stringify(predicate(state)) === JSON.stringify(predicate(tempState))
  ) {
    return;
  }

  return predicate(state) || null;
};

const initIndexedDb = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = window.indexedDB.open('johariLocalDb', 4);
    request.addEventListener('upgradeneeded', function (ev) {
      const db = this.result;

      let currentVersion = ev.oldVersion;

      if (currentVersion < 1) {
        db.createObjectStore('users', {
          autoIncrement: false,
          keyPath: 'id'
        });
        currentVersion++;
      }

      if (currentVersion === 1) {
        db.createObjectStore('companies', {
          autoIncrement: false,
          keyPath: 'id'
        });
        currentVersion++;
      }

      if (currentVersion === 2) {
        db.createObjectStore('challenges', {
          autoIncrement: false,
          keyPath: 'id'
        });
        currentVersion++;
      }

      if (currentVersion === 3) {
        db.createObjectStore('coreBehaviors', {
          autoIncrement: false,
          keyPath: 'id'
        });
        currentVersion++;
      }
    });
    request.addEventListener('success', function () {
      resolve(this.result);
    });
    request.addEventListener('error', reject);
  });
};

export const putInCache = async (store: string, value: any) => {
  const db = await initIndexedDb();
  const transaction = db.transaction(store, 'readwrite');
  transaction.objectStore(store).put(value);
  transaction.commit();
};

export const getFromCache = async <T>(store: string, id: string) => {
  const db = await initIndexedDb();
  const transaction = db.transaction(store, 'readonly');
  const request = transaction.objectStore(store).get(id) as IDBRequest<
    T | undefined
  >;
  return new Promise<T | undefined>((resolve, reject) => {
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = reject;
  });
};

export const putUserInCache = (value: UserResponse) =>
  putInCache('users', value);

export const getUserFromCache = (id: string) =>
  getFromCache<UserResponse>('users', id);

export const putCompanyInCache = (value: CompanyResponse) =>
  putInCache('companies', value);

export const getCompanyFromCache = (id: string) =>
  getFromCache<CompanyResponse>('companies', id);

export const putChallengeInCache = (value: PredefinedChallengeResponse) =>
  putInCache('challenges', value);

export const getChallengeFromCache = (id: string) =>
  getFromCache<PredefinedChallengeResponse>('challenges', id);

export const putCoreBehaviorInCache = (value: CoreBehaviorResponse) =>
  putInCache('coreBehaviors', value);

export const getCoreBehaviorFromCache = (id: string) =>
  getFromCache<CoreBehaviorResponse>('coreBehaviors', id);

export const lightenHexColor = (hex: string, percent: number) => {
  // Ensure the hex is in the correct format
  let cleanHex = hex.replace('#', '');

  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((x) => x + x)
      .join('');
  }

  // Parse the hex color into RGB components
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  // Calculate the new RGB values, mixing with white (255, 255, 255)
  const newR = Math.round(r + (255 - r) * (percent / 100));
  const newG = Math.round(g + (255 - g) * (percent / 100));
  const newB = Math.round(b + (255 - b) * (percent / 100));

  // Convert the new RGB values back to hex
  const newHex =
    '#' +
    [newR, newG, newB].map((x) => x.toString(16).padStart(2, '0')).join('');

  return newHex;
};

export const darkenHexColor = (hex: string, percent: number) => {
  // Ensure the hex is in the correct format
  let cleanHex = hex.replace('#', '');

  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((x) => x + x)
      .join('');
  }

  // Parse the hex color into RGB components
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  // Calculate the new RGB values, mixing with black (0, 0, 0)
  const newR = Math.round(r * (1 - percent / 100));
  const newG = Math.round(g * (1 - percent / 100));
  const newB = Math.round(b * (1 - percent / 100));

  // Convert the new RGB values back to hex
  const newHex =
    '#' +
    [newR, newG, newB].map((x) => x.toString(16).padStart(2, '0')).join('');

  return newHex;
};

export const getTextColor = (hex: string): string => {
  // Convert hex to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Calculate relative luminance (formula from WCAG)
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // If luminance is greater than 0.5, return black, otherwise white
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

export const getThemeWithCustomizations = (
  theme: Theme,
  customizations: CompanyCustomizations,
  skips?: Partial<
    Record<keyof (CompanyCustomizations & { background: string }), boolean>
  >
) => ({
  ...theme,
  palette: {
    ...theme.palette,
    primary:
      customizations.primaryColor && (!skips || !skips.primaryColor)
        ? {
            main: customizations.primaryColor,
            light: lightenHexColor(customizations.primaryColor, 30),
            dark: darkenHexColor(customizations.primaryColor, 30),
            contrastText: getTextColor(customizations.primaryColor)
          }
        : theme.palette.primary,
    secondary:
      customizations.secondaryColor && (!skips || !skips.secondaryColor)
        ? {
            main: customizations.secondaryColor,
            light: lightenHexColor(customizations.secondaryColor, 30),
            dark: darkenHexColor(customizations.secondaryColor, 30),
            contrastText: getTextColor(customizations.secondaryColor)
          }
        : theme.palette.secondary
  }
});

export const hexToColorMatrix = (hex: string, alpha?: number) => {
  alpha = typeof alpha !== 'number' ? 100 : alpha;
  // Ensure the hex is in the correct format
  let cleanHex = hex.replace('#', '');

  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((x) => x + x)
      .join('');
  }

  // Parse the hex color into RGB components
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  return `0 0 0 0 ${Number((r / 255).toFixed(6))} 0 0 0 0 ${Number(
    (g / 255).toFixed(6)
  )} 0 0 0 0 ${Number((b / 255).toFixed(6))} 0 0 0 ${Number(
    (alpha / 100).toFixed(2)
  )} 0`;
};

export const isInEnum =
  <T = string>(obj: Record<string, T>) =>
  (x: T) =>
    Object.values(obj).any((y) => y === x);

export function useQueryString<
  ParamsOrKey extends
    | string
    | Record<string, string | string[] | undefined> = string
>(): Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
> {
  let queryString = document.location.search;
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }

  let res: Record<string, string | string[]> = {};
  let queryStrings = queryString.split('&');

  for (let i = 0; i < queryStrings.length; i++) {
    const query = queryStrings[i];
    let [key, value] = query.split('=');
    const isArray = key.endsWith(']') || key in res;

    if (isArray) {
      key = key.replace(/\[[^\]]*\]$/, '');
      res[key] = [...(res[key] as []), value];
    } else {
      res[key] = value;
    }
  }

  return res as any;
}
