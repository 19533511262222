import { ErrorAnimation } from '@/animations';
import { useTranslation } from '@/components';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { useNavigate, useParams } from 'react-router';

const LazyLottie = React.lazy(() => import('lottie-react'));

type IProps = {
  context: Pick<IContext, 'setError'>;
  code?: string;
};

const ErrorComponent = ({ context, ...props }: IProps) => {
  const { setError } = context;
  const t = useTranslation();
  const navigate = useNavigate();

  const code = useParams<'code'>().code || props.code || 404;

  const renderError = () => {
    if (code === '401') {
      return t('Error401');
    }

    if (code === '403') {
      return t('Error403');
    }

    if (code === '404') {
      return t('Error404');
    }

    return t('Error500');
  };

  const back = async () => {
    await setError(false);
    navigate('/');
  };

  return (
    <Box maxWidth='500px' m='auto'>
      <Suspense fallback={<CircularProgress />}>
        <LazyLottie animationData={ErrorAnimation} />
      </Suspense>
      <Typography variant='h1'>{code}</Typography>
      <Typography mb={1} variant='h2'>
        {renderError()}
      </Typography>
      <Button variant='contained' onClick={back}>
        {t('Back')}
      </Button>
    </Box>
  );
};

export default ErrorComponent;
