import { fontWeights } from '@/helpers';
import { Box, useTheme } from '@mui/material';

type IProps = SurveyRespondComment & {
  relation?: TopicRelationResponse;
  index: number;
};

const CommentBox = ({ index, comment, relation }: IProps) => {
  const theme = useTheme();

  return (
    <Box
      fontSize='14px'
      borderRadius='24px'
      bgcolor='rgba(0, 0, 0, 0.03)'
      p='32px 32px 16px'
      display='flex'
      flexDirection='column'
      mb={16 / 12}
      sx={{ breakInside: 'avoid' }}
      key={`comment_${index}`}
    >
      <Box component='i' fontWeight={fontWeights.extraLight}>
        {comment}
      </Box>
      {relation && (
        <Box
          alignSelf='flex-end'
          fontWeight={fontWeights.semiBold}
          color={theme.palette.primary.main}
          lineHeight='16px'
        >
          {relation?.relation || 'Friend'}
        </Box>
      )}
    </Box>
  );
};

export default CommentBox;
