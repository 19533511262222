import { useTranslation } from '@/components';
import { VerificationTimeoutCodes } from '@/enums';
import {
  fontWeights,
  getVerificationTimeout,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import {
  SettingsButtonBack,
  SettingsPanel,
  SettingsPanelHeader
} from '@user/settings';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

enum Steps {
  Request = 'Request',
  Reason = 'Reason',
  Verify = 'Verify'
}

const deleteReasons = [
  'DeleteReasons.IDontSeeBenefits',
  'DeleteReasons.ItIsTooExpensive',
  'DeleteReasons.IHaveAPrivacyConcern',
  'DeleteReasons.IGotEverythingINeeded'
];

const SettingsDelete = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace.Settings');
  const navigate = useNavigate();

  const [reason, setReason] = useState('');
  const [step, setStep] = useState(Steps.Request);
  const [remainingTime, setRemainingTime] = useState(0);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);
  const [loading, setLoading] = useState(false);

  let timeoutId: any;

  const getExpireTime = () => {
    return (
      getVerificationTimeout(VerificationTimeoutCodes.UserSettingsDelete) || 0
    );
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  const resend = async () => {
    try {
      await webClient.api.users.deleteMe({
        loadingHandled: true
      });
      setVerificationTimeout(VerificationTimeoutCodes.UserSettingsDelete, 60);
      startTimer();
      setStep(Steps.Verify);
    } catch (exp) {
      showToast(t('DeletingAccountIsNotPossibleRightNow'), 3e3, 'error');
    }
  };

  const submit = async () => {
    if (step === Steps.Request) {
      return setStep(Steps.Reason);
    }

    setLoading(true);
    if (step === Steps.Reason) {
      try {
        await webClient.api.users.deleteMe({
          loadingHandled: true
        });
        setVerificationTimeout(VerificationTimeoutCodes.UserSettingsDelete, 60);
        startTimer();
        setStep(Steps.Verify);
      } catch (exp) {
        showToast(t('DeletingAccountIsNotPossibleRightNow'), 3e3, 'error');
      }

      setLoading(false);
      return;
    }

    try {
      await webClient.api.users.deleteMeConfirm({
        code,
        reason
      });
      setLoading(false);
      navigate('/sign-out');
    } catch (exp) {
      setLoading(false);
      setCodeError(t('$Authentication.VerificationCodeIsWrong'));
    }
  };

  useEffect(() => {
    setHeader('/user/settings/');
    setCurrentPage('settings', 'Export');
    // eslint-disable-next-line
  }, []);

  if (step === Steps.Reason) {
    return (
      <SettingsPanel>
        <SettingsButtonBack onClick={() => setStep(Steps.Request)} />
        <SettingsPanelHeader variant='h3'>
          {t('DeleteAccount')}
        </SettingsPanelHeader>
        <Typography fontSize={16} fontWeight={fontWeights.regular} mt={40 / 12}>
          {t('TellUsTheReasonForClosingYourAccount')}
        </Typography>
        <RadioGroup
          defaultValue={reason}
          onChange={(e) =>
            e.target.value === 'other'
              ? setReason('')
              : setReason(t(e.target.value))
          }
        >
          {deleteReasons.map((x, i) => (
            <FormControlLabel
              key={i}
              value={x}
              control={<Radio />}
              label={t(x)}
            />
          ))}
          <FormControlLabel
            value='other'
            control={<Radio />}
            label={t('DeleteReasons.Other')}
          />
        </RadioGroup>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: 18 }}
          variant='contained'
          onClick={submit}
        >
          {t('$Next')}
        </Button>
      </SettingsPanel>
    );
  }

  if (step === Steps.Verify) {
    return (
      <SettingsPanel>
        <SettingsButtonBack onClick={() => setStep(Steps.Request)} />
        <SettingsPanelHeader variant='h3'>
          {t('DeleteAccount')}
        </SettingsPanelHeader>
        <Typography>{t('WeSentACodeToYourEmail')}</Typography>
        <Typography>
          {t('EnterTheXDigitVerificationCode', {
            digits: '6-digit',
            email: window.user.email
          })}
        </Typography>
        <InputLabel sx={{ mt: 3 }} htmlFor='recover_code'>
          {t('$Code')}
        </InputLabel>
        <OutlinedInput
          error={codeError !== false}
          type='text'
          fullWidth
          defaultValue={code}
          placeholder={t('EnterCode')}
          name='code'
          id='recover_code'
          key='recover_code'
          onChange={(e) => {
            setCode(e.target.value);
            setCodeError(false);
          }}
        />
        <FormHelperText error={codeError !== false}>
          {codeError !== false ? codeError : ''}
        </FormHelperText>
        <Box fontSize={14} mt={1}>
          {t('$Authentication.DidntGetTheCode')}
          <Button
            variant='text'
            onClick={resend}
            disabled={remainingTime > 0}
            sx={{ p: 0, fontSize: 'inherit' }}
          >
            {t('$Authentication.Resend')}
          </Button>
          {remainingTime > 0 &&
            t('$Authentication.ResendItIn', {
              time:
                remainingTime > 1
                  ? t('$XSeconds', {
                      x: remainingTime
                    })
                  : t('$1Second')
            })}
        </Box>
        <Box mt={2} display='flex' flexDirection='row'>
          <Button
            sx={{ fontSize: 18, flex: 'auto 1 1', mr: 1 }}
            variant='outlined'
            disabled={loading || code.trim().length < 5}
            onClick={submit}
          >
            {loading ? (
              <CircularProgress color='inherit' />
            ) : (
              t('Delete account')
            )}
          </Button>
          <Button
            sx={{ fontSize: 18 }}
            variant='contained'
            onClick={() => setStep(Steps.Request)}
          >
            {t('$GoBack')}
          </Button>
        </Box>
      </SettingsPanel>
    );
  }

  return (
    <SettingsPanel>
      <SettingsButtonBack to='/user/settings/' />
      <SettingsPanelHeader variant='h3'>
        {t('DeleteAccount')}
      </SettingsPanelHeader>
      <Typography
        fontSize={14}
        fontWeight={fontWeights.regular}
        mt={8 / 12}
        color='#414042'
      >
        {t('WeAreSorryToSeeYouGo', {
          name: window.user.name
        })}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={fontWeights.regular}
        mt={4 / 12}
        color='#6D6E71'
      >
        {t('JustAQuickReminder')}
      </Typography>
      <Button
        variant='contained'
        type='button'
        disabled={loading}
        onClick={submit}
        fullWidth
        sx={{
          mt: 4
        }}
      >
        {loading && <CircularProgress />}
        {t('$Continue')}
      </Button>
    </SettingsPanel>
  );
};

export default SettingsDelete;
