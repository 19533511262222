import { useTranslation } from '@/components';
import { fontWeights, getQueryString } from '@/helpers';
import { AuthPanel } from '@auth/styleds';
import { useAppContext } from '@contexts/app-context';
import { CheckCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const SignUpThanks = () => {
  const { setMainClasses } = useAppContext();
  const navigate = useNavigate();
  const t = useTranslation('Authentication');

  useEffect(() => {
    setMainClasses();

    setTimeout(() => {
      const email = getQueryString('email');
      if (!email) {
        navigate('/');
        return;
      }

      navigate(
        `/verify/?email=${email}${
          window.hasReturnUrl ? `&returnUrl=${window.returnUrlEncoded}` : ''
        }`,
        {
          replace: true
        }
      );
    }, 5e3);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthPanel textAlign='center'>
      <CheckCircle sx={{ fontSize: '54px' }} color='success' />
      <Typography fontSize={20} fontWeight={fontWeights.semiBold} mt={16 / 12}>
        {t('$Success')}
      </Typography>
      <Typography fontSize={16} fontWeight={fontWeights.regular} mt={16 / 12}>
        {t('WeSentYouAnEmailTo')}
      </Typography>
    </AuthPanel>
  );
};

export default SignUpThanks;
