import { Box } from '@mui/material';

interface IProps {
  show: boolean;
  children: any;
}

const TabPanel = ({ show, children }: IProps) => {
  if (!show) {
    return <></>;
  }

  return <Box>{children}</Box>;
};

export default TabPanel;
