import { useTranslation } from '@/components';
import { fontWeights, getCdnUrl } from '@/helpers';
import { Box, Typography } from '@mui/material';

const DiagnoseDone = () => {
  const t = useTranslation('SurveyThanks');

  return (
    <Box
      bgcolor={{
        xs: '#f5f5f5',
        sm: 'white'
      }}
      borderRadius='24px'
      boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
      p={1}
      pt={3}
      textAlign='center'
      my='auto'
      mx={{
        xs: 1,
        sm: 'calc((100vw - 370px) / 2)'
      }}
    >
      <Box
        component='img'
        pt={2}
        src={getCdnUrl('/images/thanks.svg')}
        alt={t('ThankYou')}
      />
      <Box mt={1} textAlign='center' pb={3} px={4}>
        <Typography
          variant='h3'
          fontSize='20px'
          fontWeight={fontWeights.extraBold}
          color='#414042'
        >
          {t('ThankYou')}
        </Typography>
        <Typography
          variant='body1'
          mt='12px'
          fontSize='16px'
          textAlign='center'
          color='#6D6E71'
        >
          {t('ReceivedInputs')}
        </Typography>
      </Box>
    </Box>
  );
};

export default DiagnoseDone;
