import { TopicItemTableType, TopicTableType } from '@/enums';
import TableAnonymous from './table-anonymous';
import TableAverage from './table-average';
import TableDetailed from './table-detailed';
import TableFamiliarity from './table-familiarity';
import TableOverall from './table-overall';
import TableOverallFamiliarity from './table-overall-familiarity';

interface IProps {
  id: string;
  table: TableResponseType;
  options?: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  items?: TopicItemResponse[];
}

const TableRenderer = ({
  id,
  table,
  items,
  options,
  relations,
  familiarity
}: IProps) => {
  if (table.type === TopicItemTableType.DETAILED) {
    return (
      <TableDetailed
        key={`${id}_detailedTable`}
        id={`${id}_detailedTable`}
        options={options!}
        relations={relations}
        rows={table.items}
      />
    );
  }

  if (table.type === TopicItemTableType.AVERAGE) {
    return (
      <TableAverage
        key={`${id}_averageTable`}
        id={`${id}_averageTable`}
        relations={relations}
        rows={table.items}
      />
    );
  }

  if (table.type === TopicItemTableType.ANONYMOUS) {
    return (
      <TableAnonymous
        key={`${id}_anonymousTable`}
        id={`${id}_anonymousTable`}
        options={options!}
        rows={table.items}
      />
    );
  }

  if (table.type === TopicItemTableType.FAMILIARITY) {
    return (
      <TableFamiliarity
        key={`${id}_familiarityTable`}
        id={`${id}_familiarityTable`}
        options={options!}
        rows={table.items}
        familiarity={familiarity!}
      />
    );
  }

  if (table.type === TopicTableType.OVERALL) {
    return (
      <TableOverall
        key={`${id}_overallTable`}
        id={`${id}_overallTable`}
        items={items!}
        rows={table.items}
      />
    );
  }

  if (table.type === TopicTableType.OVERALL_FAMILIARITY) {
    return (
      <TableOverallFamiliarity
        key={`${id}_overallFamiliarityTable`}
        id={`${id}_overallFamiliarityTable`}
        items={items!}
        rows={table.items}
        familiarity={familiarity!}
      />
    );
  }

  return <></>;
};

export default TableRenderer;
