import { Loading, Markdown, useTranslation } from '@/components';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import { ReportState, VerificationTimeoutCodes } from '@/enums';
import {
  addAlphaToHex,
  fontWeights,
  getVerificationTimeout,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import {
  Card,
  CardBody,
  CardHeader,
  GlassPanel,
  GlassPanelBody,
  GlassPanelHeader,
  WorkItem
} from '@diagnose/components';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const Frame = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 24,
  boxShadow: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`,
  padding: `${theme.spacing(4)} 32px`,
  margin: '0 calc((100% - 296px) / 2)',
  [theme.breakpoints.up('sm')]: {
    borderRadius: 30,
    boxShadow: `0 0 12.2px 2.44px ${addAlphaToHex('#000', 10)}`,
    padding: `${theme.spacing(7)} ${theme.spacing(4)}`,
    margin: '0 calc((100% - 371px) / 2)'
  }
}));

const DiagnoseReport = () => {
  const diagnoseId = useParams<'diagnoseId'>().diagnoseId;
  const t = useTranslation('Diagnose');

  const [diagnose, setDiagnose] = useState<DiagnoseResponse>();
  const [remainingTime, setRemainingTime] = useState(10);
  const [notReady, setNotReady] = useState(false);

  const getTimeoutId = () => {
    const timeoutId = localStorage.getItem('DiagnoseReport.TimeoutId');
    if (!timeoutId) {
      return;
    }

    return Number(timeoutId);
  };
  const setTimeoutId = (timeoutId: number) =>
    localStorage.setItem('DiagnoseReport.TimeoutId', timeoutId + '');
  const clearTimeoutId = () =>
    localStorage.removeItem('DiagnoseReport.TimeoutId');

  const getExpireTime = () => {
    return getVerificationTimeout(VerificationTimeoutCodes.DiagnoseReport) || 0;
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    const timeoutId = getTimeoutId();
    if (timeoutId) {
      clearTimeout(timeoutId);
      clearTimeoutId();
    }
  };
  const startTimer = () => {
    clearTimer();

    setTimeoutId(
      setInterval(
        () => {
          setRemainingTime(getTimeout());

          if (getTimeout() < 0) {
            clearTimer();
          }
        },
        1000,
        []
      )
    );
  };

  useEffect(() => {
    setVerificationTimeout(VerificationTimeoutCodes.DiagnoseReport, 30);
    startTimer();
  }, []);

  useEffect(() => {
    if (remainingTime % 5 !== 0) {
      return;
    }

    webClient
      .get<SingleResponse<DiagnoseResponse>>(`diagnoses/${diagnoseId}`, {
        loadingHandled: true
      })
      .then((x) => {
        setDiagnose(x.data.data);

        if (
          x.data.data.reportState === ReportState.READY ||
          remainingTime === 0
        ) {
          clearTimer();
        }

        if (remainingTime <= 0) {
          setNotReady(true);
        }
      });
  }, [diagnoseId, remainingTime]);

  if (!diagnose) {
    return <Loading />;
  }

  const render = () => {
    if (notReady) {
      return (
        <Frame>
          <CircularProgress variant='indeterminate' size={64} />
          <Typography
            mt={4}
            fontWeight={fontWeights.semiBold}
            fontSize={16}
            textAlign='center'
          >
            {t('ItIsTakingTooLong')}
          </Typography>
          <Button
            component={Link}
            to='/user/'
            variant='contained'
            sx={{
              mt: 6
            }}
          >
            {t('BackToPersonalSpace')}
          </Button>
        </Frame>
      );
    }

    if (diagnose.reportState !== ReportState.READY) {
      return (
        <Frame>
          <CircularProgress variant='indeterminate' size={64} />
          <Typography
            mt={4}
            fontWeight={fontWeights.semiBold}
            fontSize={16}
            textAlign='center'
          >
            {t('OkieDokie')}
          </Typography>
          <Typography
            mt={4}
            fontWeight={fontWeights.regular}
            fontSize={16}
            color='#6D6E71'
            textAlign='center'
          >
            {t('ItMightTakeAFewMoments')}
          </Typography>
          <Typography
            mt={4}
            fontWeight={fontWeights.regular}
            fontSize={14}
            color='#BCBEC0'
            textAlign='center'
          >
            {remainingTime > 1
              ? t('$XSeconds', { x: remainingTime })
              : t('$1Second')}
          </Typography>
        </Frame>
      );
    }

    return (
      <Box
        mx={{
          xs: 1,
          md: 'calc((100% - 985px) / 2)'
        }}
      >
        <Typography variant='h2' fontSize={18} fontWeight={fontWeights.bold}>
          {t('OurAnalysisOfYourIssue')}
        </Typography>
        <Grid
          container
          spacing={{
            xs: 2,
            sm: 3,
            md: 4
          }}
          mt={{
            xs: 2,
            sm: 3,
            md: 4
          }}
        >
          <Grid item xs={12} md={6}>
            <GlassPanel>
              <GlassPanelHeader>{t('Problems')}</GlassPanelHeader>
              <GlassPanelBody>
                <Markdown>{diagnose.issue?.clear()}</Markdown>
              </GlassPanelBody>
            </GlassPanel>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader>{t('Diagnosis')}</CardHeader>
              <CardBody>
                <Markdown>{diagnose.content?.clear()}</Markdown>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
        <Box mt={4} textAlign='center'>
          {diagnose.suggestedItems.length > 0
            ? diagnose.suggestedItems.map((x) => (
                <WorkItem>{t('WorkOnItem', { item: x.item.name })}</WorkItem>
              ))
            : t('ThereIsNoSuggestedItems')}
        </Box>
      </Box>
    );
  };

  if (
    diagnose.customizations &&
    (diagnose.customizations.primaryColor ||
      diagnose.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={diagnose.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default DiagnoseReport;
