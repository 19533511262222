import { hexToColorMatrix, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';

const ShareWithRelation = () => {
  const theme = useTheme();

  return (
    <svg
      width='103'
      height='103'
      viewBox='0 0 103 103'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        marginRight: theme.spacing(1),
        zIndex: 1
      }}
    >
      <g filter='url(#filter0_d_6256_1048)'>
        <circle
          cx='51.5'
          cy='47.5'
          r='36.5'
          fill='url(#paint0_linear_6256_1048)'
        />
      </g>
      <path
        d='M43.3167 49.5173L54.7 56.1507M54.6833 37.8507L43.3167 44.484M64 35.334C64 38.0954 61.7614 40.334 59 40.334C56.2386 40.334 54 38.0954 54 35.334C54 32.5726 56.2386 30.334 59 30.334C61.7614 30.334 64 32.5726 64 35.334ZM44 47.0007C44 49.7621 41.7614 52.0007 39 52.0007C36.2386 52.0007 34 49.7621 34 47.0007C34 44.2392 36.2386 42.0007 39 42.0007C41.7614 42.0007 44 44.2392 44 47.0007ZM64 58.6673C64 61.4287 61.7614 63.6673 59 63.6673C56.2386 63.6673 54 61.4287 54 58.6673C54 55.9059 56.2386 53.6673 59 53.6673C61.7614 53.6673 64 55.9059 64 58.6673Z'
        stroke='url(#paint1_linear_6256_1048)'
        strokeOpacity='0.8'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_d_6256_1048'
          x='0'
          y='0'
          width='103'
          height='103'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='7.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values={hexToColorMatrix(theme.palette.primary.main, 50)}
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_6256_1048'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_6256_1048'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_6256_1048'
          x1='49.6282'
          y1='178.058'
          x2='51.5'
          y2='7.72436'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={theme.palette.primary.main} stopOpacity='0.74' />
          <stop offset='1' stopColor={theme.palette.primary.main} />
        </linearGradient>
        <linearGradient
          id='paint1_linear_6256_1048'
          x1='48.5349'
          y1='50.2568'
          x2='50.8605'
          y2='-123.697'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop
            offset='1'
            stopColor={theme.palette.primary.main}
            stopOpacity='0.26'
          />
          <stop
            offset='1'
            stopColor={lightenHexColor(theme.palette.primary.main, 18)}
            stopOpacity='0.58116'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShareWithRelation;
