import { useTranslation } from '@/components';
import { UserRoles } from '@/enums';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type IProps = {
  currentPage: string;
  open: boolean;
  sideItems: {
    label: string;
    to: string;
    name: string;
    permission: UserRoles[];
    icon?: ReactNode;
  }[];
};

const Sidebar = ({ currentPage, open, sideItems }: IProps) => {
  const theme = useTheme();
  const t = useTranslation();

  return (
    <Drawer
      variant='permanent'
      anchor='left'
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.background.default,
          width: 260,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
          ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
              width: theme.spacing(9)
            }
          })
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'flex-end',
          flexDirection: 'column'
        }}
      >
        <Typography variant='h3' py={2} px={1}>
          {t('HiUser', { name: window.user.name })}
        </Typography>
        <Divider />
        <List>
          {sideItems
            .filter((x) => x.permission.any((y) => window.user.role === y))
            .map((x, i) => (
              <ListItem key={i}>
                <ListItemButton
                  component={RouterLink}
                  selected={currentPage === x.name}
                  to={x.to}
                >
                  {x.icon && <ListItemIcon>{x.icon}</ListItemIcon>}
                  <ListItemText primary={x.label} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
