import { Markdown } from '@/components';
import { fontWeights } from '@/helpers';
import { Box, Button, Typography } from '@mui/material';

interface IProps {
  projectName?: string;
  projectDescription: string;
  customizations?: CompanyCustomizations;
  onsubmit(): void;
}

const ProjectDetails = ({
  projectName,
  projectDescription,
  customizations,
  onsubmit
}: IProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
      bgcolor='white'
      px={{
        xs: 32 / 12,
        sm: 4
      }}
      py={4}
      my={{
        xs: 0,
        sm: 2
      }}
      mx={{
        xs: 1,
        md: 'calc((100vw - 800px) / 2)'
      }}
      borderRadius='24px'
    >
      {customizations?.logo && (
        <Box
          component='img'
          src={customizations?.logo}
          maxWidth='100px'
          mb={2}
        />
      )}
      <Typography variant='h3' fontSize={16} fontWeight={fontWeights.extraBold}>
        {projectName}
      </Typography>
      <Box mt={2} fontSize={14} fontWeight={fontWeights.light}>
        <Markdown children={projectDescription} />
      </Box>
      <Box textAlign='left'>
        <Button fullWidth variant='contained' onClick={onsubmit} sx={{ mt: 2 }}>
          Start
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
