import { LazyTypingStyles, useTranslation } from '@/components';
import { ReportTypes } from '@/enums';
import { classNamer, colorCalculator, fontWeights, webClient } from '@/helpers';
import {
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt
} from '@mui/icons-material';
import {
  Box,
  Button,
  Hidden,
  IconButton,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

interface IProps {
  code: string;
  reports: {
    title?: string;
    content: string;
  }[];
  tags?: {
    title: string;
    value?: number;
  }[];
  speed?: number;
  sx?: SxProps<Theme>;
  open: boolean;
  type: ReportTypes;
  color: 'primary' | 'yellow' | 'success' | 'dark';
  thumb: boolean | null;
  onOpened?: Action;
  onClosed?: Action;
  onThumb?: Action1<boolean | null>;
  disabled?: boolean;
  title: string;
}

const TagItem = styled(Box)(({ theme }) => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '10px',
  lineHeight: '15px',
  padding: '6px',
  fontSize: '14px',
  fontWeight: fontWeights.light,
  textAlign: 'center',
  '&:not(:first-of-type)': {
    marginTop: '8px'
  },
  [theme.breakpoints.up('md')]: {
    flex: 'calc(50% - 36px) 0 0',
    marginTop: '0 !important'
  },
  [theme.breakpoints.up('lg')]: {
    flex: 'calc(33% - 48px) 0 0'
  }
}));

const LazyTyping = ({
  open,
  thumb,
  code,
  type,
  sx,
  title,
  tags,
  reports,
  onOpened,
  onClosed,
  onThumb
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Johari');

  let elementRef!: React.RefObject<HTMLDivElement>;
  let submitTimeout: number | null = null;
  let resizeTimeout: number | null = null;

  const [isOpen, setIsOpen] = useState(open);
  const [hasReadMore, setHasReadMore] = useState(false);
  const [thumbUp, setThumbUp] = useState(thumb);

  const checkReadMore = () => {
    if (resizeTimeout !== null) {
      clearTimeout(resizeTimeout);
      resizeTimeout = null;
    }

    resizeTimeout = setTimeout(
      async () => {
        const element = elementRef.current;

        if (!element) {
          return;
        }

        element.style.overflow = 'hidden auto !important';
        const readMore = element.scrollHeight > element.clientHeight;

        element.style.removeProperty('overflow');

        if (hasReadMore === readMore) {
          return;
        }

        setHasReadMore(readMore);
      },
      250,
      []
    );
  };

  const scrollToTop = () => {
    const elem = elementRef.current;

    if (!elem) {
      return;
    }

    elem.scrollTo(0, 0);
  };

  const openFn = (internal: boolean) => {
    setIsOpen(true);
    if (!internal && onOpened) {
      onOpened();
    }
  };

  const close = (internal: boolean) => {
    setIsOpen(false);
    if (!internal && onClosed) {
      onClosed();
    }

    scrollToTop();
  };

  const submit = async () => {
    submitTimeout = null;

    await webClient.post(`/short-codes/${code}/vote`, {
      thumbUp: thumbUp,
      type: type
    });
  };

  const onThumbFn = async (thumb: boolean) => {
    if (submitTimeout !== null) {
      clearTimeout(submitTimeout);
      submitTimeout = null;
    }

    setThumbUp(thumbUp === thumb ? null : thumb);

    submitTimeout = setTimeout(submit, 4000, []);

    if (!onThumb) {
      return;
    }

    onThumb(null);
  };

  useEffect(() => {
    if (isOpen === true) {
      openFn(true);
    } else if (isOpen === false) {
      scrollToTop();
      close(true);
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', checkReadMore);
    checkReadMore();
  }, []);

  return (
    <>
      <Box
        ref={elementRef}
        sx={sx}
        display='flex'
        flexDirection='column'
        flex='auto 1 1'
        overflow={isOpen ? 'hidden auto' : 'hidden'}
        className={classNamer({
          [LazyTypingStyles.open]: isOpen
        })}
      >
        <Box
          bgcolor='white'
          p='45px 16px 18px'
          display='flex'
          flexDirection='column'
          borderRadius='5px'
          mb='12px'
          sx={{
            [theme.breakpoints.up('md')]: {
              p: '60px 24px 24px',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '12px 36px',
              justifyContent: 'center'
            },
            [theme.breakpoints.up('lg')]: {
              p: '60px 32px 32px',
              gap: '16px 48px'
            }
          }}
        >
          <Hidden mdUp>
            <Typography variant='h4' textAlign='center' my={1} width='100%'>
              {t(title)}
            </Typography>
          </Hidden>
          {tags?.map((tag, j) => (
            <TagItem
              key={tag.title}
              component='span'
              borderColor={
                typeof tag.value === 'undefined'
                  ? theme.palette.primary.main
                  : colorCalculator(tag.value)
              }
            >
              {tag.title}
            </TagItem>
          ))}
        </Box>
        <Box
          display='block'
          fontSize='14px'
          fontWeight={fontWeights.extraLight}
          color='black'
          px={1}
          sx={{
            [theme.breakpoints.up('md')]: {
              fontSize: '16px',
              px: 4
            }
          }}
        >
          {!tags || tags.length === 0 ? (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: reports[0].content.replace(/\n/g, '<br/>')
                }}
              ></p>
            </>
          ) : (
            reports.map((x, i) => (
              <>
                {!!x.title && <Typography variant='h2'>{x.title}</Typography>}
                <p
                  dangerouslySetInnerHTML={{
                    __html: x.content.replace(/\n/g, '<br/>')
                  }}
                />
              </>
            ))
          )}
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        mb={hasReadMore && isOpen ? 1 : 0}
      >
        <IconButton onClick={() => onThumbFn(false)}>
          {thumbUp === false ? (
            <ThumbDownAlt color='primary' />
          ) : (
            <ThumbDownOffAlt />
          )}
        </IconButton>
        <IconButton onClick={() => onThumbFn(true)} sx={{ mr: 'auto' }}>
          {thumbUp === true ? (
            <ThumbUpAlt color='primary' />
          ) : (
            <ThumbUpOffAlt />
          )}
        </IconButton>
        {hasReadMore && !isOpen && (
          <Button variant='text' color='primary' onClick={() => openFn(false)}>
            {t('ReadMore')}
          </Button>
        )}
        {hasReadMore && isOpen && (
          <Button variant='text' color='primary' onClick={() => close(false)}>
            {t('ReadLess')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default LazyTyping;
