import { addAlphaToHex, fontWeights } from '@/helpers';
import { Box, Typography, styled } from '@mui/material';

const GlassPanel = styled(Box)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: addAlphaToHex('#000', 3),
  boxShadow: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`,
  padding: `${theme.spacing(18 / 12)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  },
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(32 / 12)} ${theme.spacing(4)}`
  }
}));

const GlassPanelHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: fontWeights.semiBold,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20
  }
}));

const GlassPanelBody = styled(Box)(({ theme }) => ({
  marginTop: 8,
  fontSize: 14,
  fontWeight: fontWeights.extraLight,
  [theme.breakpoints.up('sm')]: {
    marginTop: 16,
    fontSize: 15
  },
  [theme.breakpoints.up('md')]: {
    marginTop: 32,
    fontSize: 16
  }
}));

export { GlassPanel, GlassPanelBody, GlassPanelHeader };
