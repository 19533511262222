import { fontWeights } from '@/helpers';
import { styled, Table } from '@mui/material';

const ReportItemTable = styled(Table)(({ theme }) => ({
  border: 0,
  '& th, & td': {
    borderBottom: 0,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  '& th': {
    fontWeight: fontWeights.bold,
    whiteSpace: 'nowrap',
    textAlign: 'center'
  }
}));

export default ReportItemTable;
