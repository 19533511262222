import { HollowCircle, useTranslation } from '@/components';
import useStateAsync from '@/extensions/use-state-async';
import { fontWeights, getCdnUrl } from '@/helpers';
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const textsRadius = 150;

interface IProps {
  width?: number;
  minimumNeed?: number;
  perfect?: number;
  maxRecordingTime?: number;
  reference?: (reference: { startOver(): void }) => void;
  onMicNotAvailableOrGranted?: () => void;
  onStartRecording?: () => void;
  onRecording?: (time: number) => void;
  onEndRecording?: (audio: Blob, time: number) => void;
}

const SoundRecorder = ({
  maxRecordingTime,
  minimumNeed,
  perfect,
  width,
  reference,
  onStartRecording,
  onRecording,
  onEndRecording,
  onMicNotAvailableOrGranted
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation();

  const [percent, setPercent, setPercentAsync] = useStateAsync(0);
  const [volume, setVolume] = useState(50);
  const [isMicAvailable, setIsMicAvailable] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const recordedDataRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext>();
  const analyserRef = useRef<AudioWorkletNode>();
  const microphoneRef = useRef<MediaStreamAudioSourceNode>();
  const recorderRef = useRef<MediaRecorder>();
  const [intervalId, setIntervalId] = useState<any>();
  const [recordedTime, setRecordedTime] = useState(0);

  const getMaxRecordingTime = () =>
    Math.max(recordedTime, maxRecordingTime || 18e4);
  const getMinimumNeed = () => minimumNeed || 6e4;
  const getPerfect = () => perfect || 15e4;
  const playShortAudio = async (src: string) => {
    const audioPlayer = new Audio(src);
    audioPlayer.addEventListener('ended', () => {
      try {
        audioPlayer.remove();
      } catch (exp) {}
    });
    await audioPlayer.play();
  };

  const openAudioStream = async () => {
    try {
      const audioContext = new AudioContext();
      await audioContext.audioWorklet.addModule('worklet.js');
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser = new AudioWorkletNode(audioContext, 'volume-processor');
      analyser.port.onmessage = (e) => {
        const v = e.data * 400;
        setVolume(v > 100 ? 100 : v);
      };
      microphone.connect(analyser);
      setIsMicAvailable(true);

      audioContextRef.current = audioContext;
      microphoneRef.current = microphone;
      analyserRef.current = analyser;
    } catch (exp) {
      if (onMicNotAvailableOrGranted) {
        onMicNotAvailableOrGranted();
      }
      console.log(exp);
    }
  };

  const getAudioBlob = () =>
    new Blob(recordedDataRef.current, {
      type: 'audio/wav'
    });

  const closeAudioStream = async () => {
    if (!audioContextRef || !microphoneRef.current || !analyserRef.current) {
      return;
    }

    await audioContextRef.current?.suspend();
    microphoneRef.current.disconnect(analyserRef.current);
    microphoneRef.current.mediaStream.getAudioTracks().forEach((x) => x.stop());
  };

  const getWidth = () => width || 662;
  const convert = (x: number) => Math.round(x * (getWidth() / 662) * 100) / 100;
  const progress = () => (volume / 100) * convert(171) + convert(150);

  const start = async () => {
    let p = percent;
    if (intervalId) {
      setVolume(50);
      setIsRecording(false);
      clearInterval(intervalId);
      setIntervalId(undefined);

      await playShortAudio(getCdnUrl('/sounds/recording-end.mp3'));
      recorderRef.current?.stop();
      closeAudioStream();
      if (onEndRecording) {
        onEndRecording(getAudioBlob(), recordedTime);
      }
      return;
    }

    if (onStartRecording) {
      onStartRecording();
    }

    setIsRecording(true);
    p = await startOver();
    await openAudioStream();
    if (microphoneRef.current) {
      const recorder = new MediaRecorder(microphoneRef.current.mediaStream);
      recorder.addEventListener('dataavailable', onDataAvailable);
      recorder.start(1000);

      recorderRef.current = recorder;
    }

    await playShortAudio(getCdnUrl('/sounds/recording-start.mp3'));
    setIntervalId(
      setInterval(async () => {
        if (p < 1) {
          setRecordedTime((x) => x + 100);
          setVolume(Math.random() * 100);
        } else {
          start();
        }
      }, 100)
    );
  };

  const onDataAvailable = (e: BlobEvent) =>
    recordedDataRef.current.push(e.data);

  const startOver = async () => {
    recordedDataRef.current = [];
    setRecordedTime(0);
    setVolume(50);
    return await setPercentAsync(0);
  };

  if (reference) {
    reference({
      startOver
    });
  }

  useEffect(() => {
    openAudioStream().then(closeAudioStream);
  }, []);

  useEffect(() => {
    setPercent(recordedTime / getMaxRecordingTime());

    if (onRecording) {
      onRecording(recordedTime);
    }
  }, [recordedTime]);

  return (
    <Box
      component='svg'
      sx={{
        '& > circle': {
          cx: getWidth() / 2,
          cy: getWidth() / 2
        }
      }}
      viewBox={`0 0 ${getWidth()} ${getWidth()}`}
    >
      <defs>
        <path
          id='curve'
          d={`M${getWidth() / 2},${getWidth() / 2}m${convert(
            textsRadius
          )},0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,-${
            convert(textsRadius) * 2
          },0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,${
            convert(textsRadius) * 2
          },0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,-${
            convert(textsRadius) * 2
          },0a${convert(textsRadius)},${convert(textsRadius)},0,1,0,${
            convert(textsRadius) * 2
          },0z`}
          fill='none'
          transform='scale(-1,1) rotate(270)'
          style={{
            transformOrigin: 'center'
          }}
        />
        <filter id='dropShadow1' width={convert(144)} height={convert(144)}>
          <feOffset in='SourceGraphic' dx={0} dy={4} />
          <feColorMatrix
            type='matrix'
            values='0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0'
          />
          <feGaussianBlur stdDeviation={4} />
          <feBlend in='SourceGraphic' in2='blurOut' />
        </filter>
        <filter id='dropShadow2' width={convert(112)} height={convert(112)}>
          <feOffset in='SourceGraphic' dx={0} dy={2} />
          <feColorMatrix
            type='matrix'
            values='0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0'
          />
          <feGaussianBlur stdDeviation={4} />
          <feBlend in='SourceGraphic' in2='blurOut' />
        </filter>
      </defs>
      <HollowCircle
        radius={getWidth() / 2}
        innerRadius={progress() + convert(6)}
        stroke='#BC3DF414'
        strokeOpacity={0.3}
      />
      <HollowCircle
        radius={progress() - convert(6)}
        innerRadius={convert(140)}
        stroke='#BC3DF414'
        strokeOpacity={0.4}
      />
      <HollowCircle
        radius={convert(140)}
        innerRadius={convert(108)}
        stroke='#F7F7F7'
        filter='url(#dropShadow1)'
      />
      <HollowCircle
        radius={convert(140)}
        innerRadius={convert(108)}
        stroke='#BC3DF4'
        progress={percent}
      />
      <circle
        r={convert(108)}
        fill='#fff'
        filter='url(#dropShadow2)'
        style={{
          cursor: 'pointer'
        }}
        onClick={start}
      />
      <g
        transform={`translate(${getWidth() / 2}, ${getWidth() / 2 + 5})`}
        onClick={start}
        style={{
          cursor: 'pointer'
        }}
      >
        {isRecording || recordedTime > 0 ? (
          <>
            {(!isRecording || recordedTime % 1000 < 500) && (
              <circle
                cx={convert(-30)}
                cy={convert(-5)}
                r={convert(11)}
                fill={
                  isRecording
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
              />
            )}
            <text
              x={convert(5)}
              y={convert(-5)}
              alignmentBaseline='middle'
              textAnchor='middle'
              transform={`translate(${convert(14)}, 0)`}
              fontWeight={fontWeights.medium}
              fontSize={convert(24)}
            >
              {Math.floor(recordedTime / 6e4).toFixedLength(2)}:
              {Math.floor((recordedTime / 1000) % 60).toFixedLength(2)}
            </text>
          </>
        ) : (
          !isRecording &&
          recordedTime === 0 && (
            <>
              <path
                d='M28.3146 48.6813C35.0292 48.6813 40.409 43.2452 40.409 36.5109L40.4494 12.1703C40.4494 5.43607 35.0292 0 28.3146 0C21.6 0 16.1798 5.43607 16.1798 12.1703V36.5109C16.1798 43.2452 21.6 48.6813 28.3146 48.6813ZM23.4607 11.7646C23.4607 9.08717 25.6449 6.89651 28.3146 6.89651C30.9843 6.89651 33.1685 9.08717 33.1685 11.7646L33.1281 36.9166C33.1281 39.5941 30.9843 41.7847 28.3146 41.7847C25.6449 41.7847 23.4607 39.5941 23.4607 36.9166V11.7646ZM49.7528 36.5109C49.7528 48.6813 39.4787 57.2005 28.3146 57.2005C17.1506 57.2005 6.8764 48.6813 6.8764 36.5109H0C0 50.3445 11.0022 61.7846 24.2697 63.7724V77.0787H32.3596V63.7724C45.627 61.8252 56.6292 50.3851 56.6292 36.5109H49.7528Z'
                fill='#BC3DF4'
                style={{
                  transform: 'translate(-28.5px, -69px)'
                }}
              />
            </>
          )
        )}
        <text
          textAnchor='middle'
          y={convert(50)}
          fontSize={24}
          fill={theme.palette.primary.main}
        >
          {t(isRecording ? '$Stop' : '$Start')}
        </text>
      </g>
      <text fontSize={24} fill={theme.palette.primary.main}>
        <textPath
          xlinkHref='#curve'
          textAnchor='end'
          startOffset={`${
            (getMinimumNeed() / getMaxRecordingTime()) * 50 + 50
          }%`}
        >
          Minimum need|
        </textPath>
      </text>
      <text fontSize={24} fill={theme.palette.primary.main}>
        <textPath
          xlinkHref='#curve'
          textAnchor='end'
          startOffset={`${(getPerfect() / getMaxRecordingTime()) * 50 + 50}%`}
        >
          Perfect|
        </textPath>
      </text>
    </Box>
  );
};

export default SoundRecorder;
