import { MultiLangComponent } from '@/components';
import { getCdnUrl, webClient } from '@/helpers';
import AppContext, { AppContextType } from '@contexts/app-context';
import {
  Delete,
  GraphicEq,
  Mic,
  MicOff,
  PlayCircle
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useParams } from 'react-router';

interface IParams {
  code: string;
}

interface IState {
  description: string;
  isMicAvailable: boolean;
  isAudioContent: boolean;
  isRecording: boolean;
  isPlaying: boolean;
  audioContent: { text: string; index: number }[];
}

class NextLevel extends MultiLangComponent<IParams, IState> {
  static contextType = AppContext;
  declare context: AppContextType;

  state: IState = {
    description: '',
    isMicAvailable: false,
    isAudioContent: false,
    isRecording: false,
    isPlaying: false,
    audioContent: []
  };

  private audioStream!: MediaStream;
  private recorder!: MediaRecorder;
  private recordedData: Blob[] = [];
  private audioPlayer!: HTMLAudioElement;
  private partIndex = 0;

  async componentDidMount() {
    super.componentDidMount();
    this.context.setMainClasses('main-flex');
    await this.openAudioStream();
    if (this.state.isMicAvailable) {
      this.closeAudioStream();
    }
  }

  private getAudioBlob() {
    return new Blob(this.recordedData, {
      type: 'audio/webm;codecs=opus'
    });
  }

  private async openAudioStream() {
    try {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      await this.setStateAsync({ isMicAvailable: true });
    } catch (exp) {
      console.log(exp);
    }
  }

  private closeAudioStream() {
    if (this.audioStream === null) {
      return;
    }

    this.audioStream.getTracks().forEach((x) => x.stop());
  }

  private async playShortAudio(src: string) {
    const audioPlayer = new Audio(src);
    audioPlayer.addEventListener('ended', () => {
      try {
        audioPlayer.remove();
      } catch (exp) {}
    });
    await audioPlayer.play();
  }

  private async startRecording() {
    await this.openAudioStream();
    this.recordedData = [];
    this.recorder = new MediaRecorder(this.audioStream);
    this.recorder.addEventListener(
      'dataavailable',
      this.onDataAvailable.bind(this)
    );
    this.recorder.start();
    await this.setStateAsync({
      isPlaying: false,
      isRecording: true,
      isAudioContent: false
    });
    await this.playShortAudio(getCdnUrl('/sounds/recording-start.mp3'));
  }

  private async onDataAvailable(e: BlobEvent) {
    const res = await webClient.post<{ text: string; index: number }>(
      '/chat/whisper/',
      {
        audio: e.data,
        index: this.partIndex++
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    await this.setStateAsync({
      audioContent: [...this.state.audioContent, res.data],
      isAudioContent: this.state.isRecording
    });

    await this.updateContent();

    setTimeout(
      async () =>
        this.setStateAsync({
          isAudioContent: this.state.isRecording
        }),
      500
    );
  }

  private updateContent() {
    return this.setStateAsync({
      description: this.state.audioContent
        .sort((a, b) => a.index - b.index)
        .map((x) => x.text)
        .join(' ')
    });
  }

  private async endRecording() {
    await this.playShortAudio(getCdnUrl('/sounds/recording-end.mp3'));
    this.recorder.stop();
    await this.setStateAsync({
      isAudioContent: true,
      isPlaying: false,
      isRecording: false
    });
    this.closeAudioStream();
  }

  private async playAudio() {
    if (!this.state.isAudioContent || this.state.isRecording) {
      return;
    }

    if (this.state.isPlaying) {
      return await this.stopAudio();
    }

    const audioUrl = URL.createObjectURL(this.getAudioBlob());
    this.audioPlayer = new Audio(audioUrl);

    await this.audioPlayer.play();
    this.audioPlayer.addEventListener('ended', this.stopAudio.bind(this));
    await this.setStateAsync({
      isPlaying: true
    });
  }

  private async stopAudio() {
    try {
      this.audioPlayer.pause();
      if (this.audioPlayer.fastSeek) {
        this.audioPlayer.fastSeek(0);
      }
      this.audioPlayer.remove();
      await this.setStateAsync({
        isPlaying: false
      });
    } catch (exp) {}
  }

  private async resetRecording() {
    this.recordedData = [];
    await this.setStateAsync({ isAudioContent: false });
  }

  async submit() {}

  render() {
    return (
      <Box>
        <Typography variant='body1'>
          Describe a pressing issue that you face.
        </Typography>
        <Typography variant='body1'>
          We do a confidential analysis of it's potential root cause based on
          your profile so you can improve.
        </Typography>
        <TextField
          multiline
          defaultValue={this.state.description}
          rows={4}
          onChange={async (x) =>
            this.setStateAsync({ description: x.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <ButtonGroup>
                  {this.state.isMicAvailable ? (
                    <>
                      {this.state.isAudioContent ? (
                        <>
                          <Tooltip title={this.t('Play')}>
                            <IconButton
                              edge='start'
                              onClick={this.playAudio.bind(this)}
                            >
                              <PlayCircle />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={this.t('Reset')}>
                            <IconButton
                              edge='start'
                              onClick={this.resetRecording.bind(this)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title={this.t('Record')}>
                          <IconButton
                            edge='start'
                            onTouchStart={this.startRecording.bind(this)}
                            onTouchEnd={this.endRecording.bind(this)}
                            onClick={
                              this.state.isRecording
                                ? this.endRecording.bind(this)
                                : this.startRecording.bind(this)
                            }
                          >
                            {this.state.isRecording ? <GraphicEq /> : <Mic />}
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Tooltip title={this.t('MicIsNotAvailable')}>
                      <span>
                        <IconButton edge='start'>
                          <MicOff />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </InputAdornment>
            )
          }}
        />
        <Button onClick={this.submit}>{this.t('Submit')}</Button>
      </Box>
    );
  }
}

export default function NextLevelFunction(props: MultiLangProps<{}>) {
  const params = useParams<keyof IParams>();
  return <NextLevel {...props} code={params.code!} />;
}
