import { Box, Typography } from '@mui/material';
import MarkdownToJsx, { MarkdownToJSX } from 'markdown-to-jsx';
import { Link } from 'react-router-dom';

interface IProps {
  children: string | undefined;
  options?: MarkdownToJSX.Options;
}

const defaultOptions: MarkdownToJSX.Options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h1',
        mb: 1,
        fontSize: {
          xs: '18px',
          sm: '20px'
        }
      }
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        mb: 1,
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        mb: 1
      }
    },
    h4: {
      component: Typography,
      props: {
        variant: 'h4',
        mb: 1
      }
    },
    h5: {
      component: Typography,
      props: {
        variant: 'h5',
        mb: 1
      }
    },
    h6: {
      component: Typography,
      props: {
        variant: 'h6',
        mb: 1
      }
    },
    p: {
      component: Typography,
      props: {
        mb: 1.15,
        lineHeight: '1.15',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    ul: {
      component: Box,
      props: {
        component: 'ul',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    li: {
      component: Box,
      props: {
        component: 'li',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    }
  },
  renderRule(next, node, renderChildren, state) {
    if (node.type === '15' && node.target.startsWith('/')) {
      return (
        <Link to={node.target}>{renderChildren(node.children, state)}</Link>
      );
    }

    return next();
  }
};

const Markdown = ({ children, options }: IProps) => {
  if (children && children.startsWith('"') && children.endsWith('"')) {
    children = children.substring(1, children.length - 1);
  }

  if (children && children.startsWith('```') && children.endsWith('```')) {
    children = children.substring(3, children.length - 3);
  }

  return (
    <MarkdownToJsx
      options={{ ...defaultOptions, ...options }}
      children={children || ''}
    />
  );
};

export default Markdown;
