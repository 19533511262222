import { useTranslation } from '@/components';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { StyledTable } from '.';

interface IProps {
  id: string;
  rows: TableOverallFamiliarityItemResponse[];
  familiarity: TopicFamiliarityResponse;
  items: TopicItemResponse[];
}

const TableOverallFamiliarity = ({ id, rows, familiarity, items }: IProps) => {
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          <TableCell component='th' width='1%'>
            {t('SelfRating')}
          </TableCell>
          {familiarity.options.map((x) => (
            <TableCell component='th' width='1%' key={`header_${x.id}`}>
              {x.title}
            </TableCell>
          ))}
          {hasBenchmark && (
            <TableCell
              component='th'
              width='1%'
              sx={{
                whiteSpace: {
                  sm: 'nowrap'
                }
              }}
            >
              {t('CohortBenchmark')}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((x, i) => {
          const data = rows.find((y) => y.itemId === x.id);

          return (
            <TableRow key={`${id}_row_${x.id}`}>
              <TableCell component='th'>{x.name}</TableCell>
              <TableCell component='th'>{data?.selfRating || '-'}</TableCell>
              {familiarity.options.map((y, j) => (
                <TableCell component='th' key={`${id}_row_${i}_col_${j}`}>
                  {data?.familiarityRatings[y.id].toFixedString(1, '-')}
                </TableCell>
              ))}
              {hasBenchmark && (
                <TableCell>
                  {rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)
                    .toFixedString(1, '-')}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default TableOverallFamiliarity;
