import { Idle } from '@/animations';
import { CircularProgress } from '@mui/material';
import React, { Component, Suspense } from 'react';

const LazyLottie = React.lazy(() => import('lottie-react'));

export default class Loading extends Component {
  render() {
    return (
      <Suspense fallback={<CircularProgress />}>
        <LazyLottie
          animationData={Idle}
          style={{ width: '128px', margin: '0 auto' }}
        />
      </Suspense>
    );
  }
}
