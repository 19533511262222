import { Checkbox } from '@/animations';
import { Markdown, useTranslation } from '@/components';
import { LocalStorageKeys, TopicReportPolicy } from '@/enums';
import { checkEmailValidity, fontWeights } from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type IProps = {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
};

const SurveyStart = ({ project, topic }: IProps) => {
  const { showToast } = useAppContext();
  const t = useTranslation('StartSurvey');
  const navigate = useNavigate();

  const [name, setName] = useState(
    localStorage.getItem(LocalStorageKeys.USER_NAME) || ''
  );
  const [nameError, setNameError] = useState<FormError>(false);
  const [email, setEmail] = useState(
    localStorage.getItem(LocalStorageKeys.USER_EMAIL) || ''
  );
  const [emailError, setEmailError] = useState<FormError>(false);
  const [confirm, setConfirm] = useState(
    localStorage.getItem(LocalStorageKeys.USER_EMAIL) || ''
  );
  const [confirmError, setConfirmError] = useState<FormError>(false);
  const [grantAccess, setGrantAccess] = useState(false);
  const [seniority, setSeniority] = useState('');
  const [seniorityTouched, setSeniorityTouched] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    switch (e.target.name) {
      case 'name':
        setNameError(false);
        setName(value);
        break;

      case 'email':
        setEmailError(false);
        setConfirmError(false);
        setEmail(value);
        break;

      case 'confirm':
        setEmailError(false);
        setConfirmError(false);
        setConfirm(value);
        break;
    }
  };

  const isNextDisabled = () =>
    (!!project &&
      'reportPolicy' in topic &&
      topic.reportPolicy === TopicReportPolicy.PRIVATE &&
      !grantAccess) ||
    ('seniorities' in topic && topic.seniorities?.any() && !seniority) ||
    name.trim().length < 2 ||
    !checkEmailValidity(email) ||
    confirm !== email;

  const submit = () => {
    if (name.trim() === '') {
      setNameError(t('$NameError'));
    }

    if (email.trim() === '') {
      setEmailError(t('$EmailError'));
    }

    if (confirm.trim() === '') {
      setConfirmError(t('$EmailError'));
    }

    if (email.trim() !== '' && !checkEmailValidity(email)) {
      setEmailError(t('$ErrorInvalidEmail'));
    }

    if (confirm !== email) {
      setConfirmError(t('$ErrorInvalidConfirm'));
    }

    if (
      'seniorities' in topic &&
      topic.seniorities?.any() &&
      !topic.seniorities.any((x) => x.id === seniority)
    ) {
      return;
    }

    if (!!nameError || !!emailError || !!confirmError) {
      return;
    }

    if (project) {
      localStorage.setItem(LocalStorageKeys.SURVEY_PROJECT, project);
    } else {
      localStorage.removeItem(LocalStorageKeys.SURVEY_PROJECT);
    }

    localStorage.setItem(LocalStorageKeys.USER_NAME, name);
    localStorage.setItem(LocalStorageKeys.USER_EMAIL, email);

    if ('seniorities' in topic && topic.seniorities?.any()) {
      localStorage.setItem(LocalStorageKeys.SURVEY_SENIORITY, seniority);
    } else {
      localStorage.removeItem(LocalStorageKeys.SURVEY_SENIORITY);
    }

    localStorage.setItem(
      LocalStorageKeys.SURVEY_SHARE,
      grantAccess ? 'true' : 'false'
    );
    navigate('/survey/feedback/', {
      replace: true,
      state: topic
    });
  };

  const preventCopy = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    await showToast(t('$CantCopy'), 2e3, 'info');
  };

  useEffect(() => {
    if (email.trim() === '') {
      return;
    }

    if (confirm.trim() === '') {
      return;
    }

    if (email === confirm) {
      return;
    }

    setConfirmError(t('$ErrorInvalidConfirm'));
  }, [email, confirm]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      boxShadow={{
        xs: 'none',
        sm: '0 0 10px 2px rgba(0, 0, 0, 0.1)'
      }}
      bgcolor={{
        xs: 'none',
        sm: 'white'
      }}
      px={{
        xs: 0,
        sm: 4
      }}
      py={4}
      my={{
        xs: 0,
        sm: 2
      }}
      mx={{
        xs: 1,
        sm: 'calc((100vw - 500px) / 2)'
      }}
      borderRadius='24px'
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: fontWeights.semiBold,
          color: '#414042'
        }}
      >
        {t('SecurelyCreateYour').toTsx()}
      </Typography>
      <InputLabel sx={{ mt: 2 }} htmlFor='name' error={nameError !== false}>
        {t('$Name')}
      </InputLabel>
      <TextField
        error={nameError !== false}
        id='name'
        fullWidth
        type='text'
        placeholder={t('$EnterYourName')}
        defaultValue={name}
        onChange={handleChange}
        name='name'
        required
      />
      <FormHelperText error={nameError !== false}>
        {nameError || ' '}
      </FormHelperText>
      <InputLabel sx={{ mt: 0 }} htmlFor='email' error={emailError !== false}>
        {t('$Email')}
      </InputLabel>
      <TextField
        error={emailError !== false}
        id='email'
        fullWidth
        type='text'
        onCopy={preventCopy}
        onContextMenu={(e) => e.preventDefault()}
        placeholder={t('$EnterYourEmail')}
        defaultValue={email}
        onChange={handleChange}
        name='email'
        required
      />
      <FormHelperText error={emailError !== false}>
        {emailError || ' '}
      </FormHelperText>
      <InputLabel
        sx={{ mt: 0 }}
        htmlFor='confirm'
        error={confirmError !== false}
      >
        {t('$EmailConfirm')}
      </InputLabel>
      <TextField
        error={confirmError !== false}
        id='confirm'
        fullWidth
        type='text'
        autoComplete='false'
        placeholder={t('$EnterYourEmail')}
        defaultValue={confirm}
        onChange={handleChange}
        name='confirm'
        required
      />
      <FormHelperText error={confirmError !== false}>
        {confirmError || ' '}
      </FormHelperText>
      {'seniorities' in topic && topic.seniorities?.any() && (
        <>
          <InputLabel
            sx={{ mt: 0 }}
            htmlFor='seniority'
            error={seniorityTouched && seniority === ''}
          >
            {t('Seniority')}
          </InputLabel>
          <Select
            value={seniority}
            name='seniority'
            onChange={(e) => setSeniority(e.target.value as string)}
            fullWidth
            id='seniority'
            required
            error={seniorityTouched && seniority === ''}
            onBlur={() => setSeniorityTouched(true)}
          >
            {topic.seniorities.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={seniorityTouched && seniority === ''}>
            {seniorityTouched && seniority === '' && t('SeniorityIsRequired')}
          </FormHelperText>
        </>
      )}
      {project && (
        <Box
          component='label'
          onClick={() => setGrantAccess(!grantAccess)}
          display='flex'
          flexDirection='row'
          alignItems='center'
          fontSize={12}
        >
          <Checkbox checked={grantAccess} width='32px' />
          <Box>{t('TheOrganizerAccess')}</Box>
        </Box>
      )}
      <Typography mt={0.5} variant='body1' fontSize={12}>
        <Markdown>{t('ByClickingStart')}</Markdown>
      </Typography>
      <Button
        sx={{
          mt: 32 / 12
        }}
        type='button'
        onClick={submit}
        disabled={isNextDisabled()}
        variant='contained'
      >
        {t('$Start')}
      </Button>
    </Box>
  );
};

export default SurveyStart;
