import { Redirect, useTranslation } from '@/components';
import { Authenticate } from '@/components/authenticate';
import { UserRoles } from '@/enums';
import { clearAuthCookie } from '@/helpers';
import { CodeRouter, NotFound, Terms } from '@/pages';
import Privacy from '@/pages/privacy';
import { useAppContext } from '@contexts/app-context';
import DiagnoseDone from '@diagnose/done';
import DiagnoseReport from '@diagnose/report';
import DiagnoseStart from '@diagnose/survey';
import { Login, Person } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import SurveyChart from '@survey/chart';
import SurveyFeedback from '@survey/feedback';
import SurveyIndex from '@survey/index';
import SurveyNotAvailable from '@survey/not-available';
import SurveyNotFound from '@survey/not-found';
import SurveyReport from '@survey/report/survey-report';
import TeamReport from '@survey/report/team-report';
import SurveyShare from '@survey/share';
import SurveyThanks from '@survey/thanks';
import { Route, Routes, useNavigate } from 'react-router';
import SurveyReportGrantAccess from './survey/grant-access';

const Layout = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { setHeader, openProfile, openProfileState } = useAppContext();

  const onAuthorized = () => {
    let tools = [
      <Tooltip title={t('Login')} key={'loginButton'}>
        <IconButton onClick={() => navigate('/login')}>
          <Login />
        </IconButton>
      </Tooltip>
    ];

    if (window.user.role === UserRoles.User) {
      tools = [
        <Tooltip title={window.user.name} key={'profileButton'}>
          <IconButton
            onClick={openProfile}
            aria-controls={openProfileState ? 'profile' : undefined}
            aria-haspopup='true'
            aria-expanded={openProfileState ? 'true' : undefined}
          >
            <Person />
          </IconButton>
        </Tooltip>
      ];
    }

    if (
      [UserRoles.Admin, UserRoles.SuperAdmin].indexOf(window.user.role) > -1
    ) {
      tools = [
        <Tooltip title={t('Admin')} key={'adminButton'}>
          <IconButton onClick={() => navigate('/admin')}>
            <Person />
          </IconButton>
        </Tooltip>
      ];
    }

    setHeader('/', undefined, tools);
  };

  return (
    <>
      <Routes>
        <Route
          path='/diagnose/survey/:surveyId'
          index
          element={<Authenticate component={<DiagnoseStart />} />}
        />
        <Route
          path='/diagnose/survey/:surveyId/done'
          index
          element={<Authenticate component={<DiagnoseDone />} />}
        />
        <Route
          path='/sign-out'
          index
          element={
            <Authenticate
              component={
                <Redirect to='/' beforeRedirect={() => clearAuthCookie()} />
              }
              unauthorized={<Redirect to='/' />}
            />
          }
        />
        <Route
          path='/diagnose/:diagnoseId'
          index
          element={<Authenticate component={<DiagnoseReport />} />}
        />
        <Route path='/reports/survey/:id' index element={<SurveyReport />} />
        <Route
          path='/reports/project/:projectId/team/:teamId'
          element={<TeamReport />}
        />
        <Route
          path='/survey/:id/grantAccess'
          element={<Authenticate component={<SurveyReportGrantAccess />} />}
        />
        <Route path='/survey/start' element={<SurveyIndex />} />
        <Route path='/survey/feedback' element={<SurveyFeedback />} />
        <Route path='/survey/share' element={<SurveyShare />} />
        <Route path='/survey/not-available' element={<SurveyNotAvailable />} />
        <Route path='/survey/not-found' element={<SurveyNotFound />} />
        <Route path='/survey/:id' element={<SurveyChart />} />
        <Route path='/feedback/thanks' element={<SurveyThanks />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/:code' element={<CodeRouter />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Layout;
