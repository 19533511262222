import { useTranslation } from '@/components';
import { addAlphaToHex, fontWeights } from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const SettingsPanel = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 24,
  boxShadow: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`,
  padding: theme.spacing(4),
  margin: '0 calc((100% - 402px) / 2)',
  [theme.breakpoints.down('sm')]: {
    margin: 0
    // backgroundColor: 'transparent',
    // borderRadius: 0,
    // boxShadow: 'none',
    // padding: `${theme.spacing(4)} ${theme.spacing(32 / 12)}`
  }
}));

const settingsPages = [
  // {
  //   label: 'Email',
  //   to: '/email/'
  // },
  // {
  //   label: 'PhoneNumber',
  //   to: '/phone/'
  // },
  {
    label: 'PasswordManagement',
    to: '/password/'
  },
  {
    label: 'GetACopyOfYourData',
    to: '/export/'
  },
  {
    label: 'DeleteAccount',
    to: '/delete/'
  }
  // {
  //   label: 'ProfileInfo',
  //   to: '/info/'
  // },
  // {
  //   label: 'Plan',
  //   to: '/plan/'
  // }
];

const Settings = () => {
  const { setCurrentPage, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace.Settings');
  const theme = useTheme();

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('settings', 'Settings');
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      mx={{
        xs: 0,
        sm: 'calc((100% - 400px) / 2)'
      }}
      pb={1}
    >
      <Typography variant='h3' fontSize='20px' fontWeight={fontWeights.bold}>
        {t('AccountAccess')}
      </Typography>
      <Box
        p={4}
        mt={2}
        bgcolor='white'
        borderRadius='24px'
        boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
      >
        <List disablePadding>
          {settingsPages.map((x, i) => (
            <ListItem
              key={i}
              disablePadding
              sx={{
                borderBottom: 'solid 1px #D9D9D9',
                '&:not(:last-of-type) .MuiListItemButton-root': {
                  py: 1
                }
              }}
            >
              <ListItemButton
                sx={{
                  px: 0
                }}
                component={Link}
                to={`/user/settings${x.to}`}
              >
                <ListItemText
                  sx={{
                    fontSize: '16px',
                    fontWeight: fontWeights.light
                  }}
                >
                  {t(x.label)}
                </ListItemText>
                <ListItemIcon
                  sx={{
                    minWidth: '0 !important'
                  }}
                >
                  <ArrowForward fontSize='small' htmlColor='#AFAFAF' />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export { SettingsPanel };

export {
  AuthButtonBack as SettingsButtonBack,
  AuthPanelHeader as SettingsPanelHeader
} from '@auth/styleds';

export { default as SettingsDelete } from '@user/settings/delete';
export { default as SettingsExport } from '@user/settings/export';
export { default as SettingsInfo } from '@user/settings/info';
export { default as SettingsPassword } from '@user/settings/password';
export { default as SettingsPlan } from '@user/settings/plan';

export default Settings;
