import { createContext, useContext } from 'react';

const AppContext = createContext<IAppContext>({} as any);
const useAppContext = () => useContext(AppContext);

export { useAppContext };

export type AppContextType = React.ContextType<typeof AppContext>;

export default AppContext;
