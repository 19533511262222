import { useTranslation } from '@/components';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import { createRef, useEffect, useRef, useState } from 'react';

type IProps = {
  label?: string;
  value?: unknown;
  name: string;
  placeholder?: string;
  required?: boolean;
  parameters?: (IParameter | string)[];
  onChange: SimpleChangeEvent;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
};

interface IParameterWithValue {
  label: string;
  value: string;
  disabled?: boolean;
}
type IParameter =
  | IParameterWithValue
  | {
      label: string;
      children: IParameterWithValue[];
      disabled?: boolean;
    };

const Parameter = ({
  label,
  disabled,
  addVariable,
  ...props
}: IParameter & { addVariable(variable: string): void }) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  if ('value' in props) {
    return (
      <Button
        key={props.value || label}
        disabled={disabled}
        onClick={() => addVariable(props.value || label)}
        sx={{
          fontSize: 12
        }}
      >
        {label}
      </Button>
    );
  }

  return (
    <>
      <Button
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='menu'
        onClick={() => setOpen(!open)}
        ref={anchorRef}
        endIcon={<ArrowDropDown />}
        disabled={disabled}
      >
        {label}
      </Button>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {props.children.map((x, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => addVariable(x.value || x.label)}
                    >
                      {x.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
const Parameters = ({
  parameters,
  addVariable
}: {
  parameters: IParameter[];
  addVariable(variable: string): void;
}) => (
  <>
    {parameters.map((x, i) => (
      <Parameter {...x} addVariable={addVariable} key={i} />
    ))}
  </>
);

const PromptMaker = ({ required, label, name, onBlur, ...props }: IProps) => {
  const t = useTranslation();

  //#region Functions

  const getCursorPosition = () => {
    const el = promptRef.current;
    if (!el) {
      return {
        start: 0,
        end: 0
      };
    }

    return {
      start: el.selectionStart,
      end: el.selectionEnd
    };
  };

  const setCursorPosition = (start: number, end?: number) => {
    const input = promptRef.current;
    if (!input) {
      return;
    }

    setTimeout(function () {
      input.selectionStart = start;
      input.selectionEnd = end || start;
    }, 1);
  };

  const addVariable = (variable: string) => {
    const input = promptRef.current;
    if (!input) {
      return;
    }

    const cursor = getCursorPosition();
    const value = input.value;
    const strLeft = value.substring(0, cursor.start);
    const strRight = value.substring(cursor.end);
    setCursorPosition(cursor.start + variable.length + 2);
    input.focus();

    setValue(strLeft + `{${variable}}` + strRight);
  };

  const parseParameters = (
    parameters: (string | IParameter)[]
  ): IParameter[] => {
    let res: IParameter[] = [];
    for (let i = 0; i < parameters.length; i++) {
      let item = parameters[i];
      if (typeof item === 'string') {
        const disabled = item.startsWith('-');
        if (disabled) {
          item = item.substring(1);
        }
        res.push({
          label: item,
          value: item,
          disabled
        });
      } else {
        res.push(item);
      }
    }

    return res;
  };

  const setValue = (value: string) => {
    props.onChange({
      target: {
        name,
        value
      }
    });
  };

  const handleChange: SimpleChangeEvent = (e) => {
    setValue(e.target.value);
  };

  //#endregion Functions

  useEffect(() => {
    setValue((props.value as string | undefined) || '');
  }, []);

  const promptRef = createRef<HTMLTextAreaElement>();
  const parameters = props.parameters ? parseParameters(props.parameters) : [];

  return (
    <>
      {label && <InputLabel sx={{ mt: 1 }}>{label}</InputLabel>}
      {parameters && parameters.length > 0 && (
        <ButtonGroup sx={{ mb: 1 }}>
          <Typography lineHeight={2.2} mr={1}>
            {t('$Variables')}:
          </Typography>
          <Parameters parameters={parameters} addVariable={addVariable} />
        </ButtonGroup>
      )}
      <TextField
        fullWidth
        type='text'
        multiline
        inputRef={promptRef}
        placeholder={props.placeholder || 'Write a prompt for report generator'}
        value={props.value}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        rows={2}
      />
    </>
  );
};

export default PromptMaker;
