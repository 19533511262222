import { useTranslation } from '@/components';
import { addAlphaToHex, fontWeights } from '@/helpers';
import { Box, Typography, useTheme } from '@mui/material';

const SectionCover = ({
  title,
  isReport,
  cover,
  icon
}: {
  title: string;
  isReport?: boolean;
  cover: string;
  icon?: string;
}) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  return (
    <Box
      position='relative'
      height={{
        xs: '100vw',
        sm: 'calc(100vw / (1236 / 804))'
      }}
      sx={
        isReport
          ? undefined
          : {
              breakInside: 'avoid',
              '@media print': {
                height: '100vh'
              }
            }
      }
    >
      <Box
        position='absolute'
        right={0}
        top={0}
        bottom={0}
        minWidth={182}
        width='60%'
        bgcolor={addAlphaToHex(theme.palette.secondary.light, 60)}
        //bgcolor={addAlphaToHex('#ECE9EF', 60)}
      />
      <Box
        position='absolute'
        top={{
          xs: 263,
          sm: '54%'
        }}
        bgcolor={addAlphaToHex(theme.palette.secondary.main, 50)}
        //bgcolor='#DFD9E3'
        left={0}
        minWidth={181}
        width='40%'
        height={{
          xs: 68,
          sm: '20%'
        }}
      />
      {isReport && (
        <Box
          position='absolute'
          bgcolor='#DFD9E3'
          right={0}
          top={{
            xs: 178,
            sm: '56%'
          }}
          minWidth={181}
          width='60%'
          height={{
            xs: 5,
            sm: 10
          }}
        />
      )}
      <Box
        component='img'
        src={cover}
        position='absolute'
        left='50%'
        width={{ xs: '90%', sm: '53%' }}
        top={{
          xs: 118,
          sm: '12%'
        }}
        sx={{
          transform: 'translateX(-50%)'
        }}
      />
      {icon && (
        <Box
          component='img'
          src={icon}
          position='absolute'
          right='0'
          width={{ xs: '30%', sm: '12%' }}
          top={{
            xs: 'calc(75% + 30px)',
            sm: '40%'
          }}
          sx={{
            transform: 'translateX(-50%)'
          }}
        />
      )}
      {isReport && (
        <Typography
          position='absolute'
          variant='body1'
          fontSize={{
            xs: 24,
            sm: 'calc((100vw / 1440) * 40)'
          }}
          width={{
            xs: undefined,
            sm: 'calc((100vw / 1440) * 200)'
          }}
          left={{
            xs: 27,
            sm: '5%'
          }}
          top={{
            xs: 32,
            sm: '20%'
          }}
          fontWeight={fontWeights.bold}
          sx={{
            background: `linear-gradient(to left, ${addAlphaToHex(
              theme.palette.primary.main,
              50
            )}, ${addAlphaToHex(theme.palette.primary.main, 70)})`,
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          {t('360FeedbackReport')}
        </Typography>
      )}
      <Box
        position='absolute'
        width={{
          sm: 'calc((100vw / 1440) * 570)'
        }}
        height={{
          sm: 'calc((100vw / 1440) * 330)'
        }}
        left={{
          xs: 27,
          sm: '49%'
        }}
        top={{
          xs: 70,
          sm: '63%'
        }}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='body1'
          fontSize={{
            xs: 16,
            sm: 'calc((100vw / 1440) * 48)'
          }}
          fontWeight={fontWeights.regular}
          sx={{
            background: {
              xs: '#000',
              sm: `linear-gradient(to left, ${addAlphaToHex(
                '#fff',
                70
              )} -25%, ${addAlphaToHex(theme.palette.primary.main, 70)})`
            },
            backgroundClip: 'text !important',
            WebkitTextFillColor: 'transparent'
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default SectionCover;
