import { Loading, useTranslation } from '@/components';
import { fontWeights, getQueryString, webClient } from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Empty, ReportCard } from '@userRoot/components';
import { useEffect, useState } from 'react';
import { ReportCardGrid } from '../../components/report-card';

const Diagnoses = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace');

  const [analyses, setAnalyses] = useState<DiagnoseResponse[]>([]);
  const [invites, setInvites] = useState<SurveyResponse[]>([]);
  const [analysesTotalCount, setAnalysesTotalCount] = useState(0);
  const [invitesTotalCount, setInvitesTotalCount] = useState(0);
  const [loading, setLoading] = useState<number>(3);

  const survey = getQueryString('survey');

  const loadMoreAnalyses = async () => {
    setLoading((x) => ((1 & x) !== 0 ? x : x | 1));
    try {
      const res = await webClient.api.diagnoses.list(
        analyses.length === 0 ? undefined : analyses[analyses.length - 1].id,
        survey,
        {
          loadingHandled: true
        }
      );

      setAnalysesTotalCount((x) => Math.max(x, res.data.totalCount));
      setAnalyses((x) => [...x, ...res.data.data]);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    } finally {
      setLoading((x) => x & ~1);
    }
  };

  const loadMoreInvites = async () => {
    setLoading((x) => ((2 & x) !== 0 ? x : x | 2));
    try {
      const res = await webClient.api.surveys.list(
        {
          hasDiagnose: true,
          completed: true,
          afterId:
            invites.length === 0 ? undefined : invites[invites.length - 1].id
        },
        {
          loadingHandled: true
        }
      );

      setInvitesTotalCount((x) => Math.max(x, res.data.totalCount));
      setInvites((x) => [...x, ...res.data.data]);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    } finally {
      setLoading((x) => x & ~2);
    }
  };

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('diagnoses', 'Diagnoses');
    loadMoreAnalyses();
    loadMoreInvites();
    // eslint-disable-next-line
  }, []);

  if (
    loading &&
    (!analyses || analyses.length === 0) &&
    (!invites || invites.length === 0)
  ) {
    return <Loading />;
  }

  if (
    (analyses && analyses.length > 0) ||
    (invites &&
      invites.filter((x) => (survey ? x.id === survey : true)).length > 0)
  ) {
    return (
      <>
        {analyses && analyses.length > 0 && (
          <>
            <Typography
              fontSize={14}
              fontWeight={fontWeights.medium}
              fontStyle='italic'
              mb={16 / 12}
              ml={16 / 12}
            >
              {t('ArchiveOfAnalysisDone')}
            </Typography>
            <Grid container spacing={16 / 12} p={16 / 12}>
              {analyses.map((x, i) => (
                <ReportCardGrid key={i}>
                  <ReportCard
                    to={`/diagnose/${x.id}/`}
                    buttonText='SeeAnalysis'
                    title={x.createdAt.asDateTime('dd MMMM yyyy')}
                    children={x.issue
                      .clear()
                      .split(' ')
                      .splice(0, 15)
                      .join(' ')}
                  />
                </ReportCardGrid>
              ))}
            </Grid>
            {(loading & 1) !== 0 && <Loading />}
            {analyses.length < analysesTotalCount && (
              <Box textAlign='center'>
                <Button
                  variant='contained'
                  type='button'
                  disabled={(loading & 1) !== 0}
                  onClick={loadMoreAnalyses}
                >
                  {loading ? <CircularProgress /> : t('$LoadMore')}
                </Button>
              </Box>
            )}
          </>
        )}
        {invites &&
          invites.filter((x) => (survey ? x.id === survey : true)).length >
            0 && (
            <>
              <Typography
                fontSize={14}
                fontWeight={fontWeights.medium}
                fontStyle='italic'
                mt={
                  analyses && analyses.length > 0
                    ? {
                        xs: 2,
                        sm: 4,
                        md: 6
                      }
                    : undefined
                }
                mb={16 / 12}
                ml={16 / 12}
              >
                {t('YourPersonal100facetsAssistant')}
              </Typography>
              <Grid container spacing={16 / 12} p={16 / 12}>
                {invites
                  .filter((x) => (survey ? x.id === survey : true))
                  .map((x, i) => (
                    <ReportCardGrid key={i}>
                      <ReportCard
                        to={`/diagnose/survey/${x.id}/`}
                        buttonText='Access'
                        title={x.createdAt.asDateTime('dd MMMM yyyy')}
                        children={t('UseYourAIAssistant', {
                          name: x.name
                        })}
                      />
                    </ReportCardGrid>
                  ))}
              </Grid>
              {(loading & 2) !== 0 && <Loading />}
              {invites.length < invitesTotalCount && (
                <Box textAlign='center'>
                  <Button
                    variant='contained'
                    type='button'
                    disabled={(loading & 2) !== 0}
                    onClick={loadMoreInvites}
                  >
                    {loading ? <CircularProgress /> : t('$LoadMore')}
                  </Button>
                </Box>
              )}
            </>
          )}
      </>
    );
  }

  return <Empty title={t('ThereAreNoAnalysis')} />;
};

export default Diagnoses;
