import { AppBar, Toolbar, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type IProps = {
  title: string;
  subtitle: ReactNode;
  openDrawer: boolean;
};

const AdminHeader = ({ title, subtitle, openDrawer }: IProps) => {
  const theme = useTheme();

  return (
    <AppBar
      position='absolute'
      color='default'
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        ...(openDrawer && {
          marginLeft: 260,
          width: `calc(100% - 260px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          })
        })
      }}
    >
      <Toolbar
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center'
        }}
      >
        <Typography variant='h2'>{title}</Typography>
        {subtitle}
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;
