HTMLElement.prototype.getTotalOffsetTop = function (this: HTMLElement) {
  let total = this.offsetTop;
  let parent = this.parentElement;
  while (parent !== null) {
    total += parent.offsetTop;
    parent = parent.parentElement;
  }

  return total;
};

export {};
