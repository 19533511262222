import { Box, styled } from '@mui/material';

const Page = styled(Box)(({ theme }) => ({
  margin: '12px auto',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 12,
  padding: theme.spacing(3)
}));

export default Page;
