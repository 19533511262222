import { useTranslation } from '@/components';
import SoundRecorder from '@/components/sound-recorder';
import { RectifyType } from '@/enums';
import { addAlphaToHex, fontWeights, webClient } from '@/helpers';
import WriterSvg from '@/images/writer';
import { Keyboard, Mic, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useState } from 'react';

interface IProps {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  itemId: string;
  optionId: string;
  topicId?: string;
  isFeedback: boolean;
  name?: string;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
}

const dataMinLength = 20;

const OpenEnded = ({
  submit,
  itemId,
  optionId,
  code,
  project,
  topic,
  topicId,
  isFeedback,
  name
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Feedback.OpenEnded');

  let soundRecorder: {
    startOver(): void;
  } = {
    startOver() {}
  };

  const [inputType, setInputType] = useState<('text' | 'voice') | undefined>();
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [data, setData] = useState<Blob | string | undefined>();
  const [result, setResult] = useState<
    RectifyVoiceResponse | RectifyResponse
  >();
  const [recordedTime, setRecordedTime] = useState(0);
  const [rectifierHistory, setRectifierHistory] = useState<string[]>([]);
  const [lastSummary, setLastSummary] = useState<string>();
  const [showMakeBetter, setShowMakeBetter] = useState(false);

  const getMinimumNeed = () => {
    if (!!lastSummary) {
      return 1e4;
    }

    return 6e4;
  };

  const getPerfectNeed = () => {
    if (!!lastSummary) {
      return 6e4;
    }

    return 15e4;
  };

  const stringData = () =>
    !data || typeof data !== 'string' ? '' : (data as string).trim();
  const blobData = () => data as Blob;

  const submitResult = async () => {
    if (!result || !result.canSubmit) {
      return;
    }

    await submit([
      {
        itemId,
        optionId,
        value: result.content
      }
    ]);
  };

  const getHeadline = () => {
    if (isFeedback) {
      if (topic.othersHeadline) {
        return topic.othersHeadline.replace('{name}', name!);
      }

      return t('HowDoYouSeeIn', {
        name,
        topic: topic.name
      }).toTsx();
    }

    if (topic.selfHeadline) {
      return topic.selfHeadline;
    }

    return t('HowDoYouSeeIn', {
      name: t('$Feedback.Yourself'),
      topic: topic.name
    }).toTsx();
  };

  const rectify = async () => {
    setIsAudioRecording(false);

    const reference = topicId || code || project;
    if (!reference) {
      alert('Reference not found!\nPlease refresh the page!');
      return;
    }

    if (!data) {
      return;
    }

    const res =
      inputType! === 'voice'
        ? await webClient.api.tools.rectifierVoice(blobData(), {
            type: RectifyType.SURVEY_RESPONSE,
            reference,
            history: rectifierHistory.map((x) => ({
              role: 'user',
              content: x
            }))
          })
        : await webClient.api.tools.rectifier({
            input: stringData(),
            type: RectifyType.SURVEY_RESPONSE,
            reference,
            history: rectifierHistory.map((x) => ({
              role: 'user',
              content: x
            }))
          });

    setResult(res.data.data);
    setRectifierHistory((x) => [
      ...x,
      ...[
        'input' in res.data.data
          ? (res.data.data.input as string)
          : stringData()
      ]
    ]);
    setLastSummary(res.data.data.content);
  };

  const renderLastSummary = () => (
    <Box
      boxShadow={`0 0 10px 2px ${addAlphaToHex(
        theme.palette.primary.main,
        25
      )}`}
      borderRadius='24px'
      py={4}
      px={2}
      bgcolor='white'
      flex={{
        md: '50% 0 0',
        lg: '60% 0 0'
      }}
      maxWidth={{
        xs: '90%',
        sm: '70%',
        md: '50%',
        lg: '60%'
      }}
      width={{
        xs: '90%',
        sm: '70%',
        md: '50%',
        lg: '60%'
      }}
      sx={{
        overflowY: 'auto'
      }}
      maxHeight='300px'
      mt={{
        xs: 3,
        md: -8
      }}
    >
      {!!lastSummary ? (
        <>
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight={fontWeights.medium}
            mb={16 / 12}
          >
            {t('ThisIsYourLastSummary')}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={fontWeights.extraLight}
            fontStyle='italic'
            component='pre'
            whiteSpace='normal'
          >
            <Markdown>{lastSummary}</Markdown>
          </Typography>
        </>
      ) : (
        <Markdown>{topic.items[0].name.clear()}</Markdown>
      )}
    </Box>
  );

  if (result) {
    return (
      <>
        <Dialog open={showMakeBetter} onClose={() => setShowMakeBetter(false)}>
          <DialogTitle>{t('$Diagnose.HowToContinue')}</DialogTitle>
          <DialogContent>
            <Button
              fullWidth
              onClick={() => {
                setShowMakeBetter(false);
                setResult(undefined);
              }}
            >
              {t('$Diagnose.ImproveCurrentVersion')}
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setShowMakeBetter(false);
                setResult(undefined);
                setRectifierHistory([]);
                setLastSummary(undefined);
              }}
            >
              {t('$Diagnose.MakeNewVersionFromScratch')}
            </Button>
          </DialogContent>
        </Dialog>
        <Box
          borderRadius={{
            xs: `calc((100vw - ${theme.spacing(
              2
            )}) / 2) calc((100vw - ${theme.spacing(2)}) / 2) 24px 24px`,
            sm: '300px 300px 24px 24px'
          }}
          boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
          mx={{
            xs: 1,
            sm: 'calc((100% - 600px) / 2)'
          }}
          display='flex'
          flexDirection='column'
          py={6}
          px={32 / 12}
        >
          <WriterSvg />
          <Typography
            variant='body1'
            fontSize={20}
            fontWeight={fontWeights.semiBold}
            color='#414042'
            mt={5}
          >
            {t('ThisIsSummaryOfWhatYouShared')}
          </Typography>
          <Typography
            variant='body1'
            fontSize={18}
            fontWeight={fontWeights.extraLight}
            fontStyle='italic'
            color='#414042'
            mt={32 / 12}
          >
            <Markdown>{result.content.clear()}</Markdown>
          </Typography>
          <Box mt={2} alignSelf='center'>
            <Button
              type='button'
              variant='contained'
              color='primary'
              disabled={!result.canSubmit}
              onClick={submitResult}
            >
              {t('$Submit')}
            </Button>
            <Button
              type='button'
              variant='outlined'
              color='primary'
              onClick={() => setShowMakeBetter(true)}
              sx={{
                ml: 16 / 12
              }}
            >
              {t('$MakeItBetter')}
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  if (inputType) {
    return (
      <Box
        mx={{
          xl: 'calc((100% - 1440px) / 2)'
        }}
      >
        <Typography fontSize={27} fontWeight={fontWeights.medium}>
          {t('BeSpecificTryToGiveExamples')}
        </Typography>
        <Box
          display='flex'
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
          alignItems={{
            xs: 'center',
            md: 'flex-end'
          }}
        >
          <Hidden mdUp>{renderLastSummary()}</Hidden>
          <Box
            flex={{
              md: '50% 0 0',
              lg: '40% 0 0'
            }}
            maxWidth={{
              md: '50%',
              lg: '40%'
            }}
            width={{
              xs: '90%',
              sm: '70%',
              md: '50%',
              lg: '40%'
            }}
          >
            <Select
              sx={{
                mt: 4,
                zIndex: 1
              }}
              value={inputType}
              onChange={(e) => setInputType(e.target.value as 'text' | 'voice')}
            >
              <MenuItem value={'voice'}>
                <Box
                  display='flex'
                  alignItems='center'
                  pr={4}
                  color={theme.palette.primary.main}
                >
                  <Mic sx={{ mr: 0.2 }} />
                  {t('Talk')}
                </Box>
              </MenuItem>
              <MenuItem value={'text'}>
                <Box
                  display='flex'
                  alignItems='center'
                  pr={4}
                  color={theme.palette.primary.main}
                >
                  <Keyboard sx={{ mr: 0.2 }} />
                  {t('Write')}
                </Box>
              </MenuItem>
            </Select>
            {inputType === 'voice' ? (
              <Box>
                <SoundRecorder
                  reference={(x) => (soundRecorder = x)}
                  onMicNotAvailableOrGranted={() => setInputType('text')}
                  onStartRecording={() => setIsAudioRecording(true)}
                  onRecording={(time) => setRecordedTime(time)}
                  onEndRecording={(data, time) => {
                    setIsAudioRecording(false);
                    setData(data);
                    setRecordedTime(time);
                  }}
                  minimumNeed={getMinimumNeed()}
                  perfect={getPerfectNeed()}
                />
                <Typography
                  textAlign='center'
                  mt={-8}
                  fontSize={20}
                  fontWeight={fontWeights.regular}
                  color={theme.palette.primary.main}
                >
                  <Markdown>
                    {recordedTime < 2e3
                      ? ''
                      : recordedTime < 1e4
                      ? t('GoodStartKeepItUp')
                      : recordedTime < 6e4
                      ? t('TalkForAtleast', { sec: 60 })
                      : recordedTime < 18e4
                      ? t('MoreDetailsMoreValue')
                      : t('PerfectClickSendToProceed')}
                  </Markdown>
                </Typography>
                <Box textAlign='center' mt={3} mb={-4}>
                  <Button
                    variant='contained'
                    type='button'
                    sx={{
                      mr: 2
                    }}
                    onClick={rectify}
                    disabled={
                      !data ||
                      isAudioRecording ||
                      recordedTime < getMinimumNeed()
                    }
                  >
                    {t('$Send')}
                  </Button>
                  <Button
                    variant='contained'
                    type='button'
                    disabled={recordedTime === 0 || isAudioRecording}
                    onClick={() => soundRecorder?.startOver()}
                    sx={{
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none',
                        color: 'white'
                      }
                    }}
                  >
                    {t('$Delete')}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                flex='auto 1 1'
                mt={12}
                mr={{
                  md: 10
                }}
                bgcolor='white'
                border='solid 1px rgba(188,19,19,0.2)'
                borderRadius='24px'
                p={{
                  xs: 16 / 12,
                  sm: 32 / 12
                }}
                display='flex'
                flexDirection='column'
                position='relative'
              >
                <Box
                  position='absolute'
                  top='16px'
                  right='16px'
                  zIndex={1}
                  color={theme.palette.primary.main}
                >
                  <Box
                    component='span'
                    color={
                      stringData().length < dataMinLength
                        ? '#EC4849'
                        : '#32EFBE'
                    }
                  >
                    {stringData().length}
                  </Box>
                  <Box component='span'>/{dataMinLength}</Box>
                </Box>
                <TextField
                  type='text'
                  name='comment'
                  multiline
                  rows={3}
                  value={data}
                  placeholder={`Write at least ${dataMinLength} characters`}
                  onChange={(e) => setData(e.target.value)}
                  sx={{
                    '& fieldset': {
                      border: 'none',
                      outline: 'none'
                    }
                  }}
                />
                <IconButton
                  sx={{
                    mt: 1,
                    alignSelf: 'flex-end'
                  }}
                  type='button'
                  onClick={rectify}
                  disabled={stringData().length < dataMinLength}
                >
                  <Send fontSize='large' />
                </IconButton>
              </Box>
            )}
          </Box>
          <Hidden mdDown>{renderLastSummary()}</Hidden>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      mx={{
        sm: 'calc((100% - 540px) / 2)'
      }}
    >
      <Typography fontSize={27} fontWeight={fontWeights.bold}>
        {getHeadline()}
      </Typography>
      <Box
        mt={32 / 12}
        borderRadius='24px'
        boxShadow={`0 0 10px 2px ${addAlphaToHex(
          theme.palette.primary.main,
          10
        )}`}
        p={4}
        bgcolor='white'
      >
        <Markdown>{topic.items[0].name.clear()}</Markdown>
      </Box>
      <Typography mt={4} fontSize={27} fontWeight={fontWeights.bold}>
        {t('ChooseTheOptionToTypeOrTalk')}
      </Typography>
      <Typography fontSize={24} fontWeight={fontWeights.light}>
        {t('BeSpecificTryToGiveExamples')}
      </Typography>
      <Box
        mt={40 / 12}
        sx={{
          '&>button': {
            bgcolor: 'white',
            borderRadius: '24px',
            width: '133px',
            height: '133px',
            display: 'inline-flex',
            flexDirection: 'column',
            fontSize: '24px',
            boxShadow: `0 2px 4px ${addAlphaToHex('#000', 25)}`,
            '&>svg': {
              fontSize: '38px',
              mb: 8 / 12
            }
          }
        }}
      >
        <Button
          type='button'
          onClick={() => setInputType('voice')}
          sx={{
            mr: 6
          }}
        >
          <Mic />
          {t('Talk')}
        </Button>
        <Button type='button' onClick={() => setInputType('text')}>
          <Keyboard />
          {t('Write')}
        </Button>
      </Box>
      <Typography fontSize={14} fontWeight={fontWeights.light} mt={64 / 12}>
        <Markdown>{t('YourInputsWillBeParaphrased')}</Markdown>
      </Typography>
    </Box>
  );
};

export default OpenEnded;
