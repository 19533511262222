import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';

const LazyPlot = React.lazy(() => import('react-plotly.js'));

type IProps =
  | ChartResponse
  | {
      data: ChartTrace[];
      layout: ChartLayout;
    };

const LazyPlotly = ({ data, layout }: IProps) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <LazyPlot
        data={data}
        layout={{
          ...layout,
          colorway: ['#71F79F', '#FF686B', '#FBB13C', '#344966', '#76BED0']
        }}
        config={{
          responsive: true,
          editable: false,
          displayModeBar: false,
          setBackground: 'opaque'
        }}
      />
    </Suspense>
  );
};

export default LazyPlotly;
