import { useTranslation } from '@/components';
import { addAlphaToHex, centerOf, getCdnUrl, randPerm } from '@/helpers';
import { Box, useTheme } from '@mui/material';
import { AIReport, Contents, ReportItem } from '@survey/report/components';
import SectionCover from './components/section-cover';
import TableReportHeader from './components/table-report-header';

const TeamReportTable = ({
  reports,
  aiReports,
  items,
  options,
  summary,
  date,
  title,
  customizations
}: TeamReportResponse) => {
  const theme = useTheme();

  reports = reports.map((x) => ({
    ...x,
    item: items.find((y) => y.id === x.itemId)!,
    option: options.find((y) => y.id === x.optionId)!
  }));

  const t = useTranslation('Reports.Table');

  const coverImage = (name: string) =>
    getCdnUrl(`/images/reports/chapters/${name}.jpg`);
  const commentImage = (index: number) =>
    getCdnUrl(`/images/reports/comments/${index % 4 || 4}.jpg`);

  const coverImages = randPerm(1, 61, items.length).map((x) =>
    coverImage(x.toString())
  );

  return (
    <Box
      className='report'
      bgcolor='white'
      mx={{
        xs: 0,
        sm: 1,
        md: 2
      }}
      borderRadius='24px'
    >
      <Box
        pb={2}
        borderRadius='24px'
        bgcolor={addAlphaToHex('#000', 2)}
        overflow='hidden'
        sx={{
          overflow: 'hidden',
          '@media print': {
            px: 0,
            '& td, & th': {
              p: '8px',
              fontSize: '14px'
            }
          }
        }}
        className='report'
      >
        <Box
          sx={{
            '@media print': {
              height: '100vh'
            }
          }}
        >
          <TableReportHeader />
          <SectionCover
            isReport
            title={title}
            cover={getCdnUrl('/images/reports/hero.png')}
            icon={customizations?.logo}
          />
        </Box>
        <Contents hasSummary={!!summary} hasComments={false} items={items} />
        {summary && (
          <Box>
            <SectionCover
              title={t('ExecutiveSummary')}
              cover={getCdnUrl('/images/reports/executive-summary-h.jpg')}
            />
            <Box
              p={{
                xs: 8 / 12,
                sm: centerOf(8 / 12, 4),
                md: 4
              }}
            >
              <AIReport content={summary} />
            </Box>
          </Box>
        )}
        {items.map((x, i) => (
          <ReportItem
            key={i}
            {...x}
            index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
            options={options}
            aiReports={aiReports}
            cover={coverImages[i]}
          />
        ))}
      </Box>
      <Box />
    </Box>
  );
};

export default TeamReportTable;
