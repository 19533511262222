import { Checkbox } from '@/animations';
import { Markdown, useTranslation } from '@/components';
import { Theme, checkEmailValidity, fontWeights, webClient } from '@/helpers';
import {
  AuthButtonBack,
  AuthPanel,
  AuthPanelHeader,
  AuthSocialButton
} from '@auth/styleds';
import { useAppContext } from '@contexts/app-context';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

enum Steps {
  Email = 1,
  Password = 2
}

const SignUp = () => {
  const { setMainClasses, setError } = useAppContext();
  const navigate = useNavigate();
  const t = useTranslation('Authentication.SignUp');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<FormError>(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<FormError>(false);
  const [confirm, setConfirm] = useState('');
  const [confirmError, setConfirmError] = useState<FormError>(false);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password'
  );
  const [step, setStep] = useState<Steps>(Steps.Email);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value);
        setEmailError(false);
        break;

      case 'password':
        setPassword(e.target.value);
        setPasswordError(false);
        break;

      case 'confirm':
        setConfirm(e.target.value);
        setConfirmError(false);
        break;

      default:
        throw new Error('Invalid form');
    }
  };

  const nextStep = () => {
    setConfirmError(false);
    setEmailError(false);
    setPasswordError(false);

    let hasError = false;
    if (!checkEmailValidity(email)) {
      hasError = true;
      setEmailError('$Authentication.InvalidEmail');
    }

    if (!terms) {
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setStep(Steps.Password);
  };

  const getPasswordHas8Characters = () => password.trim().length > 7;
  const getPasswordHas8CharactersColor = () =>
    getPasswordHas8Characters()
      ? Theme.palette.success.main
      : passwordError !== false
      ? Theme.palette.error.main
      : 'black';
  const getPasswordHasUpperCase = () => /[A-Z]/g.test(password);
  const getPasswordHasUpperCaseColor = () =>
    getPasswordHasUpperCase()
      ? Theme.palette.success.main
      : passwordError !== false
      ? Theme.palette.error.main
      : 'black';

  const getPasswordHasLowerCase = () => /[a-z]/g.test(password);
  const getPasswordHasLowerCaseColor = () =>
    getPasswordHasLowerCase()
      ? Theme.palette.success.main
      : passwordError !== false
      ? Theme.palette.error.main
      : 'black';

  const getPasswordHasNumber = () => /[0-9]/g.test(password);
  const getPasswordHasNumberColor = () =>
    getPasswordHasNumber()
      ? Theme.palette.success.main
      : passwordError !== false
      ? Theme.palette.error.main
      : 'black';

  const getPasswordHasSpecialCharacter = () => /[^A-Za-z0-9\s]/g.test(password);
  const getPasswordHasSpecialCharacterColor = () =>
    getPasswordHasSpecialCharacter()
      ? Theme.palette.success.main
      : passwordError !== false
      ? Theme.palette.error.main
      : 'black';

  const register = async () => {
    setConfirmError(false);
    setEmailError(false);
    setPasswordError(false);

    let hasError = false;
    if (!checkEmailValidity(email)) {
      hasError = true;
      setEmailError('$Authentication.InvalidEmail');
    }

    const pass = password.trim();

    if (
      pass.length < 8 ||
      !/[A-Z]/g.test(pass) ||
      !/[a-z]/g.test(pass) ||
      !/[0-9]/g.test(pass) ||
      !/[^a-zA-Z0-9\s]/g.test(pass)
    ) {
      hasError = true;
      setPasswordError('has error');
    }

    if (confirm !== pass) {
      hasError = true;
      setConfirmError('$Authentication.ConfirmNotMatched');
    }

    if (hasError) {
      return;
    }

    setLoading(true);

    try {
      await webClient.api.auth.basic.register({
        email,
        password
      });
      if (window.hasReturnUrl) {
        navigate(
          `/register/thanks/?email=${email}&returnUrl=${window.returnUrlEncoded}`,
          {
            replace: true
          }
        );
      } else {
        navigate(`/register/thanks/?email=${email}`, {
          replace: true
        });
      }
    } catch (exp: any) {
      const exception = exp as AxiosError;
      setLoading(false);
      if (exception.response?.status === 400) {
        setEmailError('EmailExists');
        setStep(Steps.Email);
      } else {
        await setError(`${(exp as AxiosError).response?.status || 500}`);
      }
    }
  };

  const togglePassword = () =>
    setPasswordType(passwordType === 'password' ? 'text' : 'password');

  useEffect(() => {
    setMainClasses();
  }, []);

  if (step === 2) {
    return (
      <AuthPanel>
        <AuthButtonBack onClick={() => setStep(Steps.Email)} />
        <AuthPanelHeader variant='h2'>{t('CreateAnAccount')}</AuthPanelHeader>
        <InputLabel sx={{ mt: 3 }} htmlFor='signup_password'>
          {t('$Authentication.Password')}
        </InputLabel>
        <OutlinedInput
          error={passwordError !== false}
          type={passwordType}
          fullWidth
          defaultValue={password}
          placeholder={t('$Authentication.PasswordHint')}
          name='password'
          id='signup_password'
          key='signup_password'
          onChange={handleChange}
          autoComplete='off'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          <Typography
            variant='caption'
            color={getPasswordHas8CharactersColor()}
            sx={{ display: 'inline-block' }}
          >
            {t('$Authentication.StrongPassword.PlusXCharacters', {
              x: 8
            })}
          </Typography>
          ,
          <Typography
            variant='caption'
            color={getPasswordHasUpperCaseColor()}
            sx={{ display: 'inline-block' }}
          >
            &nbsp;{t('$Authentication.StrongPassword.UpperCase')}
          </Typography>
          ,
          <Typography
            variant='caption'
            color={getPasswordHasLowerCaseColor()}
            sx={{ display: 'inline-block' }}
          >
            &nbsp;{t('$Authentication.StrongPassword.LowerCase')}
          </Typography>
          ,
          <Typography
            variant='caption'
            color={getPasswordHasNumberColor()}
            sx={{ display: 'inline-block' }}
          >
            &nbsp;{t('$Authentication.StrongPassword.Number')}
          </Typography>
          ,
          <Typography
            variant='caption'
            color={getPasswordHasSpecialCharacterColor()}
            sx={{ display: 'inline-block' }}
          >
            &nbsp;{t('$Authentication.StrongPassword.SpecialCharacter')}
          </Typography>
          .
        </FormHelperText>
        <InputLabel sx={{ mt: 1 }} htmlFor='signup_confirm'>
          {t('$Authentication.ConfirmPassword')}
        </InputLabel>
        <OutlinedInput
          error={confirmError !== false}
          type={passwordType}
          fullWidth
          defaultValue={confirm}
          placeholder={t('$Authentication.ConfirmPasswordHint')}
          name='confirm'
          id='signup_confirm'
          key='signup_confirm'
          onChange={handleChange}
          autoComplete='off'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={togglePassword}>
                {passwordType === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error={confirmError !== false}>
          {confirmError !== false ? t(confirmError) : ''}
        </FormHelperText>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: '20px' }}
          variant='contained'
          disabled={
            !getPasswordHas8Characters() ||
            !getPasswordHasLowerCase() ||
            !getPasswordHasNumber() ||
            !getPasswordHasSpecialCharacter() ||
            !getPasswordHasUpperCase() ||
            password !== confirm
          }
          onClick={register}
        >
          {loading ? (
            <CircularProgress color='inherit' />
          ) : (
            t('$Authentication.Register')
          )}
        </Button>
      </AuthPanel>
    );
  }

  return (
    <AuthPanel>
      <AuthButtonBack
        to={
          window.hasReturnUrl
            ? `/login/?returnUrl=${window.returnUrlEncoded}`
            : '/login/'
        }
      />
      <AuthPanelHeader variant='h2'>{t('CreateAnAccount')}</AuthPanelHeader>
      <InputLabel sx={{ mt: 3 }} htmlFor='signup_email'>
        {t('$Authentication.Email')}
      </InputLabel>
      <OutlinedInput
        error={emailError !== false}
        type='email'
        fullWidth
        defaultValue={email}
        placeholder={t('$Authentication.EmailHint')}
        name='email'
        id='signup_email'
        key='signup_email'
        onChange={handleChange}
      />
      <FormHelperText error={emailError !== false}>
        {emailError !== false ? t(emailError) : ''}
      </FormHelperText>
      <FormControlLabel
        sx={{
          pl: 1,
          mt: 1,
          alignItems: 'start',
          fontSize: {
            xs: 12,
            sm: 14
          }
        }}
        onClick={() => setTerms((x) => !x)}
        control={<Checkbox checked={terms} width='20px' />}
        label={<Markdown>{t('YouAgreeToTheTerms')}</Markdown>}
      />
      <Button
        fullWidth
        sx={{ mt: 2, fontSize: '20px' }}
        variant='contained'
        disabled={!terms || !email || !checkEmailValidity(email)}
        onClick={nextStep}
      >
        {t('$Next')}
      </Button>
      <Typography
        variant='h3'
        mt={4}
        fontSize={18}
        fontWeight={fontWeights.semiBold}
      >
        {t('$Authentication.OrContinueWith')}
      </Typography>
      <Grid container columnSpacing={5} rowSpacing={0} mt={32 / 12}>
        <Grid item xs={4}>
          <AuthSocialButton
            fullWidth
            color='secondary'
            href={`${process.env.REACT_APP_API}/v1/auth/google/${document.location.search}`}
            startIcon={<img src='/assets/images/google.svg' alt='Google' />}
          >
            Google
          </AuthSocialButton>
        </Grid>
      </Grid>
    </AuthPanel>
  );
};

export default SignUp;
