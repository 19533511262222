import { useTranslation } from '@/components';
import { VerificationTimeoutCodes } from '@/enums';
import {
  fontWeights,
  getVerificationTimeout,
  setVerificationTimeout,
  webClient
} from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import {
  SettingsButtonBack,
  SettingsPanel,
  SettingsPanelHeader
} from '@user/settings';
import { useEffect, useState } from 'react';

enum Steps {
  Request = 'Request',
  Verify = 'Verify'
}

const SettingsExport = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace.Settings');

  const [requested, setRequested] = useState(false);
  const [step, setStep] = useState(Steps.Request);
  const [remainingTime, setRemainingTime] = useState(60);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState<FormError>(false);
  const [loading, setLoading] = useState(false);

  let timeoutId: any;

  const getExpireTime = () => {
    return (
      getVerificationTimeout(VerificationTimeoutCodes.UserSettingsExport) || 0
    );
  };
  const getTimeout = () =>
    getExpireTime() - Math.round(new Date().getTime() / 1000);
  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  const startTimer = () => {
    clearTimer();

    timeoutId = setInterval(() => {
      setRemainingTime(getTimeout());

      if (getTimeout() < 0) {
        clearTimer();
      }
    }, 1000);
  };

  const resend = async () => {
    try {
      await webClient.api.archives.request({
        loadingHandled: true
      });
      setVerificationTimeout(VerificationTimeoutCodes.UserSettingsExport, 60);
      startTimer();
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    }
  };

  const submit = async () => {
    if (step === Steps.Request) {
      setRequested(true);

      try {
        await webClient.api.archives.request({
          loadingHandled: true
        });
        setVerificationTimeout(VerificationTimeoutCodes.UserSettingsExport, 60);
        startTimer();
        setStep(Steps.Verify);
        setRequested(false);
      } catch (exp) {
        showToast('Error!', 3e3, 'error');
      }

      return;
    }

    await webClient.api.archives.confirm(code);
  };

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('settings', 'Export');
    // eslint-disable-next-line
  }, []);

  if (step === Steps.Verify) {
    return (
      <SettingsPanel>
        <SettingsButtonBack onClick={() => setStep(Steps.Request)} />
        <SettingsPanelHeader variant='h3'>
          {t('ExportYourData')}
        </SettingsPanelHeader>
        <Typography fontSize={14} fontWeight={fontWeights.regular} mt={16 / 12}>
          {t('EnterTheXDigitVerificationCode', {
            digits: '6-digit',
            email: window.user.email
          })}
        </Typography>
        <InputLabel sx={{ mt: 3 }} htmlFor='recover_code'>
          {t('$Code')}
        </InputLabel>
        <OutlinedInput
          error={codeError !== false}
          type='text'
          fullWidth
          defaultValue={code}
          placeholder={t('EnterCode')}
          name='code'
          id='recover_code'
          key='recover_code'
          onChange={(e) => {
            setCode(e.target.value);
            setCodeError(false);
          }}
        />
        <FormHelperText error={codeError !== false}>
          {codeError !== false ? codeError : ''}
        </FormHelperText>
        <Button
          fullWidth
          sx={{ mt: 2, fontSize: 18 }}
          variant='contained'
          disabled={loading || code.trim().length < 5}
          onClick={submit}
        >
          {loading ? <CircularProgress color='inherit' /> : t('$Download')}
        </Button>
        <Box fontSize={14} mt={1}>
          {t('$Authentication.DidntGetTheCode')}
          <Button
            variant='text'
            onClick={resend}
            disabled={remainingTime > 0}
            sx={{ p: 0, fontSize: 'inherit' }}
          >
            {t('$Authentication.Resend')}
          </Button>
          {remainingTime > 0 &&
            t('$Authentication.ResendItIn', {
              time:
                remainingTime > 1
                  ? t('$XSeconds', {
                      x: remainingTime
                    })
                  : t('$1Second')
            })}
        </Box>
      </SettingsPanel>
    );
  }

  return (
    <SettingsPanel>
      <SettingsButtonBack to='/user/settings/' />
      <SettingsPanelHeader variant='h3'>
        {t('ExportYourData')}
      </SettingsPanelHeader>
      <Typography fontSize={14} fontWeight={fontWeights.regular} mt={16 / 12}>
        {t('YourPrivacyIsOurPriority')}
      </Typography>
      <Typography fontSize={12} fontWeight={fontWeights.regular} mt={32 / 12}>
        {t('YourDataBelongsToYou')}
      </Typography>
      <Button
        variant='contained'
        type='button'
        disabled={requested}
        onClick={submit}
        fullWidth
        sx={{
          mt: 2
        }}
      >
        {requested ? (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            component='span'
          >
            <CircularProgress
              color='inherit'
              size={24}
              sx={{
                mr: 1
              }}
            />
            {t('Archiving')}
          </Box>
        ) : (
          t('RequestArchive')
        )}
      </Button>
      {requested && (
        <Typography fontSize={14} fontWeight={fontWeights.light} mt={8 / 12}>
          {t('YourDataWillBeReady')}
        </Typography>
      )}
    </SettingsPanel>
  );
};

export default SettingsExport;
