import { useTranslation } from '@/components';
import { useAppContext } from '@contexts/app-context';
import { useEffect } from 'react';

const NotFound = () => {
  const { setMainClasses } = useAppContext();
  const t = useTranslation();

  useEffect(() => {
    setMainClasses('main-flex');
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <h1>404 Not Found</h1>
      <button type='button' onClick={() => window.history.back()}>
        {t('Back')}
      </button>
    </>
  );
};

export default NotFound;
