import { LazyPlotly, Markdown } from '@/components';
import { addAlphaToHex, fontWeights, getCdnUrl } from '@/helpers';
import { Box, Typography, useTheme } from '@mui/material';

interface IProps {
  title?: string;
  content: string;
  charts?: ChartResponse[];
}

const AIReport = ({ title, content, charts }: IProps) => {
  const theme = useTheme();

  return (
    <Box minHeight={charts && charts.any() ? '600px' : undefined}>
      {title && (
        <Typography
          variant='h3'
          color={theme.palette.primary.main}
          fontSize={{
            xs: 18,
            sm: 24,
            md: 32
          }}
          fontWeight={fontWeights.bold}
          mb={{
            xs: 16 / 12,
            sm: 4
          }}
        >
          {title}
        </Typography>
      )}
      {charts && charts.any() && (
        <Box
          sx={{
            '&>div': {
              maxWidth: '450px',
              width: '450px',
              height: '450px',
              float: 'right',
              zIndex: 2,
              borderRadius: '24px',
              overflow: 'hidden',
              border: `solid 1px ${addAlphaToHex(
                theme.palette.primary.main,
                50
              )}`,
              boxShadow: `0 4px 4px ${addAlphaToHex('#000', 25)}`,
              [theme.breakpoints.down('md')]: {
                float: 'none',
                maxWidth: '100%',
                width: '100%',
                mb: 2
              }
            }
          }}
        >
          <LazyPlotly data={charts[0].data} layout={charts[0].layout} />
        </Box>
      )}
      <Box
        borderLeft={{
          xs: 'none',
          sm: `solid 4px ${theme.palette.secondary.main}`
        }}
        pl={{
          xs: 0,
          sm: 2,
          md: 4
        }}
        sx={{
          backgroundImage: {
            xs: 'none',
            md: `url('${getCdnUrl('/images/reports/ai-report-bg.svg')}')`
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundSize: '25vw auto'
        }}
      >
        <Box
          maxWidth={{
            md: 'calc(100% - 25vw)'
          }}
          fontSize={{
            xs: '16px',
            sm: '17px',
            md: '18px'
          }}
        >
          <Markdown>{content.clear()}</Markdown>
        </Box>
      </Box>
    </Box>
  );
};

export default AIReport;
