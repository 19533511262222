import { useTranslation } from '@/components';
import { addAlphaToHex, classNamer, fontWeights, webClient } from '@/helpers';
import { Bell03, BellRinging03, CheckCircleBroken } from '@/icons';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const TimeLine = styled(List)(({ theme }) => ({
  '& > li.clickable:not(.disabled) > :last-child:hover': {
    backgroundColor: 'white',
    boxShadow: `0 0 20px 0 ${addAlphaToHex(theme.palette.primary.main, 20)}`
  },
  '& > li.last > :last-child': {
    boxShadow: `none`,
    border: `solid 1px ${theme.palette.primary.main}`
  },
  '& > li > :last-child': {
    backgroundColor: addAlphaToHex('#FFF', 50),
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    height: '70px',
    boxShadow: `0 0 20px 0 ${addAlphaToHex('#000', 10)}`,
    textDecoration: 'none',
    color: 'black',
    flex: 'auto 1 1'
  },
  '&>li:not(:last-child)>div:first-child:after': {
    content: "''",
    width: 1,
    height: 70,
    borderLeft: 'dashed 1px #D9D9D9',
    position: 'absolute',
    left: 'calc(50% - 1px)',
    top: '100%',
    zIndex: 1
  },
  '&>li.last ~ li': {
    color: '#D9D9D9',
    '& .MuiListItemIcon-root svg': {
      color: '#D9D9D9 !important'
    }
  },
  '& .MuiListItemIcon-root': {
    width: '32px',
    minWidth: '32px'
  }
}));

const TimeLineIndex = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  width: 38,
  lineHeight: '38px',
  fontSize: 20,
  fontWeight: fontWeights.semiBold,
  textAlign: 'center',
  borderRadius: '50%',
  boxShadow: `0 0 20px 0 ${addAlphaToHex('#000', 10)}`,
  marginRight: '16px',
  position: 'relative',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    width: 32,
    lineHeight: '32px'
  }
}));

interface IToDoItem {
  to?: string;
  step: number;
  currentStep: number;
  children: ReactNode;
}

const ToDoItem = ({ to, step, currentStep, children }: IToDoItem) => {
  const theme = useTheme();

  return (
    <ListItem
      className={classNamer({
        last: step === currentStep,
        disabled: step > currentStep,
        clickable: !!to
      })}
      sx={{
        p: 0,
        mb: {
          xs: 16 / 12,
          sm: 2,
          md: 32 / 12
        }
      }}
    >
      <TimeLineIndex children={step} />
      <Box
        {...(to &&
          step <= currentStep && {
            component: Link,
            to
          })}
      >
        <ListItemIcon>
          {step > currentStep ? (
            <Bell03 color='#D9D9D9' />
          ) : step === currentStep ? (
            <BellRinging03 color={theme.palette.primary.main} />
          ) : (
            <CheckCircleBroken color={theme.palette.success.main} />
          )}
        </ListItemIcon>
        <ListItemText
          sx={{
            fontSize: {
              xs: 16,
              sm: 18,
              md: 20
            },
            color: step > currentStep ? '#D9D9D9' : 'black'
          }}
          children={children}
        />
      </Box>
    </ListItem>
  );
};

const ToDo = ({ id, name, startDate, neededResponses, hasDiagnose }: IToDo) => {
  const theme = useTheme();
  const t = useTranslation('PersonalSpace');

  const [reportIsReady, setReportIsReady] = useState(false);
  const [doneDiagnose, setDoneDiagnose] = useState(false);

  const currentStep = () => {
    if (neededResponses > 0) {
      return 2;
    }

    if (!reportIsReady || !hasDiagnose) {
      return 3;
    }

    if (!doneDiagnose) {
      return 4;
    }

    return 5;
  };

  useEffect(() => {
    webClient.api.reports
      .survey(id)
      .then(() => setReportIsReady(true))
      .catch(() => setReportIsReady(false));

    webClient.api.diagnoses
      .list(undefined, id)
      .then((x) => setDoneDiagnose(x.data.data.any()))
      .catch(() => setDoneDiagnose(false));
  }, []);

  return (
    <Box
      bgcolor={addAlphaToHex('#fff', 80)}
      boxShadow={`0 0 20px 0 ${addAlphaToHex(theme.palette.primary.main, 5)}`}
      borderRadius='24px'
      mb={2}
      p={{
        xs: 14 / 12,
        sm: 2.5,
        md: 4
      }}
      maxWidth={500}
    >
      <Typography
        ml={{
          xs: 18 / 12,
          sm: 9 / 12,
          md: 0
        }}
        fontSize={{
          xs: 16,
          sm: 18,
          md: 20
        }}
        fontWeight={fontWeights.light}
      >
        {name}
      </Typography>
      <Typography
        ml={{
          xs: 18 / 12,
          sm: 9 / 12,
          md: 0
        }}
        fontSize={10}
        fontWeight={fontWeights.medium}
        fontStyle='italic'
      >
        {startDate.asDateTime('dd MMMM yyyy')}
      </Typography>
      <TimeLine>
        <ToDoItem
          step={1}
          currentStep={currentStep()}
          children={t('SelfAssessment')}
        />
        <ToDoItem
          step={2}
          currentStep={currentStep()}
          to={`/user/assessments/${id}/`}
        >
          {t('FeedbackFromOthers')}
          {neededResponses > 0 && (
            <Box
              component='small'
              display='block'
              color={theme.palette.primary.main}
            >
              {t('YouNeedXMore', {
                x: neededResponses
              })}
            </Box>
          )}
        </ToDoItem>
        <ToDoItem
          step={3}
          currentStep={currentStep()}
          to={`/reports/survey/${id}/`}
          children={t('$Report')}
        />
        {hasDiagnose && (
          <>
            <ToDoItem
              step={4}
              currentStep={currentStep()}
              to={`/diagnose/survey/${id}/`}
              children={t('UsePersonalAssistant')}
            />
            <ToDoItem
              step={5}
              currentStep={currentStep()}
              to={`/user/diagnoses/?survey=${id}`}
              children={t('HistoryOfDiagnoses')}
            />
          </>
        )}
      </TimeLine>
    </Box>
  );
};

export default ToDo;
