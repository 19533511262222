import { useTranslation } from '@/components';
import { addAlphaToHex, fontWeights } from '@/helpers';
import { Cancel } from '@mui/icons-material';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  onClose?: () => void;
}

const AINotif = ({ onClose }: IProps) => {
  const t = useTranslation();
  const theme = useTheme();
  const [closing, setClosing] = useState(false);

  const close = () => {
    if (!onClose) {
      return;
    }

    setClosing(true);
  };

  useEffect(() => {
    if (closing && onClose) {
      setTimeout(onClose, 500);
    }
  }, [closing, onClose]);

  return (
    <Box
      position='fixed'
      bottom={32}
      left='16px'
      right='16px'
      textAlign='center'
    >
      <Box
        bgcolor='white'
        borderRadius='24px'
        boxShadow={`0 0 10px 2px ${addAlphaToHex(
          theme.palette.primary.main,
          10
        )}`}
        p={32 / 12}
        fontWeight={fontWeights.medium}
        fontSize={18}
        component='span'
        display='inline-block'
        position='relative'
        sx={
          (closing && {
            animation: 'fadeOut 0.5s ease-out',
            animationFillMode: 'forwards'
          }) ||
          undefined
        }
      >
        {onClose && (
          <Tooltip title={t('$Close')}>
            <IconButton
              onClick={close}
              sx={{
                position: 'absolute',
                top: '2px',
                right: '2px'
              }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        )}
        {t('$TheAnalysisYouAreReadingIsGeneratedByAI')}
      </Box>
    </Box>
  );
};

export default AINotif;
