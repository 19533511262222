import { addAlphaToHex, fontWeights } from '@/helpers';
import { Box, styled } from '@mui/material';

const Card = styled(Box)(({ theme }) => ({
  borderRadius: '24px',
  backgroundColor: 'white',
  boxShadow: `0 4px 4px ${addAlphaToHex(theme.palette.primary.main, 10)}`,
  display: 'flex',
  flexDirection: 'column',
  color: 'black',
  fontSize: 14,
  fontWeight: fontWeights.extraLight
}));

const CardHeader = styled(Box)(({ theme }) => ({
  borderRadius: '24px 24px 0 0',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  width: '100%',
  fontSize: 16,
  fontWeight: fontWeights.semiBold,
  lineHeight: '32px',
  textAlign: 'center'
}));

const CardBody = styled(Box)(({ theme }) => ({
  borderRadius: '0 0 24px 24px',
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`
}));

export { Card, CardBody, CardHeader };
