import { addAlphaToHex, getThemeWithCustomizations } from '@/helpers';
import { Theme, ThemeProvider, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

interface IProps {
  customizations: CompanyCustomizations;
  children: React.JSX.Element | ((theme: Theme) => React.JSX.Element);
  skips?: Partial<
    Record<keyof (CompanyCustomizations & { background: string }), boolean>
  >;
}

const CustomizedThemeProvider = ({
  children,
  customizations,
  skips
}: IProps) => {
  const theme = useTheme();
  const newTheme = getThemeWithCustomizations(theme, customizations, skips);

  return (
    <ThemeProvider theme={newTheme}>
      {customizations.secondaryColor && (!skips || !skips.background) && (
        <Helmet>
          <style>
            {`body {
              --bg-stop-color-2: ${addAlphaToHex(
                customizations.secondaryColor,
                20
              )}
            }`}
          </style>
        </Helmet>
      )}
      {typeof children === 'function' ? children(newTheme) : children}
    </ThemeProvider>
  );
};

export default CustomizedThemeProvider;
