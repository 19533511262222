import { AppErrorMessage, Required } from '@/components';
import { InputLabel, InputLabelProps } from '@mui/material';
import { Field, FieldProps } from 'formik';

type IProps<V, FV> = {
  name: string;
  multiple?: boolean;
  placeholder?: string;
  checkbox?: boolean;
  label?: string;
  required?: boolean;
  children(props: FieldProps<V, FV>): React.JSX.Element;
  labelProps?: InputLabelProps;
};

const AppField = function <V, FV>({
  name,
  label,
  children,
  ...props
}: IProps<V, FV>) {
  return (
    <>
      {label && (
        <InputLabel
          sx={{
            '&:not(:first-of-type)': {
              mt: 1
            }
          }}
          {...props.labelProps}
        >
          {label}
          {props.required && <Required />}
        </InputLabel>
      )}
      <Field
        name={name}
        multiple={props.multiple}
        placeholder={props.placeholder}
        type={props.checkbox && 'checkbox'}
      >
        {children}
      </Field>
      <AppErrorMessage name={name} />
    </>
  );
};

export default AppField;
