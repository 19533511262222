import { Markdown, useTranslation } from '@/components';
import Frame from '@/components/frame';
import { fontWeights } from '@/helpers';
import QuestionSvg from '@/images/question';
import QuestionBgSvg from '@/images/question-bg';
import { Check, ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import { useState } from 'react';

type IProps = {
  position?: 'left' | 'right';
  canGoToPrevious?: boolean;
  canGoToNext?: boolean;
  topic: SurveyToResponseResponse | TopicSurveyResponse;

  previous(): void;
  next(): void;
} & (
  | { section: TopicSectionResponse }
  | {
      index: number;
      item: TopicItemResponse;
      optionId?: string;
      handleChange(e: React.ChangeEvent<HTMLInputElement>): void;
      submit(): void;
    }
);

const TopicItemPage = ({
  topic,
  position,
  canGoToPrevious,
  canGoToNext,
  previous,
  next,
  ...props
}: IProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const t = useTranslation();

  if ('section' in props) {
    const { section } = props;

    return (
      <Frame
        width={'calc(100% - 20px)'}
        position={(!!position && 'absolute') || undefined}
        left={position === 'right' ? 'calc(100% + 32px)' : undefined}
        right={position === 'left' ? 'calc(100% + 32px)' : undefined}
        top={0}
        bgcolor='white'
      >
        <Box
          position='relative'
          display='flex'
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
        >
          <Box
            order={{
              xs: 1,
              md: 0
            }}
            flex='50% 1 1'
            p={4}
            pb={5}
            pr={{
              md: 0
            }}
            pt={{
              xs: 0,
              md: 4
            }}
          >
            <Box fontSize={16} fontWeight={fontWeights.extraLight}>
              <Markdown children={section.description} />
            </Box>
            <Box mt={2}>
              {!!canGoToPrevious && (
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={previous}
                  sx={{
                    mr: 1
                  }}
                >
                  Back
                </Button>
              )}
              <Button variant='contained' onClick={next}>
                Continue
              </Button>
            </Box>
          </Box>
          <Box
            order={{
              xs: 0,
              md: 1
            }}
            flex='50% 1 1'
            display='flex'
            alignItems='center'
            justifyContent='center'
            pb={{
              xs: 1,
              md: 5
            }}
            sx={{
              perspective: '1000px'
            }}
          >
            <QuestionBgSvg />
            <QuestionSvg />
          </Box>
        </Box>
      </Frame>
    );
  }

  const { index, item, optionId, handleChange, submit } = props;

  return (
    <Frame
      width={'calc(100% - 20px)'}
      position={(!!position && 'absolute') || undefined}
      left={position === 'right' ? 'calc(100% + 32px)' : undefined}
      right={position === 'left' ? 'calc(100% + 32px)' : undefined}
      top={0}
      bgcolor='white'
    >
      <Box
        position='relative'
        display='flex'
        flexDirection={{
          xs: 'column',
          md: 'row'
        }}
      >
        <Box
          order={{
            xs: 1,
            md: 0
          }}
          flex='50% 1 1'
          p={4}
          pb={5}
          pr={{
            md: 0
          }}
          pt={{
            xs: 0,
            md: 4
          }}
        >
          <Typography variant='h3'>{item?.name}</Typography>
          <RadioGroup
            value={optionId}
            onChange={handleChange}
            sx={{ mt: 2, pl: 16 / 12 }}
          >
            {topic.options?.map((x, i) => (
              <FormControlLabel
                key={`options${i}`}
                value={x.id}
                control={
                  <Radio
                    sx={{
                      '& svg': {
                        fontSize: '16px !important'
                      }
                    }}
                  />
                }
                label={x.title}
                sx={{
                  fontSize: '16px',
                  fontWeight: fontWeights.light,
                  '&:not(:last-of-type)': {
                    mb: 1.4
                  }
                }}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box
          order={{
            xs: 0,
            md: 1
          }}
          flex='50% 1 1'
          display='flex'
          alignItems='center'
          justifyContent='center'
          pb={{
            xs: 1,
            md: 5
          }}
          sx={{
            perspective: '1000px'
          }}
        >
          {item?.description ? (
            <Box
              width='100%'
              height='100%'
              minHeight={{
                xs: '200px',
                md: undefined
              }}
              position='relative'
              sx={{
                transformStyle: 'preserve-3d',
                transform: showDescription ? 'rotateY(180deg)' : 'rotateY(0)',
                transition: 'transform 1s ease-in-out',
                '&>div': {
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderTopRightRadius: '24px',
                  borderTopLeftRadius: '24px',
                  backgroundColor: 'white'
                }
              }}
            >
              <Box>
                <QuestionBgSvg />
                <Box
                  component='svg'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 38 38'
                  position='absolute'
                  top={0}
                  right={0}
                  width='38px'
                  filter='drop-shadow(0 2px 5px rgba(0,0,0,0.25))'
                  cursor='pointer'
                  onClick={() => setShowDescription(!showDescription)}
                  sx={{
                    transition: 'width ease-in-out 0.2s',
                    '&:hover': {
                      width: '42px'
                    }
                  }}
                >
                  <path d='M0,0v14q0,24,24,24h14z' fill='white'></path>
                </Box>
                <QuestionSvg />
              </Box>
              <Box
                px={2}
                sx={{
                  transform: 'rotateY(180deg) translateZ(1px)'
                }}
              >
                <Box
                  component='svg'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 38 38'
                  position='absolute'
                  top={0}
                  right={0}
                  width='38px'
                  filter='drop-shadow(0 2px 5px rgba(0,0,0,0.25))'
                  cursor='pointer'
                  onClick={() => setShowDescription(!showDescription)}
                  sx={{
                    transition: 'width ease-in-out 0.2s',
                    '&:hover': {
                      width: '42px'
                    }
                  }}
                >
                  <path d='M0,0v14q0,24,24,24h14z' fill='white'></path>
                </Box>
                {item?.description}
              </Box>
            </Box>
          ) : (
            <Box>
              <QuestionBgSvg />
              <QuestionSvg />
            </Box>
          )}
        </Box>
        <Box position='absolute' left={0} right={0} bottom={0} zIndex={2}>
          <Grid
            container
            columnSpacing={1}
            alignItems='center'
            justifyContent='center'
            mb={16 / 12}
          >
            <Grid item>
              <Tooltip title={t('$Previous')}>
                <span>
                  <IconButton disabled={!canGoToPrevious} onClick={previous}>
                    <ChevronLeft />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              {index + 1}/{topic.items.length}
            </Grid>
            <Grid item>
              {index === topic.items.length - 1 ? (
                <Tooltip title={t('$Submit')}>
                  <span>
                    <IconButton disabled={!canGoToNext} onClick={submit}>
                      <Check />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title={t('$Next')}>
                  <span>
                    <IconButton disabled={!canGoToNext} onClick={next}>
                      <ChevronRight />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Frame>
  );
};

export default TopicItemPage;
