import { CopyAnimation } from '@/animations';
import { Frame, Loading, useTranslation } from '@/components';
import {
  addAlphaToHex,
  copyToClipboard,
  fontWeights,
  getCdnUrl,
  webClient
} from '@/helpers';
import { Mail01 } from '@/icons';
import { useUserContext } from '@contexts/user-context';
import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { SettingsPanel } from '@user/settings';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ReportCard } from '../../components';

const LazyLottie = React.lazy(() => import('lottie-react'));

const AssessmentItem = () => {
  const { setCurrentPage, setError, showToast, setHeader } = useUserContext();
  const theme = useTheme();
  const t = useTranslation('PersonalSpace');
  const { id } = useParams<'id'>();

  const [data, setData] = useState<SurveyDetailedResponse | undefined>();

  let _copyAnimation = useRef<any>();

  const relationRotation = (index: number) => {
    const relationsCount = data!.relations.length;
    if (relationsCount < 1) {
      return '0';
    }

    switch (relationsCount) {
      case 2:
        return index === 0 ? '-18deg' : '18deg';

      case 3:
        return index === 1 ? '0deg' : index === 0 ? '-38deg' : '38deg';

      default:
        return index === 0
          ? '-53deg'
          : index === 1
          ? '-18deg'
          : index === 2
          ? '18deg'
          : '53deg';
    }
  };

  const copy = async () => {
    playCopy();
    await copyToClipboard(
      t('$SurveyShare.HiThereIAmCompletingAPersonal', {
        link: `${process.env.REACT_APP_URL}/${data!.code}`,
        name: data!.nickname
      }).replace(/<[^>]+>/g, '')
    );
    showToast('Link copied successfully!', 2e3, 'success');
  };

  const playCopy = () => {
    const anim = _copyAnimation.current;
    if (!anim) {
      return;
    }

    anim.goToAndPlay(0, true);
  };

  const initCopy = () => {
    const anim = _copyAnimation.current;
    if (!anim) {
      return;
    }

    anim.goToAndStop(120, true);
  };

  useEffect(() => {
    setHeader('/user/');

    if (!id) {
      return;
    }

    setCurrentPage('incompleteAssessments', '');
    webClient.api.surveys
      .get(id, {
        loadingHandled: true
      })
      .then((res) => {
        setData(res.data.data);
        setCurrentPage('incompleteAssessments', res.data.data.topic.name);
      })
      .catch(() => setError('Survey not found!'));
    // eslint-disable-next-line
  }, []);

  if (!data) {
    return <Loading />;
  }

  const collectedResponses = data.responds.filter((x) => !x.selfRespond).length;
  const neededResponses = data.relations.sum((x) => x.minResponses);

  return (
    <SettingsPanel
      width={{
        xs: '100%',
        lg: '888px'
      }}
      mx={{
        xs: '0',
        lg: 'calc((100% - 888px) / 2) !important'
      }}
    >
      <Hidden mdDown>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {t('$TopicName')}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} width='1%'>
                {t('$StartDate')}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} width='1%'>
                {t('$Status')}
              </TableCell>
              <TableCell
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    whiteSpace: 'nowrap'
                  }
                }}
                width='1%'
              >
                {t('CollectedResponses')}
              </TableCell>
              <TableCell
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    whiteSpace: 'nowrap'
                  }
                }}
                width='1%'
              >
                {t('NeededResponses')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }}>
                {data.topic.name}
              </TableCell>
              <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                {data.createdAt.asDateTime('dd MMMM yyyy')}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {t(
                  collectedResponses >= neededResponses
                    ? 'Completed'
                    : 'Incomplete'
                )}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {collectedResponses}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {neededResponses}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Hidden>
      <Hidden mdUp>
        <ReportCard
          title={data.createdAt.asDateTime('dd MMMM yyyy')}
          children={[
            {
              title: '$TopicName',
              content: data.topic.name
            },
            {
              title: '$Status',
              content: t(
                collectedResponses >= neededResponses
                  ? 'Completed'
                  : 'Incomplete'
              )
            },
            {
              title: 'CollectedResponses',
              content: collectedResponses
            },
            {
              title: 'NeededResponses',
              content: neededResponses
            }
          ]}
        />
      </Hidden>
      <Box display='flex' flexDirection='column' mb={4}>
        <Box
          display='flex'
          flexDirection={{
            xs: 'column',
            lg: 'row'
          }}
          alignItems='center'
          mt={2}
        >
          {data.relations.length > 1 ? (
            <Box
              mb={{
                xs: 32 / 12,
                lg: undefined
              }}
              mr={{
                xs: undefined,
                lg: 32 / 12
              }}
              display='flex'
              flexDirection='row'
              alignItems='center'
            >
              <Box
                component='img'
                src={getCdnUrl('/images/share-with-relation.svg')}
                title='share'
                zIndex={1}
                mr={1}
              />
              <List sx={{ p: 0 }}>
                {data.relations.map((x, i) => (
                  <ListItem
                    key={`relation_${x.id}`}
                    sx={{
                      whiteSpace: 'nowrap',
                      py: 16 / 12,
                      pr: 16 / 12,
                      pl: 4 / 12,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                      background:
                        'linear-gradient(to right, #BC3DF4B0, #BC3DF4)',
                      borderRadius: '0 40px 40px 0',
                      color: 'white',
                      position: 'relative',
                      '&:not(:last-of-type):not(:first-of-type)': {
                        my: 8 / 12
                      },
                      '&:last-of-type': {
                        mt: 8 / 12
                      },
                      '&:first-of-type': {
                        mb: 8 / 12
                      }
                    }}
                  >
                    <Box
                      borderTop='dashed 1px #BC3DF438'
                      position='absolute'
                      width='70px'
                      right='100%'
                      sx={{
                        transformOrigin: 'right',
                        transform: `rotate(${relationRotation(i)})`
                      }}
                    />
                    <Box fontWeight={fontWeights.semiBold} fontSize='10px'>
                      {x.relation}
                    </Box>
                    <Box fontWeight={fontWeights.light} fontSize='12px' ml={1}>
                      {t('$SurveyShare.AtLeast', {
                        min: x.minResponses
                      })}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              component='img'
              src={getCdnUrl('/images/share.svg')}
              title='share'
            />
          )}
          <Box
            mr={{
              xs: 0,
              lg: 3
            }}
          >
            <Typography
              variant='subtitle1'
              fontSize='18px'
              fontWeight={fontWeights.extraBold}
              sx={{
                '&>ol, &>ul': {
                  fontWeight: fontWeights.regular
                }
              }}
              mb={18 / 12}
            >
              {data.relations.length > 1
                ? t('$SurveyShare.GreatStartPleaseShareWithRelations')
                : t('$SurveyShare.GetFeedbackFromFriends', {
                    name:
                      data.relations.length > 0
                        ? data.relations[0].relation.toLowerCase()
                        : t('$SurveyShare.Friends'),
                    count:
                      data.relations.length > 0
                        ? data.relations[0].minResponses
                        : 3
                  })}
            </Typography>
            <Frame
              px={16 / 12}
              pt={16 / 12}
              pb={{
                xs: 3,
                sm: 4,
                md: 3
              }}
              position='relative'
            >
              {t('$SurveyShare.HiThereIAmCompletingAPersonal', {
                link: `<strong>${process.env.REACT_APP_URL}/${
                  data!.code
                }</strong>`,
                name: data.nickname
              }).toTsx()}
              <Button
                color='primary'
                variant='contained'
                onClick={copy}
                sx={{
                  fontSize: '16px',
                  position: 'absolute',
                  right: theme.spacing(16 / 12),
                  bottom: theme.spacing(16 / 12),
                  'svg>g': {
                    transform: 'scale(2.3)',
                    transformOrigin: 'center'
                  }
                }}
              >
                {t('$Copy')}
                <Suspense fallback={<CircularProgress />}>
                  <LazyLottie
                    animationData={CopyAnimation}
                    style={{
                      marginLeft: 4,
                      height: '24px'
                    }}
                    autoplay={false}
                    loop={false}
                    lottieRef={_copyAnimation}
                    onDOMLoaded={initCopy}
                  />
                </Suspense>
              </Button>
            </Frame>
            <Typography
              variant='subtitle1'
              fontSize='12px'
              mt={8 / 12}
              pl={16 / 12}
            >
              {t('$SurveyShare.WeAlsoSentAConfirmation')}
            </Typography>
          </Box>
        </Box>
      </Box>
      {data?.feedbackGroup && data.feedbackGroup.items.length > 0 && (
        <Box
          bgcolor={addAlphaToHex(theme.palette.primary.main, 3)}
          borderRadius='24px'
          px={32 / 12}
          py={32 / 12}
        >
          <Typography
            color={theme.palette.primary.main}
            fontSize={16}
            fontWeight={fontWeights.bold}
          >
            <Mail01
              style={{
                marginBottom: -6,
                marginRight: 4,
                marginLeft: 4
              }}
            />
            <Hidden smUp>
              <br />
            </Hidden>
            {t('$SurveyShare.WeInvitedFollowingPeople')}
          </Typography>
          <Box>
            {data.feedbackGroup.items.map((x, i) => (
              <Box
                key={`email${i}`}
                component='span'
                display={{
                  xs: 'block',
                  sm: 'inline-block'
                }}
                fontSize={{
                  xs: 12,
                  sm: 14
                }}
                fontWeight={fontWeights.light}
                bgcolor='white'
                borderRadius='24px'
                lineHeight={{
                  xs: '32px',
                  sm: '42px'
                }}
                px={{
                  xs: 0,
                  sm: 16 / 12
                }}
                whiteSpace='nowrap'
                boxShadow={`0 2px 4px ${addAlphaToHex('#000', 25)}`}
                mr={{
                  xs: 0,
                  sm: 16 / 12
                }}
                mt={{
                  xs: 8 / 12,
                  sm: 16 / 12
                }}
                textAlign='center'
              >
                {x.email}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </SettingsPanel>
  );
};

export default AssessmentItem;
