import { useEffect, useRef, useState } from 'react';

function useStateAsync<T>(initialValue: T) {
  const [state, setState] = useState<T>(initialValue);
  const resolveRef = useRef<(value: T) => void>();

  useEffect(() => {
    if (resolveRef.current) {
      resolveRef.current(state);
      resolveRef.current = undefined; // Clear the reference after resolving
    }
  }, [state]);

  const setStateAsync = (newState: T) => {
    return new Promise<T>((resolve) => {
      if (state === newState) {
        resolve(newState);
      } else {
        resolveRef.current = resolve;
        setState(newState);
      }
    });
  };

  return [state, setState, setStateAsync] as const;
}

export default useStateAsync;
