import { Markdown, useTranslation } from '@/components';
import { addAlphaToHex, fontWeights } from '@/helpers';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface IReportCardParagraph {
  title: string;
  content: any;
}

export const ReportCardGrid = ({ children }: { children: ReactNode }) => (
  <Grid item xs={12} sm={12} md={6} lg={4} xl={3} children={children} />
);

const ReportCardParagraph = ({ title, content }: IReportCardParagraph) => {
  const t = useTranslation('PersonalSpace');

  return (
    <Typography variant='body1'>
      <Box component='span' fontWeight={fontWeights.regular} mr={0.5}>
        {t(title)}:
      </Box>
      <Box component='span' fontWeight={fontWeights.extraLight}>
        {content}
      </Box>
    </Typography>
  );
};

const ReportCard = ({
  to,
  buttonText,
  children,
  title
}: {
  to?: string;
  buttonText?: string;
  title: string;
  children: string | IReportCardParagraph | IReportCardParagraph[];
}) => {
  const t = useTranslation('PersonalSpace');
  const theme = useTheme();

  return (
    <Box
      display='flex'
      flexDirection='column'
      boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
      borderRadius='24px'
      bgcolor='white'
      p={16 / 12}
      height='100%'
    >
      <Box
        fontSize='10px'
        fontWeight={fontWeights.medium}
        fontStyle='italic'
        color={theme.palette.primary.main}
        textAlign='right'
      >
        {title}
      </Box>
      <Box
        mx={16 / 12}
        fontSize='14px'
        fontWeight={fontWeights.extraLight}
        mb={16 / 12}
      >
        {typeof children === 'string' ? (
          <Markdown children={children} />
        ) : Array.isArray(children) ? (
          children.map((x, i) => (
            <ReportCardParagraph key={`reportCardParagraph${i}`} {...x} />
          ))
        ) : (
          <ReportCardParagraph key='reportCardParagraph1' {...children} />
        )}
      </Box>
      {to && (
        <Button
          component={Link}
          to={to}
          disabled={!to}
          variant='contained'
          color='success'
          sx={{
            m: 16 / 12,
            mt: 'auto',
            alignSelf: 'flex-start',
            fontSize: '14px',
            fontWeight: fontWeights.regular
          }}
        >
          {t(buttonText!)}
        </Button>
      )}
    </Box>
  );
};

export default ReportCard;
