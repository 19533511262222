import { getLanguage } from '@/components';
import { LoadingEvent, getAuthCookie, queryString } from '@/helpers';
import axios, { InternalAxiosRequestConfig } from 'axios';

function setHeadersInterceptor(config: InternalAxiosRequestConfig<any>) {
  if (!config.languageSetted) {
    config.headers['Accept-Language'] = getLanguage();
  }

  const cookie = getAuthCookie();
  if (cookie) {
    config.headers['Authorization'] = `Bearer ${cookie.accessToken}`;
  }

  return config;
}

function showLoadingInterceptor(config: InternalAxiosRequestConfig<any>) {
  if (config.loadingHandled === true) {
    return config;
  }

  const event = new LoadingEvent(true);
  window.dispatchEvent(event);
  return config;
}

function isValidStatus(status: number) {
  const event = new LoadingEvent(false);
  window.dispatchEvent(event);
  return status > 199 && status < 300;
}

export const webClient = axios.create({
  baseURL: `${process.env.REACT_APP_API || ''}/v1/`,
  validateStatus: isValidStatus
});

webClient.interceptors.request.use(setHeadersInterceptor);
webClient.interceptors.request.use(showLoadingInterceptor);

webClient.api = {
  general: {
    settingsGet: (config) =>
      webClient.get<SingleResponse<SettingsResponse>>('settings', config),
    settingsPatch: (body: PatchSettingsBody, config) =>
      webClient.patch<SingleResponse<SettingsResponse>>(
        'settings',
        body,
        config
      ),
    landing: (config) =>
      webClient.post<SingleResponse<string>>('landing', {}, config),
    topicForSurvey: (isFeedback, code, project, topic) =>
      webClient.get(
        isFeedback
          ? `/surveys/code/${code}/`
          : project
          ? `/projects/code/${project}/`
          : `/topics/${topic}/survey`
      )
  },
  auth: {
    authenticate: (config) => webClient.get('auth/authenticate', config),
    google: (config) => webClient.get('auth/google', config),
    basic: {
      register: (data, config) =>
        webClient.post('auth/basic/register', data, config),
      login: (data, config) =>
        webClient.post(`auth/basic/login/${document.location.search}`, data, {
          ...config,
          withCredentials: true
        }),
      verify: (data, config) =>
        webClient.post('auth/basic/verify', data, {
          ...config,
          withCredentials: true
        }),
      resend: (data, config) =>
        webClient.post('auth/basic/resend', data, config)
    },
    password: {
      forget: (data, config) =>
        webClient.post('auth/password/forget', data, config),
      reset: (data, config) =>
        webClient.post('auth/password/reset', data, config)
    }
  },
  users: {
    list: (config) => webClient.get('users', config),
    get: (id, config) => webClient.get(`users/${id}`, config),
    patch: (id, body, config) => webClient.patch(`users/${id}`, body, config),
    delete: (id, config) => webClient.delete(`users/${id}`, config),
    deleteConfirm: (id, body, config) =>
      webClient.delete(`users/confirm/${id}`, {
        data: body,
        ...config
      }),
    getMe: (config) => webClient.api.users.get('me', config),
    patchMe: (body, config) => webClient.api.users.patch('me', body, config),
    deleteMe: (config) => webClient.api.users.delete('me', config),
    deleteMeConfirm: (body, config) =>
      webClient.api.users.deleteConfirm('me', body, config)
  },
  topics: {
    list: (config) => webClient.get('topics', config),
    post: (body, config) => webClient.post('topics', body, config),
    project: (config) => webClient.get('topics/project', config),
    get: (id, config) => webClient.get(`topics/${id}`, config),
    patch: (id, body, config) => webClient.patch(`topics/${id}`, body, config),
    delete: (id, config) => webClient.delete(`topics/${id}`, config),
    survey: (id, config) => webClient.get(`topics/${id}/survey`, config)
  },
  reports: {
    list: (afterId, config) =>
      webClient.get(`reports/${queryString({ afterId })}`, config),
    survey: (id, config) => webClient.get(`reports/survey/${id}/`, config),
    teamReport: (projectId, teamId, config) =>
      webClient.get(`reports/project/${projectId}/team/${teamId}/`, config)
  },
  challenges: {
    list: (afterId, active, category, config) =>
      webClient.get(
        `predefined-challenges/${queryString({ afterId, active, category })}`,
        config
      ),
    get: (id, config) => webClient.get(`predefined-challenges/${id}/`, config),
    post: (body, config) =>
      webClient.post('predefined-challenges/', body, config),
    patch: (id, body, config) =>
      webClient.patch(`predefined-challenges/${id}/`, body, config)
  },
  coreBehaviors: {
    list: (afterId, active, category, config) =>
      webClient.get(
        `core-behaviors/${queryString({ afterId, active, category })}`,
        config
      ),
    get: (id, config) => webClient.get(`core-behaviors/${id}/`, config),
    post: (body, config) => webClient.post('core-behaviors/', body, config),
    patch: (id, body, config) =>
      webClient.patch(`core-behaviors/${id}/`, body, config)
  },
  companies: {
    list: (afterId, config) =>
      webClient.get(`companies/${queryString({ afterId })}`, config),
    get: (id, config) => webClient.get(`companies/${id}/`, config),
    post: (body, config) => webClient.post('companies/', body, config),
    patch: (id, body, config) =>
      webClient.patch(`companies/${id}/`, body, config)
  },
  diagnoses: {
    list: (afterId, survey, config) =>
      webClient.get(`diagnoses/${queryString({ afterId, survey })}`, config),
    survey: (surveyId, config) =>
      webClient.get(`diagnoses/survey/${surveyId}/`, config)
  },
  surveys: {
    list: (queryStrings, config) =>
      webClient.get(
        `surveys/${queryString({
          ...queryStrings
        })}`,
        config
      ),
    get: (id, config) => webClient.get(`surveys/${id}/`, config),
    getChallenges: (id, config) =>
      webClient.get(`surveys/${id}/predefined-challenges`, config),
    charts: (id, config) => webClient.get(`surveys/${id}/charts`, config),
    grantAccess: (id, config) =>
      webClient.patch(`surveys/${id}/grantAccess`, config)
  },
  projects: {
    list: (config) => webClient.get(`projects/`, config),
    get: (id, config) => webClient.get(`projects/${id}/`, config),
    teams: (id, body, config) =>
      webClient.patch(`projects/teams/${id}/`, body, config)
  },
  archives: {
    request: (config) => webClient.post('archives/me/', config),
    confirm: async (code, config) => {
      const res = await webClient.post(
        'archives/confirm/me/',
        { code },
        {
          ...config,
          responseType: 'blob'
        }
      );

      const link = document.createElement('a');
      const fileName = (
        'content-disposition' in res.headers
          ? res.headers['content-disposition']
          : 'filename=export.xlsx'
      ).split('filename=')[1];
      link.href = window.URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      link.target = '_blank';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
  tools: {
    rectifier: (body, config) =>
      webClient.post('tools/rectifier/text/', body, config),
    rectifierVoice: (audio, body, config) => {
      const formData = new FormData();
      formData.append('file', audio, 'audio.wav');
      if (body.history && body.history.length > 0) {
        for (let i = 0; i < body.history.length; i++) {
          const item = body.history[i];
          formData.append(`history[${i}][role]`, item.role);
          formData.append(`history[${i}][content]`, item.content);
        }
      }
      formData.append('type', body.type);
      if (body.reference) {
        formData.append('reference', body.reference);
      }

      return webClient.post('tools/rectifier/voice/', formData, config);
    },
    whisper: (audio, config) => {
      const formData = new FormData();
      formData.append('file', audio, 'audio.wav');
      return webClient.post('tools/whisper/', formData, {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    translator: (text, lang, config) =>
      webClient.post(
        'tools/translator/',
        {
          text,
          lang
        },
        config
      )
  }
};

export default webClient;
