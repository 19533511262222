import { Box, BoxProps } from '@mui/material';
import React from 'react';

export default class Panel extends React.Component<BoxProps> {
  get properties(): BoxProps {
    let { sx, ...others } = this.props || {};
    let properties = {
      sx: { px: 2, py: 2, ...sx },
      bgcolor: '#f5f5f5',
      borderRadius: '8px',
      ...others
    };

    return properties;
  }

  render(): React.ReactNode {
    return <Box {...this.properties}>{this.props.children}</Box>;
  }
}
