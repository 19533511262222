import { Add } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  title?: string;
}

const PsudoButton = ({ onClick, disabled, title }: IProps) => (
  <Tooltip title={title || 'Add'}>
    <Box
      bgcolor={disabled ? 'D9D9D9' : 'white'}
      border='dashed 2px #D9D9D9'
      borderRadius='10px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      p={0.6}
      sx={{
        cursor: disabled ? 'default' : 'pointer'
      }}
      flex='auto 1 1'
      onClick={disabled ? undefined : onClick}
    >
      <Add htmlColor={disabled ? '#9F9F9F' : 'black'} />
    </Box>
  </Tooltip>
);

export default PsudoButton;
