import { useTheme } from '@mui/material';
import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface IProps {
  width?: number;
  style?: Style;
}

const ChapterIcon = ({ width, style }: IProps) => {
  const theme = useTheme();

  return (
    <Svg viewBox='0 0 25 20' fill='none' width={width || 25} style={style}>
      <Path
        d='M12.0879 19.3965L0.0851187 14.8514L0.08512 4.67273e-06L12.0879 5.95338L12.0879 19.3965Z'
        fill={theme.palette.primary.main}
        fillOpacity={0.8}
      />
      <Path
        d='M12.0879 19.3965L24.0907 14.8514L24.0907 6.77136e-06L12.0879 5.95338L12.0879 19.3965Z'
        fill={theme.palette.primary.main}
        fillOpacity={0.4}
      />
    </Svg>
  );
};

export default ChapterIcon;
